import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  row: {
    display: 'flex',
    marginTop: theme.typography.pxToRem(32),
    '&:first-child': {
      marginTop: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  editModeButtons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.typography.pxToRem(32),
    gap: theme.typography.pxToRem(16),
  },
}));
