import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    padding: 0,
    overflow: 'hidden',
  },

  section: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  sectionTitle: {
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(24)}`,
    backgroundColor: theme.palette.background.alternative,
    width: '100%',
  },

  title: {
    color: theme.palette.text.secondary,
  },
}));
