import { WebhookUpsertForm } from '@marlin/account-ui-webhook-upsert-form';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { NotFoundPage, PageContainer, PageHeader, Paper } from '@marlin/shared/ui-page';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useWebhookUpdate } from './hooks/use-webhook-update.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(746),
  },
}));

export function WebhookUpdate() {
  const { classes } = useStyles();
  const {
    defaultValues,
    handleSubmit,
    handleCancel,
    currentWebhookName,
    isLoading,
    isFormSubmitting,
    isError,
    selectedDefaultFlowMeters,
    selectedAllFlowMeters,
    isLoadingEquipmentPartition,
    equipmentsPartitionData,
    isErrorEquipmentPartition,
  } = useWebhookUpdate();

  if (isLoading || isLoadingEquipmentPartition) {
    return <LoadingSpinner />;
  }

  if (isError || isErrorEquipmentPartition) {
    return <NotFoundPage />;
  }

  return (
    <PageContainer prefix="update-webhook-page" className={classes.container}>
      <PageHeader
        prefix="update-webhook-page-header"
        title={currentWebhookName}
        subtitle={content.WEBHOOK_UPDATE_SUBTITLE}
      />
      <Paper>
        <WebhookUpsertForm
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isFormSubmitting={isFormSubmitting}
          selectedDefaultFlowMeters={selectedDefaultFlowMeters}
          selectedAllFlowMeters={selectedAllFlowMeters}
          equipmentsPartitionData={equipmentsPartitionData}
        />
      </Paper>
    </PageContainer>
  );
}
