import { MarlinTheme } from '@marlin/shared/theme';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Handle, Node, NodeProps, Position, useReactFlow } from '@xyflow/react';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { content } from '../../content';
import { calculateCeilSnappedValue } from '../../use-snap-to-grid.hook';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';
import { SystemTagComponent } from '../utils/system-tag.component';
import { useNodeTags } from '../utils/use-node-tags.hook';

interface INodeStyleProps {
  nodeHeight?: number | null;
  nodeWidth?: number | null;
}

export const useStyles = makeStyles<INodeStyleProps>()((theme: MarlinTheme, { nodeWidth, nodeHeight }) => ({
  location: {
    backgroundColor: theme.palette.systemMap.location,
    borderStyle: 'dashed',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
  },
  emptyLocation: {
    width: nodeWidth ? calculateCeilSnappedValue(nodeWidth) : 'auto',
    height: nodeHeight ? calculateCeilSnappedValue(nodeHeight) : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sourceLocation: {
    width: calculateCeilSnappedValue(48),
    height: calculateCeilSnappedValue(48),
  },
  sourceLocationIcon: {
    width: theme.typography.pxToRem(46),
    height: theme.typography.pxToRem(46),
  },
}));

type TNodeData = TGraphAsset['data'] & { label: string };

export const CommonLocationNode = ({ data, id }: NodeProps<Node<TNodeData>>) => {
  const { getNode } = useReactFlow();
  const { isWaterSource, nodeTags } = useNodeTags(data);
  const node = getNode(id);

  const { classes, cx } = useStyles({ nodeWidth: node?.measured?.width, nodeHeight: node?.measured?.height });
  const { classes: commonClasses } = useCommonStyles();

  const sliceName = !!(data.name && data.name?.length > maxLabelLength);

  if (isWaterSource) {
    return (
      <div>
        <SystemTagComponent tags={nodeTags} />
        <NodeTooltip text={content.SYSTEM_TAG.WATER_SOURCE_NODE_NAME(data.name || '')}>
          <div
            className={cx(classes.location, classes.emptyLocation, classes.sourceLocation)}
            data-testid={`location-node-${id}`}
          >
            <div className={commonClasses.nodeContent}>
              <PlayArrowRoundedIcon className={classes.sourceLocationIcon} />
            </div>
            <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
            <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
            <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
            <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
          </div>
        </NodeTooltip>
      </div>
    );
  }

  return (
    <div>
      <SystemTagComponent tags={nodeTags} />
      <NodeTooltip text={data.name || ''} when={sliceName}>
        <div
          className={cx(commonClasses.node, classes.location, classes.emptyLocation)}
          data-testid={`location-node-${id}`}
        >
          <div className={commonClasses.nodeContent}>
            <span className={commonClasses.nodeLabel} data-testid={`location-node-${id}-label`}>
              {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data.name}
            </span>
            <LocationOnRoundedIcon />
          </div>
          <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
          <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
          <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
          <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
        </div>
      </NodeTooltip>
    </div>
  );
};
