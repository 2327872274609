import { ColorMode } from '@marlin/shared/theme';
import { Palette } from '@mui/material/styles/createPalette';

import { defaultConfig, defaultDarkConfig } from './default-config';

export const colorPalette = (mode: ColorMode): Palette => {
  if (mode === 'dark') {
    return colorPaletteDark();
  }

  return {
    ...defaultConfig.palette,
    mode,
    primary: {
      main: '#006FD6',
      dark: '#004A90',
      light: '#0267FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8A63B9',
      dark: '#45325D',
      light: '#903FF2',
      contrastText: '#FFFFFF',
    },
    alternative: {
      main: '#5B5B5B',
      dark: '#323232',
      light: '#767676',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#1E1E1E',
      secondary: '#5D5D5E',
      disabled: '#747474',
      alternative: '#FFFFFF',
    },
    systemMap: {
      main: '#202020',
      alternative: '#039BE5',
      group: '#F5F7FC',
      location: '#E8EAF6',
      device: '#E0E0E0',
      equipment: '#CFD8DC',
      divider: '#B6BEC2',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
      contrastText: '#000000',
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
      contrastText: '#FFFFFF',
    },
    background: {
      ...defaultConfig.palette.background,
      primary: '#FFFFFF',
      secondary: '#FAFBFD',
      alternative: '#F6F6F6',
    },
    action: {
      ...defaultConfig.palette.action,
    },
    charting: {
      breezyBlue: '#0A6BBC',
      freshGreen: '#8DB86B',
      flushedPink: '#C2185B',
      frostyBlue: '#94B4FF',
      toastyOrange: '#EE842D',
      aquaFusion: '#008886',
      springPink: '#FF92E1',
      sunnyGold: '#CFBC21',
      plasmaLavender: '#7140CB',
      hazyLilac: '#DAADE8',
      oliveDuct: '#827717',
      rustyCopper: '#A43E03',
      charting13: '#E64A19',
      charting14: '#F4A900',
      charting15: '#1C91C0',
      charting16: '#D500F9',
    },
    thresholds: {
      clementine: '#ED6C02',
      persianRed: '#D32F2F',
    },
  };
};

const colorPaletteDark = (): Palette => ({
  ...defaultDarkConfig.palette,
  mode: 'dark',
  primary: {
    main: '#007cf0',
    dark: '#0058aa',
    light: '#1c77ff',
    contrastText: '#0d0d0d',
  },
  secondary: {
    main: '#9775c1',
    dark: '#513a6d',
    light: '#9d57f4',
    contrastText: '#0d0d0d',
  },
  alternative: {
    main: '#cdcdcd',
    dark: '#5b5b5b',
    light: '#767676',
    contrastText: '#0d0d0d',
  },
  text: {
    primary: '#e1e1e1',
    secondary: '#b5b5b5',
    disabled: '#747474',
    alternative: '#0d0d0d',
  },
  systemMap: {
    main: '#dfdfdf',
    alternative: '#06aafc',
    group: '#131313',
    location: '#101429',
    device: '#2c2c2c',
    equipment: '#2e3a3f',
    divider: '#b6bec2',
  },
  error: {
    main: '#d74444',
    dark: '#d63232',
    light: '#f16a67',
    contrastText: '#0d0d0d',
  },
  warning: {
    main: '#fd780c',
    dark: '#ff5a01',
    light: '#ffa31a',
    contrastText: '#ffffff',
  },
  success: {
    main: '#359039',
    dark: '#217226',
    light: '#5cb85f',
    contrastText: '#0d0d0d',
  },
  info: {
    main: '#0299ea',
    dark: '#0167b4',
    light: '#14b3fc',
    contrastText: '#0d0d0d',
  },
  background: {
    ...defaultDarkConfig.palette.background,
    primary: '#1d1b20',
    secondary: '#211f26',
    alternative: '#36343b',
  },
  action: {
    ...defaultDarkConfig.palette.action,
  },
  charting: {
    breezyBlue: '#0b7ad4',
    freshGreen: '#9ac07c',
    flushedPink: '#d91b67',
    frostyBlue: '#94b4ff',
    toastyOrange: '#f09245',
    aquaFusion: '#00a29f',
    springPink: '#ff92e0',
    sunnyGold: '#ddc92c',
    plasmaLavender: '#8054d0',
    hazyLilac: '#daade8',
    oliveDuct: '#988b1b',
    rustyCopper: '#bd4803',
    charting13: '#e95b30',
    charting14: '#ffb70f',
    charting15: '#1fa2d6',
    charting16: '#dc14ff',
  },
  thresholds: {
    clementine: '#ED6C02',
    persianRed: '#D32F2F',
  },
});
