import { MobileSideNav } from '@marlin/account-feature-navbar';
import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useMobileSideNavOptions } from './navbar-options';
import { useLayoutLoader } from './use-layout-loader.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  appContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      minHeight: `calc( 100dvh - ${theme.typography.pxToRem(theme.static.mobileHeaderHeight)} )`,
      height: `calc(100dvh - ${theme.typography.pxToRem(theme.static.mobileHeaderHeight)} )`,
    },
  },

  appContent: {
    minHeight: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      height: 'auto',
      overflowY: 'scroll',
    },
  },

  main: {
    overflowY: 'hidden',
    height: '100%',
  },
}));

export const MobileSideNavLayout: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  const { topLinks, bottomLinks } = useMobileSideNavOptions();
  const isLoading = useLayoutLoader();

  return (
    <div className={classes.main}>
      <MobileSideNav topNavLinks={topLinks} bottomNavLinks={bottomLinks} />
      <div className={classes.appContainer}>
        <div className={classes.appContent} id="appContent">
          {isLoading && <DataLoader />}
          {!isLoading && children}
        </div>
      </div>
    </div>
  );
};
