import { z } from 'zod';

export const assignedCsmSchema = z.object({
  id: z.string(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
});

export const assignedCsmForm = z.object({
  assignedCsm: z.array(assignedCsmSchema).optional(),
});

export type TAssignedCsm = z.infer<typeof assignedCsmSchema>;
export type TAssignedCsmForm = z.infer<typeof assignedCsmForm>;
