import { environment } from '@marlin/environment';

export const content = {
  HOME_TITLE: 'Home',
  HOME_SUBTITLE: `Your ${environment.app.name} Overview.`,
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  NO_ALERTS: 'There are no active alerts.',
  NO_ASSETS_LINE_1: 'This location has no Equipment or Sensors attached to it.',
  NO_ASSETS_LINE_2: 'Please attach or create a new equipment or sensor to ',
  NO_ASSETS_LINE:
    'This location has no Equipment or Sensors attached to it. Please attach or create a new equipment or sensor to ',
  NO_ASSETS_CTA: 'Go to equipment page',
  NO_SENSORS_LINE:
    'This location has no Sensors attached to it. Please attach or create a new Sensor to Mechanical Room 1.',
  NO_SENSORS_CTA: 'Go to sensors page',
  MANAGE_LOCATION: 'Manage location',
  CREATE_NEW_BUTTON: 'Create New',
  IMPACT: (alertCount: number) => `${alertCount} high and low open alert(s)`,
  IMPACT_HIGH: (alertCount: number) => `${alertCount} high open alert(s)`,
  IMPACT_LOW: (alertCount: number) => `${alertCount} low open alert(s)`,
  IMPACT_MOBILE: (alertCount: number) => `${alertCount} alert(s)`,
  IMPACT_INTRO: 'This location has ',
  IMPACT_NONE: 'no alerts',
  MECHANICAL_ROOMS_TAB: (locationLength: number) => `Mechanical Rooms (${locationLength})`,
  OPEN_ALERTS_TAB: (locationLength: number) => `Locations with Alerts (${locationLength})`,
  ALL_LOCATIONS_TAB: (locationLength: number) => `All Locations (${locationLength})`,
  NO_MECHANICAL_ROOMS_TITLE: 'No Mechanical Rooms',
  NO_MECHANICAL_ROOMS_BODY:
    'Please create a new mechanical room or view your locations to tag them as mechanical rooms.',
  CREATE_NEW_MECHANICAL_ROOM: 'Create new mechanical room',
  GO_TO_LOCATIONS_PAGE: 'Go to locations page',
  LOCATION: 'Location',
  SENSOR: 'Sensor',
  EQUIPMENT: 'Equipment',
  AUTOMATION: 'Automation',
  TOOLTIP_NO_LOCATION: 'This sensor does not belong to a location',
  TOOLTIP_DIFFERENT_LOCATION: (locationName: string, equipmentName = 'Unattached Sensors') =>
    `This sensor also belongs to "${locationName}" under "${equipmentName}"`,
  NA: 'N/A',
  UNATTCHED_SENSORS_TITLE: (sensorCount: number) => `Unattached Sensors (${sensorCount})`,
  EQUIPMENT_ALERTS: 'There is an alert outstanding for this equipment.',
  SENSOR_ALERT: 'There is an alert outstanding for this sensor.',
  UNATTACHED_SENSORS_ALERTS: 'There is an alert outstanding for "Unattached Sensors".',
  LOST_COMMUNICATION: 'Lost Communication',
  LOW_BATTERY: 'Low Battery',
  EMPTY_LIST: 'No results found.',
  CHART_TITLE_MODAL: 'Flow',
};
