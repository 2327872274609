import { getHttpClient } from '@marlin/shared/utils/react-query';

import {
  TAutomationBuilder,
  TAutomationsBuilderResponse,
  automationBuilderSchema,
  automationsBuilderResponseSchema,
} from '../schemas/builder/automation-builder.schema';
import { createAutomationSchema } from '../schemas/builder/automation-upsert.schema';
import { paths } from './api';

export const createAutomation = async (data: TAutomationBuilder): Promise<TAutomationBuilder> => {
  const res = await getHttpClient().post(paths.AUTOMATIONS_COMPLEX, createAutomationSchema.parse(data));
  return automationBuilderSchema.parse(res);
};

export const updateAutomation = async ({
  automationId,
  data,
}: {
  automationId: string;
  data: TAutomationBuilder;
}): Promise<TAutomationBuilder> => {
  const res = await getHttpClient().put(paths.AUTOMATIONS_COMPLEX, automationBuilderSchema.parse(data), {
    params: { automationId },
  });
  return automationBuilderSchema.parse(res);
};

export const getAutomation = async (automationId: string): Promise<TAutomationBuilder> => {
  const res = await getHttpClient().get(paths.AUTOMATION_COMPLEX, { params: { automationId } });
  return automationBuilderSchema.parse(res);
};

export const getAutomations = async (): Promise<TAutomationsBuilderResponse> => {
  const res = await getHttpClient().get(paths.AUTOMATIONS_COMPLEX);
  return automationsBuilderResponseSchema.parse(res);
};

export const deleteAutomation = async (automationId: string): Promise<void> => {
  await getHttpClient().delete(paths.AUTOMATIONS_COMPLEX, { params: { automationId } });
};
