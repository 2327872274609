import { useDatapoints, useEquipmentFlow } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer } from '@marlin/shared/ui-page';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { ReactFlowProvider } from '@xyflow/react';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Canvas } from './canvas.component';
import { DatapointComponent } from './datapoint.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modal: {
    width: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  container: {
    height: '100%',
    minHeight: theme.typography.pxToRem(800),
    margin: 0,
  },
}));

export const EquipmentFlow = () => {
  const { classes } = useStyles();
  const equipmentId = useIdFromPathname();
  const { data, isLoading, refetch, isFetching } = useEquipmentFlow({ equipmentId });
  const { datapoints } = useDatapoints();
  const mappedNodes = data?.nodes.map((item) => ({
    ...item,
    data: { children: <DatapointComponent datapoints={item.datapoints} name={item.name} /> },
  }));

  useEffect(() => {
    refetch();
  }, [datapoints, refetch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data || !mappedNodes) {
    return <div>No data found</div>;
  }

  return (
    <PageContainer prefix="automation-builder" className={classes.container}>
      <ReactFlowProvider>
        <div style={{ height: 600, width: '100%' }}>
          <Canvas isFetching={isFetching} edge={data.edges} nodes={mappedNodes} />
        </div>
      </ReactFlowProvider>
    </PageContainer>
  );
};
