export const content = {
  API_KEY_TITLE: 'Monnit API Key',
  API_KEY_HEADER: 'API Key Header',
  API_KEY_VALUE: 'API Key Value',
  API_KEY_COPY_SUCCESS: 'Copied text to clipboard',
  VENDOR_API_KEY_VALUE: 'Key',
  VENDOR_TOGGLE: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    ENABLED_SUCCESS_MESSAGE: 'Your vendor api key has been enabled',
    DISABLED_SUCCESS_MESSAGE: 'Your vendor api key has been disabled',
    ENABLED_ERROR_MESSAGE: 'Something went wrong. Your vendor api key has not been enabled',
    DISABLED_ERROR_MESSAGE: 'Something went wrong. Your vendor api key has not been disabled',
    UNSUSPENDED_ERROR_MESSAGE: 'Something went wrong. Your vendor api key has not been unsuspended',
    HIDE_KEYS: 'Hide keys',
    SHOW_KEYS: 'Show keys',
  },
};
