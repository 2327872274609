import { z } from 'zod';

export const base64EncodeSchema = z.string().transform((str) => btoa(str));
export const base64EncodeUrlSchema = z
  .string()
  .url()
  .transform((str) => btoa(str));

export const base64DecodeSchema = z.string().transform((str) => {
  try {
    return atob(str);
  } catch {
    return str;
  }
});
