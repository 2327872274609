import { IConfig } from '@marlin/asset/shared/equipment-config';

import { content } from '../../../content';

export const boilersAndPumpsConfig: IConfig['boilersAndPumps'] = {
  rows: [
    {
      name: content.DATAPOINT_SBS_LABELS.SYSTEM_PUMP,
      id: 'systemPump',
      hoursDatapoint: 'SysPumpRuntime',
      cyclesDatapoint: 'SysPumpCycles',
      pumpStatus: 'SysPumpStatus',
      type: 'pump',
    },
    {
      name: content.DATAPOINT_SBS_LABELS.DHW_PUMP,
      id: 'dhwPump',
      hoursDatapoint: 'DhwPumpRuntime',
      cyclesDatapoint: 'DhwPumpCycles',
      pumpStatus: 'DhwPumpStatus',
      type: 'pump',
    },
    {
      name: `${content.DATAPOINT_SBS_LABELS.BOILER} {X}`,
      id: 'boiler{X}',
      hoursDatapoint: 'Boiler{X}IgnitionRuntime',
      cyclesDatapoint: 'Boiler{X}IgnitionCycles',
      pumpStatus: 'Boiler{X}PumpStatus',
      valveStatus: 'Boiler{X}ValveStatus',
      boilerFiringRateDatapoint: 'Boiler{X}FiringRate',
      boilerFiringTempDatapoint: 'Boiler{X}FiringTemp',
      boilerEnabledStatus: 'Boiler{X}EnableStatus',
      type: 'boiler',
    },
  ],
  columns: ['name', 'hours', 'cycles', 'boilerStatus', 'pumpStatus'],
};
