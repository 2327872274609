import { TWaterTotalResponse, useWaterTotal } from '@marlin/asset/data-access/home-dashboard';
import { PERIOD_ENUM, TCompareToValue, TPeriodDate, usePeriodState } from '@marlin/shared/ui/chart';
import { getBucketSize, getNumberOfBucketsExpandedDrawer } from '@marlin/shared/utils-chart';
import { useMemo } from 'react';

import { useTotalWaterCompare } from './use-total-water-compare.hook';

export interface ICustomPeriodModalProps {
  fromDate: TPeriodDate;
  toDate: TPeriodDate;
}

interface IUseTotalWaterData {
  waterTotal?: TWaterTotalResponse;
  selectedPeriod: PERIOD_ENUM;
  handleChangePeriod: (_: React.MouseEvent<HTMLElement>, newPeriodProps: PERIOD_ENUM) => void;
  handleCustomRangeSet: (value: ICustomPeriodModalProps) => void;
  isLoading: boolean;
  compareToValue: TCompareToValue;
  onCompareToChange: (compareTo: TCompareToValue) => void;
  currentPeriod: { from: TPeriodDate; to: TPeriodDate };
}

export const useTotalWaterData = (): IUseTotalWaterData => {
  const {
    range: currentPeriod,
    selectedPeriod,
    handleChangePeriod,
    handleCustomRangeSet,
  } = usePeriodState(PERIOD_ENUM.WEEK);
  const { previousPeriodStart, onCompareToChange, compareToValue } = useTotalWaterCompare({
    currentPeriod,
    selectedPeriod,
  });

  const waterTotalParams = useMemo(() => {
    const numberofBuckets = getNumberOfBucketsExpandedDrawer({
      minSelection: currentPeriod.from?.toDate().getTime() || 0,
      maxSelection: currentPeriod.to?.toDate().getTime() || 0,
    });
    const bucketSize = getBucketSize(
      currentPeriod.from?.toISOString() || '',
      currentPeriod.to?.toISOString() || '',
      numberofBuckets
    );
    const enabled = [currentPeriod.from, currentPeriod.to, bucketSize, previousPeriodStart].every(Boolean);

    return {
      periodStart: currentPeriod.from?.toISOString() || '',
      periodEnd: currentPeriod.to?.toISOString() || '',
      previousPeriodStart: previousPeriodStart || '',
      aggregationMinutes: bucketSize,
      enabled,
    };
  }, [currentPeriod.from, currentPeriod.to, previousPeriodStart]);

  const { data: waterTotal, isLoading, isFetched } = useWaterTotal(waterTotalParams);

  return {
    waterTotal,
    selectedPeriod,
    handleChangePeriod,
    handleCustomRangeSet,
    isLoading: isLoading && !isFetched,
    compareToValue,
    onCompareToChange,
    currentPeriod,
  };
};
