import { MarlinTheme } from '@marlin/shared/theme';
import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  readOnlyContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    width: '100%',
  },
  textLine: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    width: '100%',
  },
}));

interface IReadOnlySectionProps {
  children: ReactNode;
  onEditClick: () => void;
  testid: string;
}

export const ReadOnlySection = ({ children, onEditClick, testid }: IReadOnlySectionProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.readOnlyContent} data-testid={`organization-settings-readonly-section-${testid}`}>
      <div className={classes.textLine}>{children}</div>
      <div>
        <Button
          variant="contained"
          onClick={onEditClick}
          data-testid={`organization-settings-readonly-edit-button-${testid}`}
        >
          {content.EDIT}
        </Button>
      </div>
    </div>
  );
};
