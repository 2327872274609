import { MarlinTheme } from '@marlin/shared/theme';
import { Input } from '@marlin/shared/ui-form-common';
import { Autocomplete, MenuItem, PaperProps, createFilterOptions } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../context';
import { IRecipient } from '../../types';
import { CustomPaper } from './custom-paper.component';
import { CustomTag } from './custom-tag.component';

interface ISelectRecipientProps {
  recipients: IRecipient[];
  onAddRecipientButtonClick: () => void;
  disabled: boolean;
  onChange: (data: IRecipient[]) => void;
  shouldDisableRecipients?: boolean;
  value: IRecipient[];
  error?: FieldError;
  id?: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    flex: '70%',
  },
  phoneNumber: {
    color: theme.palette.text.disabled,
    fontSize: theme.typography.pxToRem(12),
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  input: {
    '&> .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      // TODO: find different way to overwrite autocomplete input
      paddingRight: `${theme.typography.pxToRem(10)} !important`,
    },
  },
}));

export function SelectRecipient({
  recipients,
  onAddRecipientButtonClick,
  value,
  onChange,
  shouldDisableRecipients,
  disabled,
  error,
  id,
}: ISelectRecipientProps) {
  const { classes } = useStyles();
  const content = useContent();
  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option: IRecipient) => `${option.firstName} ${option.lastName}`,
      }),
    []
  );
  const handleChange = useCallback(
    (event: SyntheticEvent, value: IRecipient[]) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      multiple
      className={classes.select}
      value={value}
      id="select-recipients"
      data-testid={id}
      options={recipients}
      disabled={disabled}
      filterOptions={filterOptions}
      getOptionLabel={(option: IRecipient) => `${option.firstName} ${option.lastName}`}
      renderOption={(props, option: IRecipient) => (
        <MenuItem {...props} key={option.id} disabled={shouldDisableRecipients && !option.phoneNumber}>
          <span className={classes.option}>
            <span>{`${option.firstName} ${option.lastName}`}</span>
            <span className={classes.phoneNumber}>
              {shouldDisableRecipients && !option.phoneNumber && content.N0_PHONE_NUMBER}
            </span>
          </span>
        </MenuItem>
      )}
      renderTags={(users: IRecipient[], getTagProps: AutocompleteRenderGetTagProps) =>
        users.map((user, index) => <CustomTag user={user} {...getTagProps({ index })} key={index} />)
      }
      limitTags={2}
      onChange={handleChange}
      filterSelectedOptions
      renderInput={(params) => (
        <Input className={classes.input} error={error} label={content.ACTION.RECIPIENT.LABEL} {...params} />
      )}
      PaperComponent={(props: PaperProps) => <CustomPaper onMouseDown={onAddRecipientButtonClick} {...props} />}
    />
  );
}
