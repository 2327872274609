import { TAutomationBuilder, TRule } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRulesStore$ } from '../../hooks/use-observable-rules-store';
import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { isEquipmentOrSensorStore } from '../../shared/utils/type-guards';
import { operatorsContent } from './content';
import { RuleBuilder } from './rule-builder.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  channelContainer: {
    minWidth: theme.typography.pxToRem(400),
    padding: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  ruleContainer: {
    display: 'flex',
    gap: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(16),
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  select: {
    width: '100%',
  },
  ruleContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Rule = ({ id, parentId }: { id: string; parentId?: string }) => {
  const { classes } = useStyles();
  const { currentState } = useTriggersStore$();
  const { addRule } = useRulesStore$();
  const { field } = useController<TAutomationBuilder>({ name: 'trigger.operation' });
  const [rule, setRule] = useState<TRule | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [metadata, setMetadata] = useState<TDeviceMetadataDatapointsResponse | undefined>(undefined);

  const { deleteElements } = useReactFlow();

  const onCancel = useCallback(async () => {
    if (!isEditing) {
      await deleteElements({
        nodes: [{ id }],
      });
    } else {
      setIsEditing(false);
    }
  }, [deleteElements, id, isEditing]);

  const onSubmit = useCallback(
    (rule: TRule, metadata?: TDeviceMetadataDatapointsResponse) => {
      addRule(
        {
          ...rule,
          id,
        },
        parentId
      );
      field.onChange(rule);
      setMetadata(metadata);
      setRule(rule);
      if (isEditing) setIsEditing(false);
    },
    [addRule, field, id, isEditing, parentId]
  );

  if (!isEquipmentOrSensorStore(currentState)) {
    return null;
  }

  if (rule && !isEditing) {
    return (
      <Paper className={classes.ruleContainer}>
        <span className={classes.ruleContent}>{rule.datapoint}</span>
        <span className={classes.ruleContent}>{operatorsContent[rule.operator]}</span>
        <span className={classes.ruleContent}>
          {parseDisplayedValue(rule.threshold, metadata?.unitOfMeasure ?? '')}
        </span>
        <IconButton color="default" onClick={() => setIsEditing(true)} className={classes.ruleContent}>
          <EditIcon />
        </IconButton>
      </Paper>
    );
  }

  return (
    <Paper className={classes.channelContainer}>
      {currentState?.models && (
        <RuleBuilder model={currentState?.models[0]} onSubmit={onSubmit} rule={rule} onCancel={onCancel} />
      )}
    </Paper>
  );
};
