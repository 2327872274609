import { EQUIPMENT_UPSERT_ERROR } from '@marlin/asset/data-access/equipment';
import { CommissionDateField, ICommissionDateFieldProps } from '@marlin/shared/ui-form';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { TEquipment } from '@marlin/shared/utils/zod';
import { Icon, IconButton, Link } from '@mui/material';
import { useCallback } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { content } from '../content';
import { useStyles } from '../upsert-equipment.styles';

interface IAercoEquipmentProps extends ICommissionDateFieldProps {
  isEdit: boolean;
  upsertEquipmentErrorCode: EQUIPMENT_UPSERT_ERROR | undefined;
  openInstructionsModal: (defaultStep?: number) => void;
}

export const AercoForm = ({
  isEdit,
  setDateValidationErrorMessage,
  dateValidationErrorMessage,
  upsertEquipmentErrorCode,
  openInstructionsModal,
}: IAercoEquipmentProps) => {
  const {
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useFormContext<TEquipment>();

  const registrationCode = watch('registrationCode');
  const serialNumber = watch('serialNumber');
  const openInstructionsModalActivationCode = () => {
    openInstructionsModal(1);
  };

  const handleClearRegisterCode = useCallback(() => {
    setValue('registrationCode', '');
    trigger('registrationCode');
  }, []);

  const handleClearSerialNumber = useCallback(() => {
    setValue('serialNumber', '');
    trigger('serialNumber');
  }, []);

  const sharedSerialNumberAndFixedIdError = getSharedSerialNumberAndFixedIdError(upsertEquipmentErrorCode, errors);

  const onFixedIdChange = useCallback(() => {
    if (sharedSerialNumberAndFixedIdError) {
      clearErrors('serialNumber');
    }
  }, [sharedSerialNumberAndFixedIdError]);

  const onSerialNumberChange = useCallback(() => {
    if (sharedSerialNumberAndFixedIdError) {
      clearErrors('registrationCode');
    }
  }, [sharedSerialNumberAndFixedIdError]);

  const { classes } = useStyles();

  return (
    <>
      <>
        {isEdit ? (
          <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>
        ) : (
          <>
            <div className={classes.tooltip}>{content.REGISTER_LABEL}</div>
            <div className={classes.registerCodeSubSection}>{content.REGISTER_SUBTITLE_LABEL}</div>
          </>
        )}
        {isEdit && (
          <>
            <FormField<TEquipment> fieldName="brand">
              {(props) => (
                <Input disabled className={classes.input} label={content.BRAND} {...props} testId="brand-field" />
              )}
            </FormField>
            <FormField<TEquipment> fieldName="model">
              {(props) => (
                <Input disabled {...props} className={classes.input} label={content.MODEL} testId="model-field" />
              )}
            </FormField>
          </>
        )}
        <FormField<TEquipment> fieldName="serialNumber">
          {(props) => (
            <Input
              required
              label={content.SERIAL_NUMBER}
              disabled={isEdit}
              {...props}
              onChange={(e) => {
                onSerialNumberChange();
                props.onChange?.(e);
              }}
              testId="serial-number-field"
              error={
                errors.serialNumber && {
                  type: 'custom',
                  message: errors.serialNumber.message,
                }
              }
              externalEndAdornment={{
                endAdornment: (
                  <>
                    {serialNumber && !isEdit && (
                      <IconButton
                        className={classes.crossIcon}
                        onClick={handleClearSerialNumber}
                        data-testid="register-code-clear-button"
                      >
                        <Icon baseClassName="material-symbols-outlined">{content.CANCEL}</Icon>
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          )}
        </FormField>
        <div className={classes.linkText}>
          <Link onClick={() => openInstructionsModal()} className={classes.link} data-testid="serial-number-link">
            {content.SERIAL_NUMBER_LINK}
          </Link>
        </div>
        {!isEdit && (
          <FormField<TEquipment> fieldName="registrationCode">
            {(props) => (
              <Input
                {...props}
                onChange={(e) => {
                  onFixedIdChange();
                  props.onChange?.(e);
                }}
                required
                type={INPUT_TYPE.NUMBER}
                maxLength={10}
                label={content.CONTROLLER_FIXED_ID}
                testId="fixed-id-field"
                error={
                  errors.registrationCode && {
                    type: 'custom',
                    message: errors.registrationCode.message,
                  }
                }
                externalEndAdornment={{
                  endAdornment: (
                    <>
                      {registrationCode && (
                        <IconButton
                          className={classes.crossIcon}
                          onClick={handleClearRegisterCode}
                          data-testid="register-code-clear-button"
                        >
                          <Icon baseClassName="material-symbols-outlined">{content.CANCEL}</Icon>
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            )}
          </FormField>
        )}
        <div className={classes.linkText}>
          <Link
            onClick={openInstructionsModalActivationCode}
            className={classes.link}
            data-testid="controller-fixed-id-link"
          >
            {content.CONTROLLER_FIXED_ID_LINK}
          </Link>
        </div>
        {sharedSerialNumberAndFixedIdError && (
          <span className={classes.sharedError}>{sharedSerialNumberAndFixedIdError}</span>
        )}
      </>
      {!isEdit && <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>}
      <CommissionDateField
        setDateValidationErrorMessage={setDateValidationErrorMessage}
        dateValidationErrorMessage={dateValidationErrorMessage}
      />
      <FormField<TEquipment> fieldName="description">
        {(props) => (
          <Input
            {...props}
            className={classes.input}
            label={content.DESCRIPTION}
            testId="description-field"
            maxLength={500}
            multiline
          />
        )}
      </FormField>
    </>
  );
};

const getSharedSerialNumberAndFixedIdError = (
  upsertEquipmentErrorCode: EQUIPMENT_UPSERT_ERROR | undefined,
  errors: FieldErrors<TEquipment>
) => {
  const noMessages = !errors.serialNumber?.message && !errors.registrationCode?.message;
  const isError = errors.serialNumber || errors.registrationCode;
  const isSharedErrorCode =
    upsertEquipmentErrorCode &&
    [
      EQUIPMENT_UPSERT_ERROR.INVALID_DEVICE,
      EQUIPMENT_UPSERT_ERROR.EQUIPMENT_FIXED_ID_DOES_NOT_EXIST,
      EQUIPMENT_UPSERT_ERROR.EQUIPMENT_FIXED_ID_DOES_NOT_MATCH,
      EQUIPMENT_UPSERT_ERROR.EQUIPMENT_DEVICE_ALREADY_REGISTERED,
    ].includes(upsertEquipmentErrorCode);

  if (upsertEquipmentErrorCode === EQUIPMENT_UPSERT_ERROR.EQUIPMENT_DEVICE_ALREADY_REGISTERED) {
    return content.EQUIPMENT_ALREADY_REGISTERED_AERCO;
  }

  if (noMessages && isSharedErrorCode && isError) {
    return content.SERIAL_NUMBER_OR_FIXED_ID_INVALID;
  }

  return null;
};
