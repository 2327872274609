import { ISection } from '@marlin/asset/shared/equipment-config';

import { icons } from '../../../../shared/assets';
import { content } from '../../../content';
import { intellistationEventCodes } from './event-codes';

export const sectionConfig: ISection[] = [
  {
    title: content.SYSTEM_STATUS,
    dataTestId: 'system-status-section',
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'temperatureSensors',
        title: content.SENSOR_TEMPERATURE,
        icon: icons.temperature,
        items: [
          {
            name: 'hotTemp',
            label: content.DATAPOINT_LABELS.HOT_TEMP,
            eventCodes: intellistationEventCodes.temperatureSensors.hotTemp,
          },
          {
            name: 'coldTemp',
            label: content.DATAPOINT_LABELS.COLD_TEMP,
            eventCodes: intellistationEventCodes.temperatureSensors.coldTemp,
          },
          {
            name: 'recircTemp',
            label: content.DATAPOINT_LABELS.RECIRC_TEMP,
            eventCodes: intellistationEventCodes.temperatureSensors.recircTemp,
          },
        ],
      },
    ],
  },
  {
    title: content.SYSTEM_INPUTS,
    dataTestId: 'system-inputs-section',
    style: {
      fullWidth: true,
    },
    sectionDatapoints: [
      {
        sectionName: 'pressureSensors',
        title: content.SENSOR_PRESSURE,
        icon: icons.indicator,
        items: [
          {
            name: 'outletPressure',
            label: content.DATAPOINT_LABELS.OUTLET_PRESSURE,
            eventCodes: intellistationEventCodes.pressureSensors.outletPressure,
          },
          {
            name: 'recircPressure',
            label: content.DATAPOINT_LABELS.RECIRC_PRESSURE,
            eventCodes: intellistationEventCodes.pressureSensors.recircPressure,
          },
          {
            name: 'hotPressure',
            label: content.DATAPOINT_LABELS.HOT_PRESSURE,
            eventCodes: intellistationEventCodes.pressureSensors.hotPressure,
          },
          {
            name: 'coldPressure',
            label: content.DATAPOINT_LABELS.COLD_PRESSURE,
            eventCodes: intellistationEventCodes.pressureSensors.coldPressure,
          },
        ],
      },
      {
        sectionName: 'flowSensors',
        title: content.FLOW_SENSORS,
        icon: icons.flow,
        items: [
          {
            name: 'outletFlow',
            label: content.DATAPOINT_LABELS.OUTLET_FLOW,
            eventCodes: intellistationEventCodes.flowSensors.outletFlow,
          },
          {
            name: 'recircFlow',
            label: content.DATAPOINT_LABELS.RECIRC_FLOW,
            eventCodes: intellistationEventCodes.flowSensors.recircFlow,
          },
        ],
      },
    ],
  },
  {
    title: content.SYSTEM_OUTPUTS,
    dataTestId: 'system-outputs-section',
    sectionDatapoints: [
      {
        sectionName: 'valves',
        title: content.VALVES,
        icon: icons.valve,
        items: [
          { name: 'isolationValve', label: content.DATAPOINT_LABELS.ISOLATION_VALVE },
          {
            name: 'mixingValve',
            label: content.DATAPOINT_LABELS.MIXING_VALVE,
          },
        ],
      },
      {
        sectionName: 'pumps',
        title: content.PUMPS,
        icon: icons.pump,
        items: [
          {
            name: 'pump1Status',
            label: content.DATAPOINT_LABELS.SYSTEM_PUMP_1,
            eventCodes: intellistationEventCodes.pumps.pump1Status,
          },
          {
            name: 'pump2Status',
            label: content.DATAPOINT_LABELS.SYSTEM_PUMP_2,
            eventCodes: intellistationEventCodes.pumps.pump2Status,
          },
        ],
      },
    ],
  },
];
