import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  highlighted: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  error: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.main,
  },
  custom: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.disabled,
  },
}));
