import { zodResolver } from '@hookform/resolvers/zod';
import {
  CHANNEL_TYPE,
  CRITICALITY,
  TAutomationBuilder,
  automationBuilderSchema,
  useCreateAutomationComplex,
} from '@marlin/alert/data-access/automated-action';
import { AutomationCardBuilder } from '@marlin/alert/shared/ui/automation-card';
import { AutomationContentContext } from '@marlin/alert/ui/automated-action-create';
import { PageContainer } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { ReactFlowProvider } from '@xyflow/react';
import '@xyflow/react/dist/base.css';
import '@xyflow/react/dist/style.css';
import { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Canvas } from './canvas.component';
import { uiContent } from './content';

const useStyles = makeStyles()(() => ({
  modal: {
    width: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  container: {
    height: '100%',
    margin: 0,
  },
}));

const defaultValues: TAutomationBuilder = {
  id: '',
  name: '',
  manufacturerIdList: [],
  isEnabled: true,
  snoozeIntervalInMinutes: 0,
  criticality: CRITICALITY.LOW,
  channels: {
    [CHANNEL_TYPE.IN_APP]: {
      type: CHANNEL_TYPE.IN_APP,
    },
  },
  trigger: {
    devices: [],
    operation: null,
  },
};

export function AutomationBuilder() {
  const { classes } = useStyles();
  const form = useForm<TAutomationBuilder>({
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver(automationBuilderSchema),
  });
  const { modalDispatch } = useContext(ModalContext);

  const createAutomation = useCreateAutomationComplex();

  const handleSubmit = useCallback(
    (values: TAutomationBuilder) => {
      createAutomation.mutate(values);
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          modalClassName: classes.modal,
          body: <AutomationCardBuilder item={values} />,
        },
      });
    },
    [classes.modal, createAutomation, modalDispatch]
  );

  const onInvalid = useCallback(
    (e: unknown) => {
      // eslint-disable-next-line no-console
      console.log('invalid', e);
      try {
        automationBuilderSchema.parse(form.getValues());
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [form]
  );

  return (
    <PageContainer prefix="automation-builder" className={classes.container}>
      <AutomationContentContext.Provider value={uiContent}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.container}>
            <ReactFlowProvider>
              <div style={{ height: '100%', width: '100%', minHeight: '100vh' }}>
                <Canvas handleSubmit={form.handleSubmit(handleSubmit, onInvalid)} />
              </div>
            </ReactFlowProvider>
          </form>
        </FormProvider>
      </AutomationContentContext.Provider>
    </PageContainer>
  );
}
