import { TAutomationBuilder, TOperation, TRule } from '@marlin/alert/data-access/automated-action';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { useAssets } from '@marlin/system-map/data-access/system-map';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AlertIntervalSection } from './alert-interval-section';
import { operatorsContent } from './automation-builder-content';
import { Channels } from './channels.component';
import { content } from './content';
import { DetailsItem } from './details-item.component';
import { CardHeaderBuilder } from './header-builder.component';

const isRule = (operation: TOperation | TRule): operation is TRule => {
  return (operation as TRule).datapoint !== undefined;
};

const formatTriggerOperation = (operation?: TOperation | TRule) => {
  if (!operation) {
    return '';
  }
  if (isRule(operation)) {
    return `${operation.datapoint} ${operatorsContent[operation.operator]} ${operation.threshold}`;
  }
  return `(${formatTriggerOperation(operation.operands[0])}) ${operation.logicalOperator} (${formatTriggerOperation(
    operation.operands[1]
  )})`;
};

export const useStyles = makeStyles()(() => ({
  disabled: {
    opacity: 0.6,
    filter: 'gray',
    '-webkit-filter': 'grayscale(100%)',
    '-moz-filter': 'grayscale(100%)',
    '-ms-filter': 'grayscale(100%)',
    '-o-filter': 'grayscale(100%)',
  },
}));

interface IAutomationCardProps extends Record<string, unknown> {
  item: TAutomationBuilder;
}

export function AutomationCardBuilder({ item }: IAutomationCardProps) {
  const { classes, cx } = useStyles();

  const title = item.name;
  const { data } = useAssets({}, item.trigger.devices.length > 0);

  const items = useMemo(() => {
    return data?.filter((asset) => item.trigger.devices.includes(asset.id)) || [];
  }, [data, item.trigger.devices]);

  return (
    <Grid item xs={12}>
      <Paper testId="card">
        <CardHeaderBuilder title={title} enabled={true} />
        <Grid container>
          <Grid item xs={12}>
            <DetailsItem
              label={content.CARD_LABELS.TRIGGER}
              value={formatTriggerOperation(item.trigger.operation)}
              testId="automation-trigger"
            />
          </Grid>
        </Grid>
        <Grid container className={cx({ [classes.disabled]: !item.isEnabled })}>
          <Grid item xs={6}>
            <DetailsItem
              label={content.CARD_LABELS.ACTION}
              value={<Channels channels={item.channels} />}
              testId="automation-actions"
            />
            <DetailsItem
              label={content.ALERTS}
              testId="automation-alert-interval"
              value={
                <AlertIntervalSection automationMode={true} snoozeIntervalInMinutes={item.snoozeIntervalInMinutes} />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DetailsItem
              label={content.CARD_LABELS.CRITICALITY}
              value={<CriticalityControl severity={item.criticality} />}
              testId="automation-criticality"
            />
            <DetailsItem
              label={'Device(s)'}
              value={<div style={{ display: 'flex', gap: 4 }}>{items.map((i) => i.name).join(', ')}</div>}
              testId="automation-criticality"
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
