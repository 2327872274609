import { createTheme } from '@mui/material/styles';

const defaultConfig = createTheme();
const defaultDarkConfig = createTheme({
  palette: {
    mode: 'dark',
  },
});

export { defaultConfig, defaultDarkConfig };
