import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { createApiKey } from '../infrastructure/create-api-key';
import { TApiKeyRequest } from '../schemas/api-key.schema';
import { queryKey } from './query-key.enum';

export const useCreateApiKey = () => {
  return useMutation<unknown, AxiosError, TApiKeyRequest, void>({
    mutationFn: createApiKey,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.VENDOR_API_KEY() });
    },
  });
};
