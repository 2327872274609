import { MarlinTheme } from '@marlin/shared/theme';
import { TSystemTag } from '@marlin/system-map/shared/data-access-schemas';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { SystemTagTooltip } from './system-tag-tooltip.component';

export const useStyles = makeStyles<{ isSensor: boolean }>()((theme: MarlinTheme, { isSensor }) => ({
  nodeTag: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    borderRadius: '50%',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.text.alternative}`,
    backgroundColor: theme.palette.charting.plasmaLavender,
    position: 'absolute',
    top: isSensor ? 0 : -12,
    left: 0,
  },
}));

export const SystemTagComponent = ({ tags, isSensor = false }: { tags: TSystemTag[]; isSensor?: boolean }) => {
  const { classes } = useStyles({ isSensor });
  if (!tags || !tags.length || tags.every((tag) => tag?.length === 0)) {
    return null;
  }
  const readableTag = tags
    .filter((tag) => tag !== null && tag !== undefined)
    .map((tag) => content.SYSTEM_TAG.TAGS[tag ?? ''])
    .join(', ');
  return (
    <SystemTagTooltip text={readableTag}>
      <div className={classes.nodeTag} />
    </SystemTagTooltip>
  );
};
