export const paths = {
  WEBHOOK: '/vendor/api/webhook',
  WEBHOOK_FILTER: '/vendor/api/webhook/filter',
  WEBHOOK_ID: '/vendor/api/webhook/{webhookId}',
  WEBHOOK_STATUS: '/vendor/api/webhook/{webhookId}/{action}',
  VENDOR_API_KEY: '/vendor/api/vendor-subscription',
  VENDOR_API_KEY_ENABLE: '/vendor/api/vendor-subscription/{vendorSubscriptionId}/enable',
  VENDOR_API_KEY_DISABLE: '/vendor/api/vendor-subscription/{vendorSubscriptionId}/disable',
  VENDOR_API_KEY_REGENERATE: '/vendor/api/vendor-subscription/{vendorSubscriptionId}/subscription-key',
};
