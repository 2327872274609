import { z } from 'zod';

export const systemTagValuesSchema = z
  .union([
    z.literal('DOMESTIC_HOT_WATER'),
    z.literal('DOMESTIC_COLD_WATER'),
    z.literal('HEATING_HOT_WATER'),
    z.literal('CHILLED_WATER'),
    z.literal('CONDENSER_WATER'),
    z.literal(''),
  ])
  .nullish();

export const systemTagSchema = z.array(systemTagValuesSchema).nullish();

export type TSystemTag = z.infer<typeof systemTagValuesSchema>;
