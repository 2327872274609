import { createContext, useContext } from 'react';

import { ColorMode } from './theme';

interface IColorModeContext {
  setMode: (mode: ColorMode) => void;
  mode: ColorMode;
}

export const ColorModeContext = createContext<IColorModeContext | undefined>(undefined);

export const useColorModeContext = () => {
  const value = useContext<IColorModeContext | undefined>(ColorModeContext);

  if (value === undefined) {
    throw new Error(`No ColorMode context found when calling useContext.`);
  }

  return value;
};
