import { RoleSchema, SupportRoleSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

const invitationStatusSchema = z.union([z.literal('Expired'), z.literal('Pending')]);

const sortingSchema = z.object({
  direction: z.union([z.literal('Ascending'), z.literal('Descending')]),
  sortBy: z.string(),
});

export const paginationSchema = z.object({
  pageSize: z.number(),
  page: z.number(),
  totalItems: z.number(),
});

const OrganizationSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const getSupportUserPayload = z.object({
  search: z.string().optional(),
  pageSize: z.number(),
  page: z.number(),
  sorting: sortingSchema.optional(),
  onlyUsers: z.boolean().optional(),
  supportRole: SupportRoleSchema.optional().transform((value) => {
    switch (value) {
      case 'Support':
        return 2;
      case 'CSM':
        return 4;
      case 'BUCS':
        return 3;
      default:
        return undefined;
    }
  }),
});

export const supportUserSchema = z.object({
  firstName: z.string(),
  id: z.string(),
  lastName: z.string(),
  title: z.string().nullish(),
  email: z.string(),
  phone: z.string(),
  role: SupportRoleSchema,
  organizations: z.array(OrganizationSchema).optional(),
});

export const supportUserFromListSchema = z.object({
  firstName: z.string().nullable(),
  id: z.string().nullish(),
  lastName: z.string().nullable(),
  title: z.string().nullish(),
  email: z.string(),
  phone: z.string().nullable(),
  role: SupportRoleSchema,
  organizations: z.array(OrganizationSchema).optional(),
  invitationStatus: invitationStatusSchema.nullish(),
});

export const getSupportUserResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(supportUserFromListSchema),
});

export const elevateUserSchema = z.object({
  email: z.string(),
  role: RoleSchema,
});

export const inviteSupportUserSchema = z.object({
  email: z.string(),
  role: SupportRoleSchema,
});

export const updateSupportUserSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  role: SupportRoleSchema,
  phone: z.string(),
});

export type TGetSupportUsersPayload = z.input<typeof getSupportUserPayload>;
export type TGetSupportUserResponse = z.infer<typeof getSupportUserResponseSchema>;
export type TElevateUserSchema = z.infer<typeof elevateUserSchema>;
export type TSupportUserSchema = z.infer<typeof supportUserSchema>;
export type TUpdateSupportUserSchema = z.infer<typeof updateSupportUserSchema>;
export type TInviteSupportUserSchema = z.infer<typeof inviteSupportUserSchema>;
export type TSupportUserFromListSchema = z.infer<typeof supportUserFromListSchema>;
