import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TSourceSinkTagParams, sourceSinkTagParamsSchema } from '../system-tag.schema';
import { paths } from './api';

export const setSourceSinkTag = async (requestParams: TSourceSinkTagParams): Promise<unknown> => {
  const params = sourceSinkTagParamsSchema.parse(requestParams);

  return await getHttpClient().put(paths.SOURCE_SINK_TAGS, undefined, {
    params,
  });
};
