import { queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationResult, useMutation } from '@marlin/shared/utils/react-query';

import { setSourceSinkTag } from '../infrastructure/source-sink-tag';
import { TSourceSinkTagParams } from '../system-tag.schema';
import { queryKey } from './query-key.enum';

export const useSetSourceSinkTag = (): UseMutationResult<unknown, unknown, TSourceSinkTagParams> => {
  return useMutation<unknown, unknown, TSourceSinkTagParams>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.invalidateQueries({ queryKey: queryKey.GRAPH() });
    },
    mutationFn: setSourceSinkTag,
  });
};
