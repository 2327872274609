import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { MODEL, TModel } from '@marlin/shared/utils/zod';
import { useCallback, useMemo } from 'react';

export interface IEquipmentModelOption {
  id: TModel;
  name: TModel;
}

export const useEquipmentModelOptions = (): IEquipmentModelOption[] => {
  const { sentinel, waterHeater, boiler } = useFeatureFlagsContext();

  const featureFlagFilter = useCallback(
    (model: MODEL) => {
      if (model === MODEL.SENTINEL) {
        return sentinel;
      }

      if (model === MODEL.AERCO_WATER_HEATER_N || model === MODEL.AERCO_WATER_HEATER_INNOVATION) {
        return waterHeater;
      }

      if (model === MODEL.AERCO_BOILER_LN || model === MODEL.AERCO_BOILER_DUAL) {
        return boiler;
      }

      return true;
    },
    [boiler, sentinel, waterHeater]
  );

  return useMemo(
    () =>
      Object.values(MODEL)
        .filter(featureFlagFilter)
        .map((model) => ({
          id: model,
          name: model,
        })),
    [featureFlagFilter]
  );
};
