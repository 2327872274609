import { useState } from 'react';

interface IUseEditMode {
  editMode: boolean;
  toggleEditMode: () => void;
}

export const useEditMode = (): IUseEditMode => {
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  return { editMode, toggleEditMode };
};
