import { INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { useState } from 'react';

import { content } from './content';
import { Copy } from './copy.component';
import { useStyles } from './monnit-api-key.styles';
import { Visibility } from './visibility.component';

interface IApiKeyProps {
  keyValue: string;
}

export const VendorApiKey = ({ keyValue = '' }: IApiKeyProps) => {
  const { classes } = useStyles();
  const [valueInputType, setValueInputType] = useState(INPUT_TYPE.PASSWORD);

  const valueEndAdornment = (
    <div className={classes.adornment}>
      <Visibility inputType={valueInputType} onInputTypeChange={setValueInputType} />
      <Copy value={keyValue} testId="vendor-api-key-value" />
    </div>
  );

  return (
    <Input
      disabled
      label={content.VENDOR_API_KEY_VALUE}
      value={keyValue}
      type={valueInputType}
      externalEndAdornment={{
        endAdornment: valueEndAdornment,
      }}
    />
  );
};
