import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    '& .MuiPaper-root': {
      width: theme.typography.pxToRem(320),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
}));
