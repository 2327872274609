import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

interface IActionCustomProps {
  optionText: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: JSX.Element;
}

export const ActionCustom = ({ optionText, onClick, disabled = false, icon }: IActionCustomProps) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{optionText}</ListItemText>
    </MenuItem>
  );
};
