import { TDeviceType } from '@marlin/shared/ui-form';

import { TSensorModel } from '../../types';

export const sensorModelToSensorTypeMap = new Map<TSensorModel, TDeviceType>([
  ['WNS2-9-W2-DC-CF-L01', 'LEAK'],
  ['WNS2-9-W2-PC-01', 'PULSE_METER'],
  ['WNS2-9-W2-PC-02', 'PULSE_METER'],
  ['WNS2-9-W2-PS-300', 'PRESSURE'],
  ['WNS2-9-W2-TS-ST', 'TEMPERATURE'],
  ['WNS2-9-W2-WS-WP-L03', 'LEAK'],
  ['WNS2-9-W2-WS-WR', 'LEAK'],
]);

export const getModelsBySensorType = (sensorType: TDeviceType): TSensorModel[] => {
  const models: TSensorModel[] = [];
  sensorModelToSensorTypeMap.forEach((value, key) => {
    if (value === sensorType) {
      models.push(key);
    }
  });

  return models;
};
