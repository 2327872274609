import { Edge, MarkerType, Node, NodeChange, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import { useEffect } from 'react';

import ComponentNode from './component-node.component';

const nodeTypes = {
  componentNode: ComponentNode,
};

interface ICanvas {
  edge: Edge[];
  nodes: Node[];
  isFetching: boolean;
}

export const Canvas = ({ isFetching, edge, nodes: initNodes }: ICanvas) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(edge);

  useEffect(() => {
    const mappedNodes = initNodes.map((node) => {
      let position = node.position;
      try {
        const nodePosition = localStorage.getItem(`node-${node.id}`);
        if (nodePosition) {
          position = JSON.parse(nodePosition);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error parsing position from local storage', e);
      }

      return {
        ...node,
        position,
      };
    });
    setNodes(mappedNodes);
    setEdges(edge);
  }, [initNodes, edge, setNodes, setEdges, isFetching]);

  const handleChartNode = (nodes: NodeChange<Node>[]) => {
    if (nodes[0].type === 'position') {
      // TODO: move logic to BE
      const currentNode = nodes[0] as unknown as { position: { x: string; y: string }; id: string };

      localStorage.setItem(`node-${currentNode.id}`, JSON.stringify(currentNode.position));
      onNodesChange(nodes);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%', minHeight: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={(nodes) => handleChartNode(nodes)}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitView
        preventScrolling
        proOptions={{
          hideAttribution: true,
        }}
        defaultEdgeOptions={{
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 32,
            height: 32,
          },
        }}
      />
    </div>
  );
};
