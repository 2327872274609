import { TOperator } from '@marlin/alert/data-access/automated-action';

type TOperatorContent = {
  [key in TOperator]: string;
};

export const operatorsContent: TOperatorContent = {
  eq: 'is equal',
  neq: 'is not equal',
  gt: 'is greater than',
  gte: 'is greater than or equal',
  lt: 'is less than',
  lte: 'is less than or equal',
};
