import { useMemo } from 'react';

import { TSystemMapNodeData } from '../../../types';

export const useNodeTags = (data: TSystemMapNodeData) => {
  const isWaterSource = useMemo(() => {
    const currentSourceSinkTag = data.sourceSinkTags || [];
    return currentSourceSinkTag.includes('POE_WATER_SOURCE');
  }, [data.sourceSinkTags]);

  const hasTag = useMemo(() => {
    if (!data?.systemTags?.length) {
      return false;
    }
    return data?.systemTags?.length > 0;
  }, [data?.systemTags]);

  const nodeTags = useMemo(() => {
    if (hasTag) {
      return data.systemTags ?? [];
    }
    return [];
  }, [data.systemTags, hasTag]);

  return { isWaterSource, hasTag, nodeTags };
};
