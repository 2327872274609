import { environment } from '@marlin/environment';
import { CssBaseline, StyledEngineProvider, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { ComponentType, useCallback } from 'react';
import { GlobalStyles } from 'tss-react';

import { ColorModeContext } from './color-mode-context';
import { ColorMode, MarlinTheme } from './theme';
import { themeStorageService } from './theme-storage';

export const withTheme =
  (Cmp: ComponentType, theme: (mode: ColorMode) => MarlinTheme) =>
  (props: Record<string, unknown>): JSX.Element => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const defaultMode =
      environment.module.features.darkMode && environment.app.name === 'Nexa'
        ? themeStorageService.getTheme() || (prefersDarkMode ? 'dark' : 'light')
        : 'light';

    const [mode, setMode] = React.useState<ColorMode>(defaultMode as ColorMode);
    const setColorMode = useCallback((mode: ColorMode) => {
      setMode(mode);
      themeStorageService.setTheme(mode);
    }, []);

    const currentTheme = theme(mode);

    return (
      <StyledEngineProvider injectFirst>
        <ColorModeContext.Provider value={{ setMode: setColorMode, mode }}>
          <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            {/* TODO: Improve this to one Link component which is mixed with mui and react router components */}
            <GlobalStyles styles={{ a: { color: currentTheme.palette.primary.main } }} />
            <Cmp {...props} />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </StyledEngineProvider>
    );
  };
