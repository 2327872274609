import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Popover } from '@mui/material';
import { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IMessage, TSendMessage } from '../types';
import { createChatGradient } from '../utils/colors';
import { ChatForm } from './chat-input.component';
import { ChartWarning } from './chat-warning.component';
import { MessageList } from './message-list.component';

interface IChatWindowProps {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  messages: IMessage[];
  sendMessage: TSendMessage;
  isAnswering?: boolean;
  clearChat?: () => void;
}

const windowHeight = 580;
const messagesOffset = 162;

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chatWindowPaper: {
    backgroundImage: `linear-gradient(${theme.palette.primary.contrastText}, ${
      theme.palette.primary.contrastText
    }), ${createChatGradient(theme)}`,
    border: '1px solid transparent',
    borderRadius: theme.typography.pxToRem(32),
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(windowHeight),
    width: theme.typography.pxToRem(368),
  },
  chatWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  chatMessages: {
    flex: 1,
    maxHeight: `calc(${windowHeight}px - ${theme.typography.pxToRem(messagesOffset)})`,
    minHeight: `calc(${windowHeight}px - ${theme.typography.pxToRem(messagesOffset)})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
  },
  chatInput: {
    paddingTop: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(12),
  },
  chatWindowRoot: {
    marginBottom: theme.typography.pxToRem(8),
    inset: `-${theme.typography.pxToRem(8)} 0 0 0`,
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
}));

export const ChatWindow = ({
  anchorEl,
  isOpen,
  onClose,
  messages,
  sendMessage,
  isAnswering,
  clearChat,
}: IChatWindowProps) => {
  // TODO: Split Popover into ChatWindow and ChatWindowContent
  const { classes } = useStyles();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.chatWindowPaper, root: classes.chatWindowRoot }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <div className={classes.chatWrapper}>
        <div className={classes.chatMessages} ref={messagesEndRef}>
          <MessageList messages={messages} isAnswering={isAnswering} scrollToBottom={scrollToBottom} />
        </div>
        <div className={classes.chatInput}>
          <ChatForm sendMessage={sendMessage} isAnswering={isAnswering} />

          <div className={classes.footerWrapper}>
            <ChartWarning>
              {content.MISTAKE_WARNING}
              <br />
              {content.CHECK_IMPORTANT_INFO}
            </ChartWarning>
            <Button disabled={messages.length <= 1} onClick={clearChat}>
              {content.CLEAR_CHAT_BUTTON}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
