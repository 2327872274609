import { zodResolver } from '@hookform/resolvers/zod';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Input } from '@marlin/shared/ui-form-common';
import { Icon } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { TSendMessage } from '../types';
import { createChatGradient } from '../utils/colors';

interface IChatForm {
  message: string;
}

interface IChatFormProps {
  sendMessage: TSendMessage;
  isAnswering?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => {
  return {
    focusedIcon: {
      backgroundImage: createChatGradient(theme),
      backgroundSize: '100%',
      backgroundRepeat: 'repeat',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      marginRight: theme.typography.pxToRem(12),
    },
    icon: {
      color: theme.palette.action.disabled,
      marginRight: theme.typography.pxToRem(12),
    },
    chatInput: {
      '& input': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  };
});

const defaultFilters: IChatForm = {
  message: '',
};

const chatFormSchema = z.object({
  message: z.string().trim().min(1),
});

export const ChatForm = ({ sendMessage, isAnswering }: IChatFormProps) => {
  const { classes, cx } = useStyles();
  const [isFocused, setIsFocused] = useState(false);

  const form = useForm<IChatForm>({
    defaultValues: defaultFilters,
    resolver: zodResolver(chatFormSchema),
  });

  const onSubmit = (data: IChatForm) => {
    sendMessage({ message: data.message, from: 'me', timestamp: new Date().toISOString() });
    form.reset(defaultFilters);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField<IChatForm> fieldName="message">
          {({ error, ...props }) => {
            return (
              <Input
                {...props}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                  setIsFocused(false);
                  return props.onBlur?.();
                }}
                classes={{ root: classes.chatInput }}
                disabled={isAnswering}
                externalEndAdornment={{
                  endAdornment: (
                    <Icon
                      className={cx(classes.icon, { [classes.focusedIcon]: isFocused })}
                      baseClassName="material-symbols-outlined"
                    >
                      send
                    </Icon>
                  ),
                }}
              />
            );
          }}
        </FormField>
      </form>
    </FormProvider>
  );
};
