import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import { Chip } from '@marlin/shared/ui/chips';
import { CsmSearchWrapper } from '@marlin/support/data-access/settings';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { content } from '../content';
import { TAssignedCsm, TAssignedCsmForm } from './csm.schema';

export const CsmAutocomplete = () => {
  const { watch, setValue, trigger } = useFormContext();
  const assignedCsm = watch('assignedCsm');

  const handleDelete = (id: string) => {
    setValue(
      'assignedCsm',
      assignedCsm.filter((csm: TAssignedCsm) => csm.id !== id),
      { shouldDirty: true }
    );
    trigger('assignedCsm');
  };

  return (
    <CsmSearchWrapper>
      {(props) => {
        return (
          <InfiniteAutocompleteControl<TAssignedCsmForm>
            fieldName="assignedCsm"
            testId="assigned-csm-autocomplete"
            label={content.ASSIGNED_CSM}
            renderTags={(csms: TAssignedCsm[]) => {
              return csms.length === 0
                ? null
                : csms.map((csm: TAssignedCsm) => (
                    <Box sx={{ marginLeft: 1 }}>
                      <Chip
                        key={csm.id}
                        label={`${csm.firstName} ${csm.lastName}`}
                        size="small"
                        onDelete={() => handleDelete(csm.id)}
                      />
                    </Box>
                  ));
            }}
            {...props}
          />
        );
      }}
    </CsmSearchWrapper>
  );
};
