export class ThemeStorageService {
  private key = 'theme';

  public setTheme = (mode: 'light' | 'dark') => {
    localStorage.setItem(this.key, mode);
  };

  public getKey = () => this.key;

  public getTheme = () => {
    const value = localStorage.getItem(this.key);

    if (!value) {
      return null;
    }

    return value;
  };
}

export const themeStorageService = new ThemeStorageService();
