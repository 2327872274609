import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { regenerateVendorApiKey } from '../infrastructure/regenerate-vendor-api-key';
import { queryKey } from './query-key.enum';

export const useRegenerateVendorApiKey = () => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.VENDOR_API_KEY() });
    },
    mutationFn: ({ vendorSubscriptionId }: { vendorSubscriptionId: string }) =>
      regenerateVendorApiKey({
        vendorSubscriptionId,
      }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
  });
};
