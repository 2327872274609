import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(-20),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
  },
  button: {
    width: theme.typography.pxToRem(88),
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
}));
