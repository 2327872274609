import { TWebhookForm } from '@marlin/account-ui-webhook-upsert-form';
import { useFilteredEquipmentsWithPaging } from '@marlin/asset/data-access/equipment';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

export const useWebhookEquipmentPartition = () => {
  const equipmentQueryParams = useMemo(
    () => ({
      params: {
        pageSize: 100,
        page: 1,
        equipmentType: [EQUIPMENT_TYPE.PARTITION_CONTROLLER],
      },
    }),
    []
  );

  const {
    data: allEquipmentsPartition,
    isLoading,
    isInitialLoading,
    isError,
  } = useFilteredEquipmentsWithPaging(equipmentQueryParams);

  const isLoadingEquipments = useMemo(() => isLoading || isInitialLoading, [isInitialLoading, isLoading]);

  const isErrorEquipments = useMemo(() => isError, [isError]);

  const selectedEquipmentPartition: TWebhookForm['devices'] = useMemo(() => {
    return (allEquipmentsPartition?.data || []).map((device) => ({
      deviceId: device.id || '',
      name: device.name || '',
      datapointName: 'Sensor_{X}_Occupancy',
    }));
  }, [allEquipmentsPartition]);

  return {
    isError: isErrorEquipments,
    isLoading: isLoadingEquipments,
    data: selectedEquipmentPartition,
  };
};
