import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { useFilteredEquipmentsWithPaging } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { Button, Checkbox, Divider } from '@mui/material';
import { Handle, NodeProps, Position, useReactFlow } from '@xyflow/react';
import { useCallback, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { TRIGGER_VARIANT } from '../../types';
import { useCommonStyles } from '../common.styles';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
  equipmentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  handle: {
    width: 1,
    height: 1,
    opacity: 0,
  },
}));

export const EquipmentManufacturerIdNode = ({ id }: NodeProps) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes, cx } = useStyles();
  const { deleteElements } = useReactFlow();

  const [manufacturersId, setManufacturersId] = useState<{ id: string; name: string }[]>([]);

  const { currentState, removeManufacturerIds, addManufacturerIds } = useTriggersStore$();

  // TODO: remove this
  const {
    field: { onChange: onChangeManufacturerIdList },
  } = useController<TAutomationBuilder>({ name: 'manufacturerIdList' });

  const {
    field: { onChange: onChangeDevices },
  } = useController<TAutomationBuilder>({ name: 'trigger.devices' });

  const equipmentModel = useMemo(
    () => (currentState?.variant === TRIGGER_VARIANT.EQUIPMENT ? currentState.models[0] : ''),
    [currentState]
  );

  const equipmentQueryParams = useMemo<Parameters<typeof useFilteredEquipmentsWithPaging>[0]>(
    () => ({
      params: {
        pageSize: 100,
        page: 1,
        search: equipmentModel,
      },
      select: (data) => {
        return {
          ...data,
          data:
            data.data?.filter((equipment) => {
              return equipment.model === equipmentModel;
            }) ?? null,
        };
      },
    }),
    [equipmentModel]
  );

  const onCancelClick = useCallback(async () => {
    removeManufacturerIds();
    await deleteElements({
      nodes: [{ id }],
    });
  }, [deleteElements, id, removeManufacturerIds]);

  const onAddClick = useCallback(async () => {
    addManufacturerIds(Array.from(manufacturersId));
    onChangeManufacturerIdList(manufacturersId.map((item) => item.id));
    onChangeDevices(manufacturersId.map((item) => item.id));

    await deleteElements({
      nodes: [{ id }, { id: 'equipmentModel' }, { id: 'sensorNode' }],
    });
  }, [addManufacturerIds, deleteElements, id, manufacturersId, onChangeDevices, onChangeManufacturerIdList]);

  const equipmentsQuery = useFilteredEquipmentsWithPaging(equipmentQueryParams);

  const equipmentList = useMemo(() => equipmentsQuery.data?.data ?? [], [equipmentsQuery.data]);

  const toggleManufacturerId = useCallback(
    (id: string) => {
      setManufacturersId((prev) => {
        if (prev.some((item) => item.id === id)) {
          return prev.filter((item) => item.id !== id);
        }
        return [
          ...prev,
          {
            id,
            name: equipmentList.find((equipment) => equipment.id === id)?.name || '',
          },
        ];
      });
    },
    [equipmentList]
  );

  const toggleAllManufacturers = useCallback(() => {
    setManufacturersId((prev) => {
      if (prev.length === equipmentList.length) {
        return [];
      }
      return equipmentList.map((equipment) => ({
        id: equipment.id,
        name: equipment.name,
      }));
    });
  }, [equipmentList]);

  return (
    <div className={cx(commonClasses.node, classes.container)} data-testid={`equipment-node-${id}`}>
      <div className={cx(commonClasses.nodeContent, classes.content)}>
        {equipmentsQuery.isFetching ? (
          <LoadingSpinner />
        ) : (
          <>
            <span data-testid={`equipment-model-node-${id}-label`}>Choose equipment</span>
            <div className={classes.equipmentRow}>
              <div>Select all</div>
              <Checkbox
                checked={manufacturersId.length === equipmentList.length}
                indeterminate={manufacturersId.length > 0 && manufacturersId.length < equipmentList.length}
                onChange={toggleAllManufacturers}
              />
            </div>
            <Divider />
            {equipmentList.map((equipment) => {
              const manufacturerId = equipment.id;
              if (!manufacturerId) {
                return null;
              }
              return (
                <div key={manufacturerId} className={classes.equipmentRow}>
                  <div>{equipment.name}</div>
                  <Checkbox
                    checked={manufacturersId.some((item) => item.id === manufacturerId)}
                    onChange={() => toggleManufacturerId(manufacturerId)}
                  />
                </div>
              );
            })}
            <div className={commonClasses.actionButtons}>
              <Button onClick={onCancelClick}>Cancel</Button>
              <Button onClick={onAddClick} variant="contained" disabled={!manufacturersId.length}>
                Choose equipment
              </Button>
            </div>
          </>
        )}
      </div>

      <Handle className={classes.handle} type="target" id="top" position={Position.Top} />
      <Handle className={classes.handle} type="target" id="left" position={Position.Left} />
      <Handle className={classes.handle} type="source" id="right" position={Position.Right} />
      <Handle className={classes.handle} type="source" id="bottom" position={Position.Bottom} />
    </div>
  );
};
