import { MouseEvent, useCallback, useState } from 'react';

import { ChatButton } from './components/chat-button.component';
import { ChatWindow } from './components/chat-window.component';
import { IMessage, TSendMessage } from './types';

interface IChatProps {
  messages: IMessage[];
  sendMessage: TSendMessage;
  clearChat: () => void;
  isAnswering?: boolean;
}

export function Chat({ messages, sendMessage, isAnswering, clearChat }: IChatProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setIsOpen((prevState) => !prevState);
    setAnchorEl(event.currentTarget);
  }, []);

  // TODO: Add chat context
  return (
    <>
      <ChatButton onClick={onClick} isOpen={isOpen} />
      <ChatWindow
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        sendMessage={sendMessage}
        messages={messages}
        isAnswering={isAnswering}
        clearChat={clearChat}
      />
    </>
  );
}
