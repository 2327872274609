import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { NodeToolbar, Position } from '@xyflow/react';

export const AddNextStepButton = ({ onClick, zoom }: { onClick: () => void; zoom: number }) => {
  return (
    <NodeToolbar isVisible position={Position.Right} offset={0}>
      <IconButton
        color="secondary"
        style={{ transform: `scale(${zoom}) translate(-24px, 0) translate(${zoom * 18}px, 0)` }}
        onClick={onClick}
      >
        <AddIcon />
      </IconButton>
    </NodeToolbar>
  );
};
