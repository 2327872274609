import { TModel } from '@marlin/shared/utils/zod';

export enum TRIGGER_STEP {
  IDLE = 'IDLE',
  // equipment
  ADD_MODEL = 'ADD_MODEL',
  // sensor
  ADD_SENSOR_TYPE = 'ADD_SENSOR_TYPE',

  ADD_MANUFACTURER_IDS = 'ADD_MANUFACTURER_IDS',
  ADD_RULE = 'ADD_RULE',
  FINISHED = 'FINISHED',
}

export enum TRIGGER_VARIANT {
  NOT_SELECTED = 'NOT_SELECTED',
  SENSOR = 'SENSOR',
  EQUIPMENT = 'EQUIPMENT',
}

type TTriggerStoreBase = {
  currentStep: TRIGGER_STEP;
  variant: TRIGGER_VARIANT;
  manufacturerIdList: { id: string; name: string }[];
  ruleCounter: number;
};

export type TTriggerStoreNotSelected = {
  variant: TRIGGER_VARIANT.NOT_SELECTED;
  currentStep: TRIGGER_STEP;
};

export type TTriggerStoreSensor = TTriggerStoreBase & {
  variant: TRIGGER_VARIANT.SENSOR;
  models: TSensorModel[];
};

export type TTriggerStoreEquipment = TTriggerStoreBase & {
  variant: TRIGGER_VARIANT.EQUIPMENT;
  models: TModel[];
};

export type TTriggerStore = TTriggerStoreNotSelected | TTriggerStoreSensor | TTriggerStoreEquipment;
export type TModelStore = TTriggerStoreSensor | TTriggerStoreEquipment;

export type TSensorModel =
  | 'WNS2-9-W2-DC-CF-L01'
  | 'WNS2-9-W2-PC-01'
  | 'WNS2-9-W2-PC-02'
  | 'WNS2-9-W2-PS-300'
  | 'WNS2-9-W2-TS-ST'
  | 'WNS2-9-W2-WS-WP-L03'
  | 'WNS2-9-W2-WS-WR';
