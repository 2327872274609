import { MarlinTheme } from '@marlin/shared/theme';
import { IChartData, IChartSeries, findMinMax } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure, getFormattedValue, getUomSuffix } from '@marlin/shared/utils-format-reading';
import { useMediaQuery, useTheme } from '@mui/material';
import Plotly, { Config, Datum, Layout, PlotDatum } from 'plotly.js';
import { useCallback, useMemo } from 'react';

import { ITooltipData, TChartTooltip, useChartTooltip } from './use-chart-tooltip.hook';
import { getFormattedDate } from './utils/chart-options-utils';

interface IChartOptionsParams {
  chartData: Pick<IChartSeries, 'data' | 'uom' | 'name' | 'color'>[];
  from: number;
  to: number;
}

interface IPlotDatum extends PlotDatum {
  z: Datum;
}

const equipmentChartSize = {
  height: {
    mobile: 200,
    desktop: 300,
  },
};

export interface IDashboardChartOptions {
  config: Partial<Config>;
  layout: Partial<Layout>;
  handleHover: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover: () => void;
}

export const useDashboardChartOptions = ({
  chartData,
  from,
  to,
}: IChartOptionsParams): {
  chartOptions: IDashboardChartOptions;
  tooltip: TChartTooltip;
} => {
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mergedData = useMemo(
    () => chartData.reduce<IChartData[]>((acc, datapoint) => [...acc, ...datapoint.data], []),
    [chartData]
  );

  const { max, min } = useMemo(() => findMinMax({ points: mergedData }), [mergedData]);
  const { setShowTooltip, tooltip, setTooltip, showTooltip, tooltipDirection } = useChartTooltip();

  const config: Partial<Config> = useMemo(
    () => ({
      displaylogo: false,
      responsive: false,
      displayModeBar: false,
    }),
    []
  );

  const uoms = useMemo(() => {
    return Array.from(new Set(chartData.map((s) => s.uom)));
  }, [chartData]);

  const handleHover = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      const eventPoints = event.points as IPlotDatum[];

      const points: ITooltipData[] = eventPoints
        .filter((point: IPlotDatum) => point.data.mode === 'lines+markers' || point.data.type === 'bar')
        .map((point: IPlotDatum): ITooltipData => {
          const uom = point.data.customdata?.[0] as TUnitOfMeasure;

          return {
            color: point.data.marker.color?.toString() || '',
            displayedValue: getFormattedValue(point.y?.toString() || '', uom).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            name: point.data.name,
            date: typeof point.x === 'string' ? point.x.toString() : '',
            formattedDate: getFormattedDate(point),
          };
        });

      setTooltip({
        top: event.event.clientY,
        left: event.event.clientX,
        tooltipData: points,
      });
      setShowTooltip(true);
    },
    [setShowTooltip, setTooltip]
  );

  const handleUnhover = useCallback(() => {
    setShowTooltip(false);
    setTooltip(null);
  }, [setShowTooltip, setTooltip]);

  const layout: Partial<Layout> = useMemo(() => {
    const layoutOptions: Partial<Layout> = {
      paper_bgcolor: theme.palette.background.primary,
      plot_bgcolor: theme.palette.background.primary,
      barmode: 'stack',
      hovermode: 'x unified',
      xaxis: {
        type: 'date',
        fixedrange: true,
        autorange: false,
        range: from && to ? [from, to] : undefined,
        showgrid: false,
      },
      legend: { x: 0, y: isMobile ? 1.5 : 1.2, orientation: 'h' },
      yaxis: {
        fixedrange: true,
        side: 'right',
        range: [min, max],
        ticksuffix: uoms[0] ? getUomSuffix(uoms[0]) : '',
      },
      height: equipmentChartSize.height[isMobile ? 'mobile' : 'desktop'],
      margin: {
        t: isMobile ? 40 : 50,
        b: isMobile ? 40 : 50,
        l: isMobile ? 0 : undefined,
        r: isMobile ? 50 : undefined,
      },
    };

    if (uoms.length > 1 && uoms[1]) {
      const yaxis2: Layout['yaxis2'] = {
        side: 'left',
        range: [0, 100],
        ticksuffix: getUomSuffix(uoms[1]),
        overlaying: 'y',
        showgrid: false,
        fixedrange: true,
      };

      return {
        ...layoutOptions,
        yaxis2,
      };
    }

    return layoutOptions;
  }, [from, isMobile, max, min, to, uoms]);

  const chartOptions = useMemo(
    () => ({
      config,
      layout,
      handleHover,
      handleUnhover,
    }),
    [config, layout, handleHover, handleUnhover]
  );

  return {
    chartOptions,
    tooltip: { ...(tooltip ?? {}), direction: tooltipDirection, showTooltip },
  };
};
