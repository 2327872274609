import { TLogicalOperator } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Button, ButtonGroup } from '@mui/material';
import { Handle, Node, NodeProps, NodeToolbar, Position, getOutgoers, useReactFlow, useViewport } from '@xyflow/react';
import { makeStyles } from 'tss-react/mui';

import { useRulesStore$ } from '../../hooks/use-observable-rules-store';
import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { TModelStore } from '../../types';
import { useCommonStyles } from '../common.styles';
import { Rule } from './rule.component';

export type TLOperatorData = {
  logicalOperator: TLogicalOperator;
  parentId?: string;
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  device: {
    backgroundColor: theme.palette.systemMap.device,
    width: theme.typography.pxToRem(96),
    height: theme.typography.pxToRem(96),
    borderRadius: '50%',
    padding: 0,
  },
  deviceBackground: {
    backgroundColor: theme.palette.background.primary,
    width: theme.typography.pxToRem(96),
    height: theme.typography.pxToRem(96),
    borderRadius: '50%',
    padding: 0,
  },
  deviceNodeContent: {
    lineHeight: 1.6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  summary: {
    backgroundColor: theme.palette.systemMap.device,
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
  },
  handle: {
    width: 1,
    height: 1,
    opacity: 0,
  },
}));

export const LogicalOperatorNode = ({
  id: nodeId,
  data,
  positionAbsoluteX,
  positionAbsoluteY,
  selected,
}: NodeProps<Node<TLOperatorData>>) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { zoom } = useViewport();
  const { currentState } = useTriggersStore$();
  const { generateId, addOperator } = useRulesStore$();
  const { addNodes, addEdges, getNodes, getEdges } = useReactFlow();

  const isRuleDisabled = !(currentState as TModelStore)?.models?.length;

  const onAddRule = () => {
    const outgoers = getOutgoers({ id: nodeId }, getNodes(), getEdges());
    const id = generateId(nodeId);

    const newNode = {
      id,
      position: { x: positionAbsoluteX + 150, y: positionAbsoluteY + 100 * outgoers.length },
      type: 'componentNode',
      data: { children: <Rule id={id} parentId={nodeId} /> },
    };

    const newEdge = {
      id: `edge-34-${id}`,
      source: nodeId,
      target: id,
      sourceHandle: 'right',
      targetHandle: 'left',
    };

    addNodes([newNode]);
    addEdges([newEdge]);
  };

  const onAddOperator = (logicalOperator: TLogicalOperator) => {
    const outgoers = getOutgoers({ id: nodeId }, getNodes(), getEdges());
    const id = generateId(nodeId);
    addOperator(logicalOperator, id, nodeId);

    const newNode = {
      id,
      position: { x: positionAbsoluteX + 150, y: positionAbsoluteY + 100 * outgoers.length },
      type: 'operatorNode',
      data: { logicalOperator, parentId: nodeId },
    };

    const newEdge = {
      id: `edge-34-${id}`,
      source: nodeId,
      target: id,
      sourceHandle: 'right',
      targetHandle: 'left',
    };

    addNodes([newNode]);
    addEdges([newEdge]);
  };

  return (
    <div>
      <div className={classes.deviceBackground}>
        <div className={cx(commonClasses.node, classes.device)} data-testid={`device-node-${nodeId}`}>
          <div className={classes.deviceNodeContent}>{data.logicalOperator}</div>
          <Handle className={classes.handle} type="target" id="top" position={Position.Top} />
          <Handle className={classes.handle} type="target" id="left" position={Position.Left} />
          <Handle className={classes.handle} type="source" id="right" position={Position.Right} />
          <Handle className={classes.handle} type="source" id="bottom" position={Position.Bottom} />
        </div>
      </div>
      <NodeToolbar isVisible={selected} position={Position.Right} align={'center'}>
        <ButtonGroup orientation="vertical" style={{ transform: `scale(${zoom})` }}>
          <Button key="andoperator" onClick={() => onAddOperator('and')}>
            AND Operator
          </Button>
          <Button key="oroperator" onClick={() => onAddOperator('or')}>
            OR Operator
          </Button>
          <Button key="rule" onClick={onAddRule} disabled={isRuleDisabled}>
            Rule
          </Button>
        </ButtonGroup>
      </NodeToolbar>
    </div>
  );
};
