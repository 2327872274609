import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

interface IBodyProps {
  currentOrgName: string;
  newOrgName: string;
  currentTierName: string;
  newTierName: string;
  support?: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(400),
    wordWrap: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
}));

export const Body = ({ currentOrgName, newOrgName, currentTierName, newTierName, support }: IBodyProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {currentOrgName !== newOrgName && (
        <Typography>{content.CONFIRM_CHANGE_ORG_TEXT(currentOrgName, newOrgName)}</Typography>
      )}
      {support && currentTierName !== newTierName && (
        <Typography>{content.CONFIRM_CHANGE_TIER_TEXT(currentTierName, newTierName)}</Typography>
      )}
    </div>
  );
};
