import { TSourceSinkTag, TSystemTag } from '@marlin/system-map/shared/data-access-schemas';

import { content } from './content';

export interface ISidebarFormModel {
  systemTag?: TSystemTag;
  sourceSinkTag?: TSourceSinkTag;
}

export interface IOption {
  id: NonNullable<TSystemTag>;
  name: string;
}

export const options: {
  [key in NonNullable<
    Extract<TSystemTag, 'DOMESTIC_COLD_WATER' | 'DOMESTIC_HOT_WATER' | 'HEATING_HOT_WATER'>
  >]: IOption;
} = {
  DOMESTIC_COLD_WATER: {
    id: 'DOMESTIC_COLD_WATER',
    name: content.TAGS.DOMESTIC_COLD_WATER,
  },
  DOMESTIC_HOT_WATER: {
    id: 'DOMESTIC_HOT_WATER',
    name: content.TAGS.DOMESTIC_HOT_WATER,
  },
  HEATING_HOT_WATER: {
    id: 'HEATING_HOT_WATER',
    name: content.TAGS.HEATING_HOT_WATER,
  },
};

export const emptyOption: IOption = {
  id: '',
  name: '',
};
