import { z } from 'zod';

export const maxNotesLength = 256;

export const initialValues = {
  notes: '',
};

export const NotesSchema = z.object({
  notes: z.string(),
});

export type TNotesForm = z.infer<typeof NotesSchema>;
