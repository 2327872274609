import { useChat as useChatMutation, useChatMessages } from '@marlin/account-data-access-chat';
import { IMessage as IMessageUI } from '@marlin/shared/ui/chat';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';

import { content } from './content';
import { useChatId } from './use-chat-id.hook';

interface IUseChat {
  messages: IMessageUI[];
  sendMessage: (params: IMessageUI) => void;
  isAnswering?: boolean;
  clearConversationId: () => void;
}

export const useChat = (): IUseChat => {
  const { conversationId, setConversationId, clearConversationId } = useChatId();
  const { enqueueSnackbar } = useSnackbar();

  const { data: messages } = useChatMessages(conversationId);
  const { mutateAsync: chat, isLoading } = useChatMutation();

  const sendMessage = async (params: IMessageUI) => {
    try {
      const { conversationId: newConversationId } = await chat({ conversationId, userMessage: params.message });

      if (newConversationId !== conversationId) {
        setConversationId(newConversationId);
      }
    } catch (error) {
      enqueueSnackbar(content.CHAT_ERROR_MESSAGE, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return { messages: messages || [], sendMessage, isAnswering: isLoading, clearConversationId };
};
