import { MarlinTheme } from '@marlin/shared/theme';
import { Input, Select } from '@marlin/shared/ui-form-common';
import { getUomSymbol } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { SelectChangeEvent, TextField } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: '100%',
  },
  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
}));

const boolValues = [
  { id: 'true', name: 'True' },
  { id: 'false', name: 'False' },
];

export interface IValueProps {
  metadata?: TDeviceMetadataDatapointsResponse;
  value: string;
  onChange: (value: string) => void;
}

export const value =
  (variant: 'enum' | 'number' | 'bool') =>
  ({ onChange, value, metadata }: IValueProps) => {
    const { classes } = useStyles();

    if (!metadata) {
      return <TextField label="Value" className={classes.select} disabled />;
    }

    if (variant === 'number') {
      const endAdornment = useMemo(
        () => <span className={classes.endAdornment}>{getUomSymbol(metadata.unitOfMeasure)}</span>,
        [classes.endAdornment, metadata.unitOfMeasure]
      );

      return (
        <Input
          value={value}
          onChange={(event) => {
            onChange(event.target.value as string);
          }}
          label="Value"
          className={classes.select}
          externalEndAdornment={{
            endAdornment: endAdornment,
          }}
        />
      );
    }
    if (variant === 'bool') {
      return (
        <Select
          label="Value"
          value={value}
          onChange={(event: SelectChangeEvent) => {
            onChange(event.target.value as string);
          }}
          data={boolValues}
          prefix="value"
          className={classes.select}
        />
      );
    }
    if (variant === 'enum') {
      return (
        <Select
          label="Value"
          value={value}
          onChange={(event: SelectChangeEvent) => {
            onChange(event.target.value as string);
          }}
          data={metadata?.options.map((option) => ({ id: option.name, name: option.label ?? option.name }))}
          prefix="value"
          className={classes.select}
        />
      );
    }
    return null;
  };
