import { IAlertFilterParams, IFilterParams } from '../automation.model';
import { TAssetLocationParamsSchema } from '../schemas/assets/assets.schema';
import { TAutomationHistoryParams } from '../schemas/automation-history/automation-history.schema';

enum QUERY_KEY {
  AUTOMATIONS = 'automation-list',
  AUTOMATION = 'automation-details',
  AUTOMATION_HISTORY = 'automation-history',
  LOCATION_ASSETS = 'location-assets',
  ALERTS = 'alerts',
  AUTOMATION_COMPLEX = 'automation-complex',
  AUTOMATIONS_COMPLEX = 'automations-complex',
}

export const queryKey = {
  AUTOMATIONS: (filters?: IFilterParams) => (filters ? [QUERY_KEY.AUTOMATIONS, filters] : [QUERY_KEY.AUTOMATIONS]),
  AUTOMATION: (automationId: string) => [QUERY_KEY.AUTOMATION, automationId],
  ALERTS: (filters?: IAlertFilterParams) => (filters ? [QUERY_KEY.ALERTS, filters] : [QUERY_KEY.ALERTS]),
  LOCATION_ASSETS: (filters: Omit<TAssetLocationParamsSchema, 'page'>) =>
    filters ? [QUERY_KEY.LOCATION_ASSETS, filters] : [QUERY_KEY.LOCATION_ASSETS],
  AUTOMATION_HISTORY: (params: TAutomationHistoryParams) => [QUERY_KEY.AUTOMATION_HISTORY, params],
  AUTOMATION_COMPLEX: (automationId: string) => [QUERY_KEY.AUTOMATION_COMPLEX, automationId],
  AUTOMATIONS_COMPLEX: () => [QUERY_KEY.AUTOMATIONS_COMPLEX],
};
