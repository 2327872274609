import { IConfig, globalConfigTekmarSsc } from '@marlin/asset/shared/equipment-config';
import { tekmarLogo, tekmarSSCImage } from '@marlin/asset/shared/ui/assets';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';

import { HistoricalChart } from '../../../components/chart/historical-chart';
import { content } from '../../../content';
import { BoilersAndPumps } from '../../components/boilers-and-pumps/boilers-and-pumps.component';
import { EventLog } from '../../components/event-log/event-log.component';
import { OverrideModeBanner } from '../override-mode-banner/override-mode-banner.component';
import { boilersAndPumpsConfig } from './boilers-pumps-config';
import { detailsConfig } from './details-config';
import { sectionConfig } from './section-config';

const tabs = {
  [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
  [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
  [EQUIPMENT_TAB_VALUES.BOILERS]: <BoilersAndPumps />,
};

export const tekmarSmartSteamConfig: IConfig = {
  headerBanner: <OverrideModeBanner />,
  tabs,
  settings: {
    settingGroups: [
      {
        id: SETTINGS_GROUP.CENTRAL_HEATING,
        title: content.CENTRAL_HEATING,
      },
      {
        id: SETTINGS_GROUP.DOMESTIC_HOT_WATER,
        title: content.DOMESTIC_HOT_WATER,
      },
      {
        id: SETTINGS_GROUP.CONSTANT_SETPOINT,
        title: content.CONSTANT_SETPOINT,
      },
      {
        id: SETTINGS_GROUP.REMOTE_SETPOINT,
        title: content.REMOTE_SETPOINT,
      },
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
      {
        id: SETTINGS_GROUP.ALERT,
        title: content.ALERT_SETTINGS,
      },
    ],
    checkAvailableBoilers: true,
    schedule: {
      scheduleTempGroup: SETTINGS_GROUP.SCHEDULE_TEMPERATURE,
    },
  },
  details: detailsConfig,
  dashboard: {
    ...globalConfigTekmarSsc.dashboard,
    productDetails: {
      datapoints: [
        // wwsd
        {
          name: 'modeVal',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['wwsd', true]]),
            },
          },
        },
        // Override Max Heat || Max Heat || Override hand
        {
          name: 'condensateTarget',
          label: content.DATAPOINT_SSC_LABELS.CONDENSATE,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['overrideMaxHeat', true],
                ['heatOn', true],
                ['overrideOff', true],
                ['overrideHand', true],
                ['heatOff', true],
              ]),
            },
          },
        },
        {
          name: 'outdoorTemp',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['overrideMaxHeat', true],
                ['heatOn', true],
                ['overrideOff', true],
                ['overrideHand', true],
                ['heatOff', true],
              ]),
            },
          },
        },
        // establishing steam, cooldown
        {
          name: 'condensateTarget',
          label: content.DATAPOINT_SSC_LABELS.TARGET,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['establishingSteamTemp', true],
                ['coolDown', true],
              ]),
            },
          },
        },
        {
          name: 'modeVal',
          label: content.DATAPOINT_SSC_LABELS.CONDENSATE,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['establishingSteamTemp', true],
                ['coolDown', true],
              ]),
            },
          },
        },
        {
          name: 'outdoorTemp',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['establishingSteamTemp', true],
                ['coolDown', true],
              ]),
            },
          },
        },

        // No Heat Abort Cutoff
        {
          name: 'modeVal',
          label: content.DATAPOINT_SSC_LABELS.INDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['noHeatAbortCutoff', true]]),
            },
          },
        },
        {
          name: 'condensateTarget',
          label: content.DATAPOINT_SSC_LABELS.CONDENSATE,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['noHeatAbortCutoff', true]]),
            },
          },
        },
        {
          name: 'outdoorTemp',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['noHeatAbortCutoff', true]]),
            },
          },
        },
      ],
      imageSrc: tekmarSSCImage,
      logoSrc: tekmarLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
    },
    enableStartStopData: true,
    sections: sectionConfig,
    chart: {
      datapointNames: ['condensateTemp', 'condensateTarget', 'outdoorTemp'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
      isAdvancedButton: true,
    },
    isLastReadingTimeCounter: false,
  },
  boilersAndPumps: boilersAndPumpsConfig,
};
