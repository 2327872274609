import { z } from 'zod';

export interface IToggleVendorApiKey {
  vendorSubscriptionId: string;
  enable: boolean;
}

export interface IRegenerateVendorApiKey {
  vendorSubscriptionId: string;
}

export const vendorApiKeySchema = z.object({
  id: z.string(),
  name: z.string(),
  isEnabled: z.boolean(),
  apikey: z.string(),
  organizationIds: z.array(z.string()),
});

export type TVendorApiKey = z.infer<typeof vendorApiKeySchema>;
