export const content = {
  API_TITLE: 'API',
  GENERATE_NEW_KEY_BUTTON: 'Generate New Key',
  API_DEVELOPER_PORTAL: 'API Developer Portal',
  GENERATE_NEW_API_KEY_MODAL: {
    MODAL_TITLE: 'Generate New Key?',
  },
  GENERATE_SUCCESS: 'Your API key has been generated',
  GENERATE_ERROR: 'Something went wrong. Your API key has not been generated',
};
