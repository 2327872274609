import { z } from 'zod';

export const apiKeyResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  apikey: z.string().nullish(),
  isEnabled: z.boolean(),
  organizationIds: z.array(z.string()),
});

export const apiKeyRequestSchema = z.object({
  name: z.string(),
  organizationIds: z.array(z.string()),
});

export type TApiKeyRequest = z.infer<typeof apiKeyRequestSchema>;
export type TApiKeyResponse = z.infer<typeof apiKeyResponseSchema>;
