import { z } from 'zod';

import { content } from './content';

const orgNameMinCharacters = 3;
const orgNameMaxCharacters = 80;

const requiredOrganizationNameFieldSchema = z
  .string()
  .min(orgNameMinCharacters, content.MIN_3)
  .max(orgNameMaxCharacters, content.MAX_LENGTH(orgNameMaxCharacters));

export const ChangeOrganizationNameFormFieldsSchema = z.object({
  organizationName: requiredOrganizationNameFieldSchema,
  tierId: z.string().nullish(),
});

export type TChangeOrganizationNameForm = z.infer<typeof ChangeOrganizationNameFormFieldsSchema>;
