import { TCreateWebhookParams, TWebhookResponse } from '@marlin/account-data-access-webhook';
import { TWebhookForm } from '@marlin/account-ui-webhook-upsert-form';

const frequencyAdapter = (frequency: TWebhookForm['frequency']): TCreateWebhookParams['frequency'] => {
  switch (frequency) {
    case '1day':
      return 'Daily';
    case '1hr':
      return 'Hourly';
    case '1week':
      return 'Weekly';
    case '4hr':
      return 'FourHours';
    case '8hr':
      return 'EightHours';
    case 'Realtime':
      return 'Realtime';
    default:
      return 'Daily';
  }
};

const frequencyApiAdapter = (frequency: TWebhookResponse['frequency']): TWebhookForm['frequency'] => {
  switch (frequency) {
    case 'Daily':
      return '1day';
    case 'Hourly':
      return '1hr';
    case 'Weekly':
      return '1week';
    case 'FourHours':
      return '4hr';
    case 'EightHours':
      return '8hr';
    default:
      return '1day';
  }
};

const webhookTypeAdapter = (type: TWebhookForm['type']): TCreateWebhookParams['type'] => {
  switch (type) {
    case 'total-organization-volume':
      return 'AggregatedDeviceVolumeByOrgAndLocation';
    case 'all-individual-flow-meters':
      return 'AggregatedDeviceVolume';
    case 'all-individual-partitions':
      return 'RawTelemetry';
    default:
      return 'AggregatedDeviceVolumeByOrgAndLocation';
  }
};

const webhookTypeApiAdapter = (type: TWebhookResponse['type'], isPartition: boolean): TWebhookForm['type'] => {
  switch (type) {
    case 'AggregatedDeviceVolumeByOrgAndLocation':
      return 'total-organization-volume';
    case 'AggregatedDeviceVolume':
      return 'all-individual-flow-meters';
    case 'RawTelemetry':
      if (isPartition) {
        return 'all-individual-partitions';
      }
      return 'total-organization-volume';
    default:
      return 'total-organization-volume';
  }
};

const webhookDeviceAdapter = (devices: TWebhookForm['devices']): TCreateWebhookParams['devices'] => {
  return devices.map((device) => ({
    deviceId: device.deviceId,
    datapointName: device.datapointName,
  }));
};

const webhookDeviceApiAdapter = (devices: TWebhookResponse['devices']): TWebhookForm['devices'] => {
  return devices.map((device) => ({
    deviceId: device.deviceId,
    datapointName: device.datapointName,
    name: device.name || '',
  }));
};

export const createWebhookApiParamsAdapter = (values: TWebhookForm): TCreateWebhookParams => {
  return {
    name: values.name,
    managerEmail: values.managerEmail,
    url: values.url,
    frequency: frequencyAdapter(values.frequency),
    headers: values.apiKeys,
    type: webhookTypeAdapter(values.type),
    devices: webhookDeviceAdapter(values.devices),
  };
};

export const updateWebhookApiParamsAdapter = (values: TWebhookResponse, isPartition: boolean): TWebhookForm => {
  return {
    name: values.name,
    managerEmail: values.managerEmail,
    url: values.url,
    frequency: frequencyApiAdapter(values.frequency),
    apiKeys: values.headers,
    type: webhookTypeApiAdapter(values.type, isPartition),
    devices: webhookDeviceApiAdapter(values.devices),
  };
};
