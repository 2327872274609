import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TApiKeyResponse, apiKeyResponseSchema } from '../schemas/api-key.schema';
import { paths } from './api';

export const getApiKey = async (): Promise<TApiKeyResponse> => {
  const response = await getHttpClient().get(paths.VENDOR_API_KEY);
  return safeParseData(response, apiKeyResponseSchema);
};
