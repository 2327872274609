import { TModel } from '@marlin/shared/utils/zod';
import { useObservableState } from 'observable-hooks';
import { useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

import {
  TRIGGER_STEP,
  TRIGGER_VARIANT,
  TSensorModel,
  TTriggerStore,
  TTriggerStoreEquipment,
  TTriggerStoreSensor,
} from '../types';

const defaultState: TTriggerStore = {
  currentStep: TRIGGER_STEP.IDLE,
  variant: TRIGGER_VARIANT.NOT_SELECTED,
};

const triggerStore$ = new BehaviorSubject<TTriggerStore>(defaultState);

export const useTriggersStore$ = () => {
  const [currentState] = useObservableState<TTriggerStore>(() => triggerStore$);

  const openAddModel = useCallback(() => {
    const newState: TTriggerStoreEquipment = {
      currentStep: TRIGGER_STEP.ADD_MODEL,
      variant: TRIGGER_VARIANT.EQUIPMENT,
      models: [],
      manufacturerIdList: [],
      ruleCounter: 0,
    };
    triggerStore$.next(newState);
  }, []);

  const openAddSensorType = useCallback(() => {
    const newState: TTriggerStoreSensor = {
      currentStep: TRIGGER_STEP.ADD_SENSOR_TYPE,
      variant: TRIGGER_VARIANT.SENSOR,
      models: [],
      manufacturerIdList: [],
      ruleCounter: 0,
    };
    triggerStore$.next(newState);
  }, []);

  const addModels = useCallback(
    (models: TSensorModel[] | TModel[]) => {
      const newState = {
        ...currentState,
        models,
        currentStep: TRIGGER_STEP.ADD_MANUFACTURER_IDS,
      };
      triggerStore$.next(newState as TTriggerStore);
    },
    [currentState]
  );

  const removeModels = useCallback(() => {
    const newState = {
      ...currentState,
      models: [],
      currentStep: TRIGGER_STEP.IDLE,
    };
    triggerStore$.next(newState as TTriggerStore);
  }, [currentState]);

  const restoreDefaultState = useCallback(() => {
    triggerStore$.next(defaultState);
  }, []);

  const removeManufacturerIds = useCallback(() => {
    const previousStep = currentState?.variant === 'SENSOR' ? TRIGGER_STEP.ADD_SENSOR_TYPE : TRIGGER_STEP.ADD_MODEL;

    const newState = {
      ...currentState,
      manufacturerIdList: [],
      currentStep: previousStep,
    };
    triggerStore$.next(newState as TTriggerStore);
  }, [currentState]);

  const addManufacturerIds = useCallback(
    (ids: { id: string; name: string }[]) => {
      const newState = {
        ...currentState,
        manufacturerIdList: ids,
        currentStep: TRIGGER_STEP.FINISHED,
      };
      triggerStore$.next(newState as TTriggerStore);
    },
    [currentState]
  );

  return {
    currentState,
    openAddSensorType,
    openAddModel,
    restoreDefaultState,
    removeManufacturerIds,
    addManufacturerIds,
    addModels,
    removeModels,
  };
};
