import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'relative',
    padding: 0,
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.primary,
    height: '100%',
    width: 'auto',
  },

  equipmentDashboardContainer: {
    border: 'none',
    gap: theme.typography.pxToRem(10),
  },

  header: {
    width: '100%',
    height: theme.typography.pxToRem(112),
    padding: theme.typography.pxToRem(24),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.divider}`,
    borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)} 0px 0px`,

    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(16),
    isolation: 'isolate',

    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(16),
      justifyContent: 'space-between',
      // 94.02px is the height of the header in mobile view.
      // This is because we need to adjust top open alerts card height to match the sensor health and device health card.
      // Complex solution require header unification across Sensor Health, Device Health and Top Open Alerts.
      height: theme.typography.pxToRem(94.02),
    },
  },

  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.background.primary,
  },

  button: {
    height: theme.typography.pxToRem(30),
    width: theme.typography.pxToRem(111),
    fontSize: theme.typography.pxToRem(13),
    padding: theme.typography.pxToRem(5),
  },

  equipmentDashboardButton: {
    width: '100%',
    height: theme.typography.pxToRem(30),
    fontSize: theme.typography.pxToRem(13),
    padding: theme.typography.pxToRem(5),
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    width: '100%',
  },

  listEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '75%',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(16),
    },
  },

  listEmptyEquipmentDashboard: {
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(8),
    },
  },
}));
