import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { ILocation } from '@marlin/asset/data-access/location';
import { LOCATION_TYPE } from '@marlin/asset/data-access/location';
import {
  AttachmentUpdateValidation,
  AttachmentValidation,
  getAttachments,
  useHasAttachments,
} from '@marlin/asset/shared/ui/attachment-validation';
import { AssetFormSidebar } from '@marlin/asset/ui/asset-form-sidebar';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { LocationTypeControl, PostalCodeControl, StateControl } from '@marlin/shared/ui-form';
import { FormField, InfiniteAutocompleteControl, Input, Select } from '@marlin/shared/ui-form-common';
import { GridLayout, Paper } from '@marlin/shared/ui-page';
import { Button, Icon, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { countryList } from './constants';
import { content } from './content';
import { useStyles } from './upsert-location.component.styles';
import { useUpsertLocation } from './use-upsert-location.hook';

interface IUpsertLocationProps {
  defaultValues?: ILocation;
  isEdit?: boolean;
  disableSubmit: boolean;
  onSubmit: (value: ILocation) => void;
  onCancel: () => void;
}

const UpsertLocation = ({ defaultValues, isEdit, disableSubmit, onSubmit, onCancel }: IUpsertLocationProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { form } = useUpsertLocation(defaultValues);

  const assetId = form.watch('id');
  const selectedCountry = form.watch('country');
  const parentLocation = form.watch('parentLocation');

  const locationChanged = form.formState.dirtyFields.parentLocation;
  const { data } = useAssetLinks({ assetId: assetId ?? '', enabled: !!(locationChanged && isEdit) });

  const attachmentsFound = useHasAttachments(data);

  const isPlant = defaultValues?.locationType === LOCATION_TYPE.PLANT;

  useEffect(() => {
    if (parentLocation?.id) {
      form.trigger();
    }
  }, [form, parentLocation]);

  return (
    <FormProvider {...form}>
      <GridLayout
        form={
          <Paper className={classes.formContainer} data-testid="card">
            {isPlant && (
              <div className={classes.plantWarning}>
                <span>{content.PLANT_WARNING}</span>
                <span className={classes.iconWrapper}>
                  <Tooltip placement="top" text={content.PLANT_WARNING_TOOLTIP}>
                    <Icon baseClassName="material-symbols-outlined" className={classes.icon}>
                      Info
                    </Icon>
                  </Tooltip>
                </span>
              </div>
            )}
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className={classes.formSection}>{isPlant ? content.PLANT_DETAILS : content.LOCATION_DETAILS}</div>
              <FormField<ILocation> fieldName="name">
                {(props) => (
                  <Input
                    className={classes.input}
                    label={isPlant ? content.PLANT_NAME_LABEL : content.LOCATION_NAME_LABEL}
                    required
                    {...props}
                  />
                )}
              </FormField>
              <LocationSearchWrapper enableSearch={false} useEmptyOption>
                {(props) => (
                  <>
                    <InfiniteAutocompleteControl<ILocation>
                      {...props}
                      fieldName="parentLocation"
                      testId="locationId"
                      label={content.PARENT_LOCATION_LABEL}
                      className={assetId && isEdit ? classes.select : classes.selectMargins}
                      disabled={isPlant}
                      multiple={false}
                    />
                    {!!assetId && isEdit && locationChanged && data && (
                      <AttachmentValidation
                        assetId={assetId}
                        data={getAttachments(data)}
                        variant={attachmentsFound ? 'warning' : 'success'}
                      />
                    )}
                  </>
                )}
              </LocationSearchWrapper>
              {!isPlant && (
                <div className={classes.input}>
                  <LocationTypeControl<ILocation> fieldName="locationType"></LocationTypeControl>
                </div>
              )}
              <div className={classes.formSection}>{isPlant ? content.PLANT_ADDRESS : content.LOCATION_ADDRESS}</div>
              <FormField<ILocation> fieldName="country">
                {(props) => (
                  <Select
                    {...props}
                    prefix="country"
                    label={content.COUNTRY_LABEL}
                    data={countryList}
                    className={classes.select}
                    onChange={(e) => {
                      if ((e?.target?.value as string) !== selectedCountry) {
                        form.setValue('state', '');
                        form.setValue('postalCode', '');
                        setTimeout(() => {
                          form.clearErrors('state');
                          form.clearErrors('postalCode');
                        }, 0);
                      }

                      return props.onChange && props.onChange(e);
                    }}
                  />
                )}
              </FormField>
              <div className={classes.inputRow}>
                <FormField<ILocation> fieldName="address1">
                  {(props) => (
                    <Input
                      className={classes.input}
                      testId="address-1"
                      label={content.ADDRESS_1_LABEL.get(selectedCountry ?? '')}
                      {...props}
                    />
                  )}
                </FormField>
                <FormField<ILocation> fieldName="address2">
                  {(props) => (
                    <Input
                      className={classes.input}
                      testId="address-2"
                      label={content.ADDRESS_2_LABEL.get(selectedCountry ?? '')}
                      {...props}
                    />
                  )}
                </FormField>
              </div>
              <div className={classes.inputRow}>
                <FormField<ILocation> fieldName="city">
                  {(props) => <Input className={classes.input} label={content.CITY_LABEL} {...props} />}
                </FormField>
                <StateControl<ILocation> fieldName="state" country={selectedCountry} />
              </div>
              <div className={classes.zipcode}>
                <PostalCodeControl<ILocation>
                  fieldName="postalCode"
                  country={selectedCountry}
                  className={classes.input}
                />
              </div>
              <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>

              <div className={classes.description}>
                <FormField<ILocation> fieldName="description">
                  {(props) => (
                    <Input
                      className={classes.input}
                      label={content.DESCRIPTION_LABEL}
                      placeholder={content.DESCRIPTION_PLACEHOLDER}
                      {...props}
                    />
                  )}
                </FormField>
              </div>
            </form>
          </Paper>
        }
        tags={!isPlant && isEdit ? <AssetFormSidebar<ILocation> showSourceSinkTag /> : null}
        buttons={
          <div className={classes.buttonsWrapper}>
            {!isMobile && (
              <div className={classes.button}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={onCancel}
                  data-testid="new-location-cancel"
                >
                  {content.BUTTON_CANCEL}
                </Button>
              </div>
            )}
            <div className={classes.button}>
              <Button
                className={classes.button}
                variant="contained"
                data-testid={isEdit ? 'new-location-update' : 'new-location-create'}
                onClick={form.handleSubmit(onSubmit)}
                disabled={disableSubmit || !form.formState.isDirty || !form.formState.isValid}
              >
                {isEdit ? content.BUTTON_UPDATE : content.BUTTON_CREATE}
              </Button>
              {!!assetId && isEdit && locationChanged && attachmentsFound && (
                <div className={classes.attachmentUpdateValidation}>
                  <AttachmentUpdateValidation />
                </div>
              )}
            </div>
          </div>
        }
      />
    </FormProvider>
  );
};

export { UpsertLocation };
