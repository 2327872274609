import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getApiKey } from '../infrastructure/get-api-key';
import { TApiKeyResponse } from '../schemas/api-key.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getApiKey;

export const useGetApiKey = () => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.VENDOR_API_KEY(),
    queryFn: getApiKey,
    keepPreviousData: false,
    refetchInterval: (data?: TApiKeyResponse) => {
      return data?.apikey === null ? 500 : false;
    },
  });
};
