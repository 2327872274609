import { base64DecodeSchema } from '@marlin/shared/utils/zod';
import { z } from 'zod';

export const webhookSyncFrequencySchema = z.enum(['Realtime', 'Hourly', 'Daily', 'Weekly', 'FourHours', 'EightHours']);

export const webhookTypeSchema = z.enum([
  'RawTelemetry',
  'AggregatedOrganizationVolume',
  'AggregatedDeviceVolume',
  'AggregatedDeviceVolumeByOrgAndLocation',
]);

export const webhookResponseDeviceSchema = z.object({
  deviceId: z.string(),
  datapointName: z.string(),
  allDatapoints: z.boolean(),
  name: z.string().nullish(),
  model: z.string().nullish(),
  brand: z.string().nullish(),
});

export const webhookApiHeaderSchema = z.object({
  name: z.string(),
  value: z.string(),
});

export const webhookSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: base64DecodeSchema,
  organizationId: z.string(),
  managerEmail: z.string(),
  frequency: webhookSyncFrequencySchema,
  type: webhookTypeSchema,
  devices: z.array(webhookResponseDeviceSchema),
  suspended: z.boolean(),
  allDevices: z.boolean(),
  failureCount: z.number(),
  isEnabled: z.boolean(),
  headers: z.array(webhookApiHeaderSchema),
});

export type TWebhook = z.infer<typeof webhookSchema>;
export type TWebhookSyncFrequency = z.infer<typeof webhookSyncFrequencySchema>;
export type TWebhookType = z.infer<typeof webhookTypeSchema>;
export type TWebhookApiHeader = z.infer<typeof webhookApiHeaderSchema>;
export type TWebhookResponseDevice = z.infer<typeof webhookResponseDeviceSchema>;
