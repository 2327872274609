import { TExtractFnReturnType, TQueryConfig, getHttpClient } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { EquipmentFlow } from '../schema/equpment-flow.schema';
import { QUERY_KEY } from './query-key.enum';

export const getEquipmentFlow = async ({ equipmentId }: { equipmentId: string }) => {
  const response = await getHttpClient().get(paths.EQUIPMENT_FLOW, { params: { equipmentId } });

  return safeParseData(response, EquipmentFlow);
};

type TQueryFnType = typeof getEquipmentFlow;

type TUseEquipmentOptions = {
  equipmentId: string;
  config?: TQueryConfig<TQueryFnType>;
};

export const useEquipmentFlow = ({ equipmentId, config }: TUseEquipmentOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY.EQUIPMENT_FLOW, equipmentId],
    queryFn: () => getEquipmentFlow({ equipmentId }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
  });
};
