import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { createChatGradient } from '../utils/colors';

export const animationDuration = 1;
export const dotCount = 3;

export const calculateAnimationDelay = (index: number) => {
  return `-${(dotCount - 1 - index) * (animationDuration / dotCount)}s`;
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(5),
  },

  chatDot: {
    borderRadius: '50%',
    animation: `bounce ${animationDuration}s ease-in-out infinite, pulse ${animationDuration}s ease-in-out infinite`,
    height: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(10),

    '@keyframes bounce': {
      '0%, 25%': {
        transform: 'translateY(0)',
        background: theme.palette.action.disabled,
        opacity: 0.5,
      },
      '33%, 58%': {
        transform: 'translateY(-10px)',
        background: createChatGradient(theme),
        opacity: 1,
      },
      '66%, 100%': {
        transform: 'translateY(0)',
        background: theme.palette.action.disabled,
        opacity: 0.5,
      },
    },
    '@keyframes pulse': {
      '0%, 25%': {
        opacity: 0.5,
      },
      '33%, 58%': {
        opacity: 1,
      },
      '66%, 100%': {
        opacity: 0.5,
      },
    },
  },
}));
