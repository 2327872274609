import { TOrgAddress } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper, ReadOnlySection } from '@marlin/shared/ui-page';
import { formatTimezone } from '@marlin/shared/utils-common-date';
import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { Coordinates } from './coordinates.component';
import { TAddressForm } from './form/address-form-data-schema';

interface IReadOnlyAddressProps {
  defaultValues?: TOrgAddress & TAddressForm;
  toggleEditMode: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(16),
  },
  mobileDivider: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(16),
  },
  coordinates: {
    marginTop: theme.typography.pxToRem(24),
  },
}));

export const ReadOnlyAddress = ({ defaultValues, toggleEditMode }: IReadOnlyAddressProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getTimezone = () => {
    if (!defaultValues?.timeZone) {
      return content.DEFAULT_TIME_ZONE;
    }
    const formattedTimezone = formatTimezone(defaultValues.timeZone?.id ?? '');
    if (!formattedTimezone) {
      return content.DEFAULT_TIME_ZONE;
    }

    const [name, abbr, offset] = formattedTimezone.nameComponents;

    return (
      <div>
        {name} <br />({abbr}, {offset})
      </div>
    );
  };

  return (
    <Paper data-testid="card">
      <ReadOnlySection testid="organization-address" onEditClick={toggleEditMode}>
        <Grid container columnSpacing={2} direction={isMobile ? 'column' : 'row'}>
          <Grid item md={6} xs={12}>
            <div className={classes.sectionTitle} data-testid="card-title-address">
              {content.ORGANIZATION_ADDRESS}
            </div>
            <div data-testid="organization-settings-readonly-address1">{defaultValues?.address1}</div>
            {defaultValues?.address2 && (
              <div data-testid="organization-settings-readonly-address2">{defaultValues?.address2}</div>
            )}
            <div data-testid="organization-settings-readonly-city-state-zipcode">
              {defaultValues?.city} {defaultValues?.state}, {defaultValues?.postalCode}
            </div>
            <div data-testid="organization-settings-readonly-country">{defaultValues?.country}</div>

            <div className={classes.coordinates} data-testid="organization-settings-readonly-coordinates">
              <Coordinates latitude={defaultValues?.latitude} longitude={defaultValues?.longitude} />
            </div>
          </Grid>
          <Divider
            className={isMobile ? classes.mobileDivider : undefined}
            orientation={isMobile ? 'horizontal' : 'vertical'}
            flexItem
          />
          <Grid item md={5} xs={12}>
            <div className={classes.sectionTitle} data-testid="card-title-timezone">
              {content.ORGANIZATION_TIME_ZONE}
            </div>
            <div data-testid="organization-settings-readonly-timezone">{getTimezone()}</div>
          </Grid>
        </Grid>
      </ReadOnlySection>
    </Paper>
  );
};
