import { UseQueryResult, useQuery } from '@marlin/shared/utils/react-query';

import { firstMessage } from '../constants';
import { getChatMessages } from '../infrastracture/chat';
import { TChatMessage } from '../schemas/chat.schema';
import { queryKey } from './query-key.enum';

export const useChatMessages = (conversationId: string | null): UseQueryResult<TChatMessage[]> => {
  return useQuery<TChatMessage[]>({
    queryKey: queryKey.CHAT(conversationId),
    queryFn: async () => {
      if (!conversationId) {
        return [firstMessage];
      }

      const data = await getChatMessages(conversationId);

      return [firstMessage, ...data.messages];
    },
    staleTime: Infinity,
  });
};
