import { Chat as ChatUI } from '@marlin/shared/ui/chat';

import { useChat } from './use-chat.hook';

export const Chat = () => {
  const { messages, sendMessage, isAnswering, clearConversationId } = useChat();

  return (
    <ChatUI messages={messages} sendMessage={sendMessage} isAnswering={isAnswering} clearChat={clearConversationId} />
  );
};
