import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { ReadOnlySection } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { EditCardLayout } from '../components/edit-card-layout.component';
import { useEditMode } from '../hooks/use-edit-mode.hook';
import { useUpsertOrg } from '../hooks/use-upsert-org.hook';
import { content } from './content';
import { CsmForm } from './form/csm-form.component';
import { TAssignedCsmForm } from './form/csm.schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: theme.typography.pxToRem(0.15),
    lineHeight: '160%',
    marginBottom: theme.typography.pxToRem(16),
  },
}));

interface ICsmProps {
  organizationId?: string;
}

export const Csm = ({ organizationId }: ICsmProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const { editMode, toggleEditMode } = useEditMode();
  const { updateOrganizationDetails, data, isFetched } = useUpsertOrg({
    organizationId,
    support: true,
  });

  const submit = useCallback(
    async (csm: TAssignedCsmForm) => {
      return updateOrganizationDetails({
        ...data,
        assignedCsm: csm.assignedCsm,
        organizationId,
      })
        .then(() => {
          enqueueSnackbar(content.UPDATE_SUCCESS, { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar(content.UPDATE_ERROR, { variant: 'error' });
        });
    },
    [data, enqueueSnackbar, organizationId, updateOrganizationDetails]
  );

  const children: ReactNode = useMemo(() => {
    if (!isFetched) {
      return <DataLoader />;
    }

    if (editMode && data?.assignedCsm) {
      return (
        <CsmForm cancelEditMode={toggleEditMode} onSubmit={submit} defaultValues={{ assignedCsm: data?.assignedCsm }} />
      );
    }

    return (
      <ReadOnlySection onEditClick={toggleEditMode} testid="customer-billing-contact">
        {data?.assignedCsm?.length ? (
          <Typography className={classes.text} variant="body1">
            {data.assignedCsm.map(({ firstName, lastName }, elemIndex) => {
              return `${firstName} ${lastName}${
                data.assignedCsm && elemIndex < data.assignedCsm.length - 1 ? ', ' : ''
              }`;
            })}
          </Typography>
        ) : (
          <Typography className={classes.text} variant="body1">
            -
          </Typography>
        )}
      </ReadOnlySection>
    );
  }, [classes.text, data?.assignedCsm, editMode, isFetched, submit, toggleEditMode]);

  return <EditCardLayout title={content.CSM}>{children}</EditCardLayout>;
};
