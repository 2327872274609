import { MarlinTheme } from '@marlin/shared/theme';
import { isEmptyChartData } from '@marlin/shared/ui/chart';
import {
  IChartSeries,
  IChartThresholdSeries,
  RANGE_FILTER,
  TBucketOption,
  TChartDisplayType,
  getChartHeight,
  getDateFromWithAdjustByBucket,
  getDateToWithAdjustByBucket,
} from '@marlin/shared/utils-chart';
import { createDateString, dateAdapter, formatDate, millisecondsInMinute } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getFormattedValue, getUomSuffix } from '@marlin/shared/utils-format-reading';
import { useMediaQuery, useTheme } from '@mui/material';
import get from 'lodash/get';
import Plotly, { Config, Datum, Layout, PlotDatum, PlotMouseEvent } from 'plotly.js';
import { useCallback, useMemo, useState } from 'react';
import * as z from 'zod';

import { TOOLBAR_ICON_PATH } from './const/toolbar-icon-path';
import { content } from './content';
import { ITooltipData, TChartTooltip, useChartTooltip } from './use-chart-tooltip.hook';
import { dateStringToTimestamp, getFormattedDate, getXaxisOffset, numberTypeGuard } from './utils/chart-options-utils';
import { getNewSelection, isSelectionGreaterThanTwoHours } from './utils/chart-selection';

const chartTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak', 'custom'] as const;
export const ChartType = z.enum(chartTypeValues);
export type TChartType = z.infer<typeof ChartType>;

interface IPlotDatum extends PlotDatum {
  z: Datum;
  base?: string;
}

interface IChartOptionsParams {
  chartData: IChartSeries[];
  chartDataWithoutMargins?: IChartSeries[];
  thresholdsData?: IChartThresholdSeries[];
  rangeFilter: RANGE_FILTER;
  from?: number;
  to?: number;
  max: number;
  min: number;
  handleZoomChange: (zoom?: { min: number; max: number }, bucketOption?: TBucketOption) => void;
  isZoomed: boolean;
  chartDisplayType: TChartDisplayType;
  chartId: string;
  isFullScreen?: boolean;
  onFullScreenClick?: () => void;
  customIconClassName?: string;
  hideUomOnAxis?: boolean;
  additionalAxisUom?: TUnitOfMeasure;
  mainDatapoint?: string;
  bucketOption?: TBucketOption;
  chartType?: TChartType;
  rangeBarDatapoints?: string[] | undefined;
  disableFullScreen?: boolean;
}

export interface IChartOptions {
  layout: Partial<Layout>;
  config: Partial<Config>;
  handleRelayout: (e: Plotly.PlotRelayoutEvent) => void;
  handleHover: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover: (event: Readonly<PlotMouseEvent>) => void;
  isEmpty: boolean;
  tooltip: TChartTooltip;
}

export const useChartOptionsV2 = ({
  max,
  min,
  chartData,
  chartDataWithoutMargins,
  rangeFilter,
  from,
  to,
  isZoomed,
  handleZoomChange,
  chartDisplayType,
  chartId,
  isFullScreen,
  customIconClassName,
  onFullScreenClick,
  hideUomOnAxis,
  additionalAxisUom,
  mainDatapoint,
  bucketOption,
  chartType,
  thresholdsData,
  rangeBarDatapoints,
  disableFullScreen,
}: IChartOptionsParams): IChartOptions => {
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isEmpty = isEmptyChartData({ chartData, from, to, chartDisplayType });

  const [dragMode, setDragMode] = useState<
    'zoom' | 'pan' | 'select' | 'lasso' | 'orbit' | 'turntable' | false | undefined
  >(undefined);

  const uom = thresholdsData?.[0]?.uom ?? chartData[0]?.uom;

  const chartHeight = useMemo(() => {
    if (isMobile) {
      return 280;
    }

    if (isFullScreen) {
      return 540;
    }

    return 360;
  }, [isFullScreen, isMobile]);

  const layout: Partial<Layout> = useMemo(() => {
    if (chartDisplayType === 'rangeBar') {
      const rangeBarChartHeight = getChartHeight(0, rangeBarDatapoints?.length, !!chartData.length);

      const layout: Partial<Layout> = {
        paper_bgcolor: theme.palette.background.primary,
        plot_bgcolor: theme.palette.background.primary,
        autosize: true,
        barmode: 'stack',
        hovermode: 'closest',
        showlegend: false,
        xaxis: {
          type: 'date',
          anchor: 'y',
          fixedrange: false,
          autorange: false,
          showgrid: true,
          range:
            from && to
              ? [
                  getFormattedDateWithBucketOption(from, 'from', isZoomed, bucketOption),
                  getFormattedDateWithBucketOption(to, 'to', isZoomed, bucketOption),
                ]
              : undefined,
        },
        margin: {
          t: isMobile ? 40 : 50,
          b: isMobile ? 40 : 50,
          l: isMobile ? 0 : 16,
          r: isMobile ? 50 : undefined,
        },
        dragmode: dragMode,
        legend: { orientation: 'h' },
        height: rangeBarChartHeight,
        yaxis: {
          anchor: 'x',
          type: 'category',
          showgrid: true,
          visible: false,
          fixedrange: true,
        },
      };

      return layout;
    }

    const layoutOptions: Partial<Layout> = {
      autosize: true,
      barmode: 'stack',
      hovermode: 'x unified',
      showlegend: false,
      paper_bgcolor: theme.palette.background.primary,
      plot_bgcolor: theme.palette.background.primary,
      xaxis: {
        type: 'date',
        fixedrange: false,
        autorange: false,
        range:
          from && to
            ? [
                getFormattedDateWithBucketOption(from, 'from', isZoomed, bucketOption),
                getFormattedDateWithBucketOption(to, 'to', isZoomed, bucketOption),
              ]
            : undefined,
      },
      yaxis: {
        fixedrange: true,
        side: 'right',
        range: [min, max],
        ticksuffix: !hideUomOnAxis && chartType !== 'custom' && chartId !== 'other' ? getUomSuffix(uom) : '',
      },
      height: chartHeight,
      margin: {
        t: isMobile ? 40 : 50,
        b: isMobile ? 40 : 50,
        l: isMobile ? 0 : additionalAxisUom ? 50 : 16,
        r: isMobile ? 50 : undefined,
      },
      dragmode: dragMode,
    };

    if (additionalAxisUom && !hideUomOnAxis) {
      const yaxis2: Layout['yaxis2'] = {
        side: 'left',
        range: [0, 100],
        ticksuffix: getUomSuffix(additionalAxisUom),
        overlaying: 'y',
        showgrid: false,
        fixedrange: true,
      };

      return {
        ...layoutOptions,
        yaxis2,
      };
    }

    return layoutOptions;
  }, [
    additionalAxisUom,
    bucketOption,
    chartData.length,
    chartDisplayType,
    chartHeight,
    chartId,
    chartType,
    dragMode,
    from,
    hideUomOnAxis,
    isMobile,
    isZoomed,
    max,
    min,
    rangeBarDatapoints?.length,
    theme.palette.background.primary,
    to,
    uom,
  ]);

  const isFullScreenButton = useMemo(
    () => !isMobile && !isFullScreen && !disableFullScreen,
    [disableFullScreen, isFullScreen, isMobile]
  );
  const isZoomInButtonEnabled = useMemo(
    () => (from && to ? isSelectionGreaterThanTwoHours(dateStringToTimestamp(from), dateStringToTimestamp(to)) : true),
    [from, to]
  );

  const { setShowTooltip, tooltip, setTooltip, showTooltip, tooltipDirection } = useChartTooltip();

  const config: Partial<Config> = useMemo(
    () => ({
      displaylogo: false,
      responsive: chartDisplayType !== 'rangeBar',
      displayModeBar: true,
      modeBarButtons: [
        [
          ...(isZoomInButtonEnabled
            ? ['zoomIn2d' as const]
            : [
                {
                  name: 'disabledZoomIn2d',
                  title: content.DISABLED_MODE_BUTTON,
                  icon: {
                    width: 875,
                    height: 1000,
                    path: TOOLBAR_ICON_PATH.ZOOM_IN,
                    ascent: 850,
                  },
                  click: () => {},
                  attr: 'disabled',
                },
              ]),
          'zoomOut2d',
          ...(isZoomInButtonEnabled
            ? ['zoom2d' as const]
            : [
                {
                  name: 'disabledZoom2d',
                  title: content.DISABLED_MODE_BUTTON,
                  icon: {
                    width: 1000,
                    height: 1000,
                    path: TOOLBAR_ICON_PATH.ZOOM,
                    ascent: 850,
                  },
                  click: () => {},
                  attr: 'disabled',
                },
              ]),
          'pan2d',
          ...(isFullScreenButton
            ? [
                {
                  title: 'Full screen',
                  name: 'autoscale',
                  icon: {
                    width: 1792,
                    path: TOOLBAR_ICON_PATH.FULL_SCREEN,
                    ascent: 1792,
                    descent: 0,
                  },
                  click: () => {
                    onFullScreenClick?.();
                  },
                },
              ]
            : []),
        ],
      ],
    }),
    [chartDisplayType, isFullScreenButton, isZoomInButtonEnabled, onFullScreenClick]
  );

  const handleRelayout = useCallback(
    (e: Plotly.PlotRelayoutEvent) => {
      const x1 = get(e, 'xaxis.range[0]', null) || get(e, 'xaxis.range', [])[0];
      const x2 = get(e, 'xaxis.range[1]', null) || get(e, 'xaxis.range', [])[1];

      const selectionX1 = dateStringToTimestamp(x1);
      const selectionX2 = dateStringToTimestamp(x2);

      if (selectionX1 && numberTypeGuard(selectionX1) && selectionX2 && numberTypeGuard(selectionX2)) {
        const isFromSelectionChanged = Math.abs(from || 0 - (selectionX1 || 0)) > millisecondsInMinute;
        const isToSelectionChanged = Math.abs((selectionX2 || 0) - (to || 0)) > millisecondsInMinute;

        if (isFromSelectionChanged || isToSelectionChanged) {
          const zoom = getNewSelection(
            { min: selectionX1, max: selectionX2 },
            {
              min: from || 0,
              max: to || 0,
            },
            chartDisplayType === 'bar',
            bucketOption
          );

          handleZoomChange(zoom, bucketOption);
        }
      }
      const newDragMode = get(e, 'dragmode', null);
      if (newDragMode && newDragMode !== dragMode) {
        setDragMode(newDragMode);
      }
    },
    [dragMode, from, to, chartDisplayType, handleZoomChange, bucketOption]
  );

  const handleHover = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      let eventPoints = event.points as IPlotDatum[];
      if (chartType === 'leak') {
        const mappedData = chartData.map((series) => ({
          ...series,
          data: series.data.map((point) => ({
            ...point,
            x: formatDate(createDateString(new Date(point.x))),
          })),
        }));
        const xPoint = formatDate(createDateString(new Date(eventPoints[0].x ?? '')));
        eventPoints = mappedData.map((series) => ({
          ...eventPoints[0],
          y: series.name,
          x: series.data.find((point) => point.x === xPoint)?.x ?? eventPoints[0].x ?? '',
          z: series.data.find((point) => point.x === xPoint)?.y ?? '',
          data: {
            ...eventPoints[0].data,
            marker: {
              ...eventPoints[0].data.marker,
              color: series.color,
            },
          },
        }));
      }

      const points: ITooltipData[] = eventPoints
        .filter(
          (point: IPlotDatum) =>
            point.data.mode === 'lines+markers' || point.data.type === 'heatmap' || point.data.type === 'bar'
        )
        .map((point: IPlotDatum): ITooltipData => {
          if (point.data.type === 'bar' && point.base && point.x) {
            const timeFormat = 'DD MMM HH:mm';
            return {
              color: point.data.marker.color?.toString() || '',
              name: point.y?.toString() || '',
              date: typeof point.x === 'string' ? point.x.toString() : '',

              formattedDate: `${formatDate(createDateString(new Date(point.base)), timeFormat)} - ${formatDate(
                createDateString(new Date(point.x)),
                timeFormat
              )}`,
              displayedValue: '',
            };
          }

          const uom = point.data.customdata?.[0] as TUnitOfMeasure;

          if (point.data.type === 'heatmap') {
            return {
              color: point.data.marker.color?.toString() || '',
              displayedValue: getFormattedValue(
                point.z?.toString() || '',
                chartType === 'leak' ? 'WaterDetect' : uom
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              name: point.y?.toString() || '',
              date: typeof point.x === 'string' ? point.x.toString() : '',
              formattedDate: getFormattedDate(point, bucketOption),
            };
          }

          return {
            color: point.data.marker.color?.toString() || '',
            displayedValue: getFormattedValue(point.y?.toString() || '', uom).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            name: point.data.name,
            date: typeof point.x === 'string' ? point.x.toString() : '',
            formattedDate: getFormattedDate(point, bucketOption),
          };
        });

      setTooltip({
        top: event.event.clientY,
        left: event.event.clientX,
        tooltipData: points,
      });
      setShowTooltip(true);
    },
    [bucketOption, chartData, chartType, setShowTooltip, setTooltip]
  );

  const handleUnhover = useCallback(() => {
    setShowTooltip(false);
    setTooltip(null);
  }, [setShowTooltip, setTooltip]);

  return {
    layout,
    config,
    handleRelayout,
    isEmpty,
    handleHover,
    handleUnhover,
    tooltip: { ...(tooltip ?? {}), direction: tooltipDirection, showTooltip },
  };
};

const getFormattedDateWithBucketOption = (
  timestamp: number,
  variant: 'from' | 'to',
  isZoomed: boolean,
  bucketOption?: TBucketOption
) => {
  // TODO: adjusting here may replace adjusting also for zooming in chart-selection, needs to be refactored and tested
  if (!bucketOption || isZoomed) {
    return formatDate(createDateString(new Date(timestamp - getXaxisOffset(bucketOption, isZoomed))));
  }

  const dateAdjustingFunction = variant === 'from' ? getDateFromWithAdjustByBucket : getDateToWithAdjustByBucket;

  const adjustedDate = dateAdjustingFunction(dateAdapter.date?.(timestamp), bucketOption)?.toDate() ?? new Date();

  return formatDate(createDateString(new Date(adjustedDate?.getTime() - getXaxisOffset(bucketOption, true))));
};
