import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { content } from './content';
import { useStyles } from './modal.styles';

export const ModalBody = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.body}>
      <WarningRoundedIcon color="warning" />
      <p className={classes.bodyText}>{content.GENERATING_KEY_MESSAGE}</p>
    </div>
  );
};
