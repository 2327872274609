import {
  UpdateAssetTypeSchema,
  sourceSinkTagValuesSchema,
  systemTagValuesSchema,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

export const systemTagParamsSchema = z.object({
  assetType: UpdateAssetTypeSchema,
  assetId: z.string(),
  systemTag: systemTagValuesSchema.nullish().transform((systemTag) => (systemTag ? systemTag : '')),
});

export type TSystemTagParams = z.input<typeof systemTagParamsSchema>;

export const sourceSinkTagParamsSchema = z.object({
  assetType: UpdateAssetTypeSchema,
  assetId: z.string(),
  sourceSinkTag: sourceSinkTagValuesSchema.nullish().transform((sourceSinkTag) => (sourceSinkTag ? sourceSinkTag : '')),
});

export type TSourceSinkTagParams = z.input<typeof sourceSinkTagParamsSchema>;
