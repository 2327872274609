import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback } from 'react';

import { useGatewayHook as useGatewayHookShared } from '../../shared/hooks/use-gateway.hook';

export const useGatewayHook = () => {
  const { goTo } = useRouter();
  const nextRoute = routes.gateway.list.path;

  const onSuccess = useCallback(() => {
    goTo(nextRoute, { state: { shouldIgnoreGuard: true } });
  }, [goTo, nextRoute]);

  return useGatewayHookShared({
    onSuccess,
  });
};
