import { Copy } from '@marlin/account/shared-api-key-utils';
import { MarlinTheme } from '@marlin/shared/theme';
import { Input } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  section: {
    alignItems: 'center',
  },

  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.typography.pxToRem(75),
    marginBottom: theme.typography.pxToRem(16),
  },

  adornment: {
    marginRight: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface IOrganizationIdProps {
  organizationId?: string;
}

export const OrganizationId = ({ organizationId = '' }: IOrganizationIdProps) => {
  const { classes } = useStyles();

  const headerEndAdornment = (
    <div className={classes.adornment}>
      <Copy value={organizationId} testId="organization-id" />
    </div>
  );

  return (
    <Paper data-testid="card">
      <Grid container columnSpacing={2} className={classes.section}>
        <Grid item className={classes.sectionTitle} data-testid="card-title">
          {content.ORGANIZATION_ID}
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} className={classes.section}>
        <Grid item flex={1}>
          <Input
            disabled
            label={content.ORGANIZATION_ID_HEADER}
            value={organizationId}
            externalEndAdornment={{ endAdornment: headerEndAdornment }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
