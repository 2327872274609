import { Menu } from '@mui/material';

import { IEntity } from '../../utils/types';
import { OrganizationDetailsContent } from './organization-details-content';
import { useStyles } from './organization-details.styles';

interface IOrganizationDetailsProps {
  entities: IEntity[];
  open: boolean;
  anchor: null | HTMLElement;
  entityName?: string;
  isOrganizationListVisible: boolean;
  currentOrgId?: string;
  onClose: () => void;
  onChange: (event: React.MouseEvent<HTMLElement>) => void;
  variant: 'marlin' | 'portfolio' | 'support';
  fetchNextPage?: () => void;
  isLoading: boolean;
  handleOrgSearchValue: (value: string) => void;
  orgSearchValue: string;
  hasNextPage: boolean;
}

export const OrganizationDetails = ({
  open,
  anchor,
  entityName,
  entities,
  isOrganizationListVisible,
  currentOrgId,
  onClose,
  onChange,
  variant,
  fetchNextPage,
  isLoading,
  handleOrgSearchValue,
  orgSearchValue,
  hasNextPage,
}: IOrganizationDetailsProps) => {
  const { classes } = useStyles();
  return (
    <Menu
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={classes.container}
    >
      <OrganizationDetailsContent
        entities={entities}
        currentOrgId={currentOrgId}
        entityName={entityName}
        isOrganizationListVisible={isOrganizationListVisible}
        onClose={onClose}
        onChange={onChange}
        variant={variant}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        handleOrgSearchValue={handleOrgSearchValue}
        orgSearchValue={orgSearchValue}
        hasNextPage={hasNextPage}
      />
    </Menu>
  );
};
