import { uiContent } from './content';
import { AlertLimit, EmailChannel, InAppChannel, SmsChannel } from './nodes/actions.component';
import { AutomationCard } from './nodes/automation-card.component';
import { AutomationInfo } from './nodes/automation-info.component';
import { Header } from './nodes/header.component';
import { SectionHeader } from './nodes/section-header.component';
import { TriggersNode } from './nodes/triggers.component';

export const initialNodes = [
  {
    id: 'header',
    position: { x: 0, y: 0 },
    data: { children: <Header /> },
    type: 'componentNode',
  },
  {
    id: 'automationInfo',
    position: { x: 0, y: 160 },
    data: { children: <AutomationInfo /> },
    type: 'componentNode',
  },
  {
    id: 'deviceTitle',
    position: { x: 0, y: 400 },
    data: {
      children: <SectionHeader title={'Device(s)'} prefix={'device'} />,
    },
    type: 'componentNode',
  },
  {
    id: 'triggerTitle',
    position: { x: 0, y: 600 },
    data: {
      children: <TriggersNode />,
    },
    type: 'componentNode',
  },
  {
    id: 'sensorNode',
    position: { x: 300, y: 350 },
    type: 'sensorNode',
    data: {},
  },
  {
    id: 'equipmentNode',
    position: { x: 300, y: 500 },
    type: 'equipmentNode',
    data: {},
  },
  {
    id: 'actionTitle',
    position: { x: 0, y: 1000 },
    data: {
      children: <SectionHeader title={uiContent.ACTION.TITLE} tooltip={uiContent.ACTION.TOOLTIP} prefix={'actions'} />,
    },
    type: 'componentNode',
  },
  {
    id: 'inApp',
    position: { x: 300, y: 850 },
    data: { children: <InAppChannel /> },
    type: 'componentNode',
  },
  {
    id: 'email',
    position: { x: 300, y: 1000 },
    data: { children: <EmailChannel /> },
    type: 'componentNode',
  },
  {
    id: 'sms',
    position: { x: 300, y: 1150 },
    data: { children: <SmsChannel /> },
    type: 'componentNode',
  },
  {
    id: 'alertLimit',
    position: { x: 800, y: 1000 },
    data: { children: <AlertLimit /> },
    type: 'componentNode',
  },
  {
    id: 'automationCard',
    position: { x: 0, y: 1350 },
    data: { children: <AutomationCard /> },
    type: 'componentNode',
  },
];

export const initialEdges = [
  { id: 'edge-1', source: 'header', target: 'automationInfo', sourceHandle: 'bottom', targetHandle: 'top' },
  { id: 'edge-2', source: 'automationInfo', target: 'deviceTitle', sourceHandle: 'bottom', targetHandle: 'top' },
  { id: 'edge-3', source: 'triggerTitle', target: 'actionTitle', sourceHandle: 'bottom', targetHandle: 'top' },
  { id: 'edge-7', source: 'deviceTitle', target: 'triggerTitle', sourceHandle: 'bottom', targetHandle: 'top' },
  { id: 'edge-31', source: 'deviceTitle', target: 'sensorNode', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-32', source: 'deviceTitle', target: 'equipmentNode', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-4', source: 'actionTitle', target: 'automationCard', sourceHandle: 'bottom', targetHandle: 'top' },
  { id: 'edge-41', source: 'actionTitle', target: 'inApp', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-42', source: 'actionTitle', target: 'email', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-43', source: 'actionTitle', target: 'sms', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-44', source: 'inApp', target: 'alertLimit', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-5', source: 'email', target: 'alertLimit', sourceHandle: 'right', targetHandle: 'left' },
  { id: 'edge-6', source: 'sms', target: 'alertLimit', sourceHandle: 'right', targetHandle: 'left' },
];
