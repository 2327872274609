import { z } from 'zod';

import { avatarUrl, chatName, citationSourceName } from '../constants';

export const conversationIdSchema = z.string().nullable();

export const chatParamsSchema = z.object({
  conversationId: conversationIdSchema,
  userMessage: z.string(),
});

export const docCitationSchema = z.object({
  title: z.string().nullish(),
  url: z.string().nullish(),
});

const removeConsecutiveSources = (input: string) => {
  return input.replaceAll(/(\[.*?])\1+/g, '$1');
};

export type TDocCitation = z.infer<typeof docCitationSchema>;

const insertDocCitation = (message: string, citations: TDocCitation[]): string => {
  let newMessage: string = message;

  citations.forEach((citation, index) => {
    newMessage = newMessage.replaceAll(
      `[doc${index + 1}]`,
      `[[${citationSourceName}](${encodeURI(citation.url || '')} )]`
    );
  });

  return removeConsecutiveSources(newMessage);
};

export const responseMessageSchema = z
  .object({
    timestamp: z.string(),
    content: z.string(),
    author: z.string(),
    docCitations: z.array(docCitationSchema).nullish(),
  })
  .transform((data) => {
    const message = data.content || '';
    const messageWithCitations = data.docCitations ? insertDocCitation(message, data.docCitations) : message;

    return {
      from: data.author.toUpperCase() === 'USER' ? 'me' : chatName,
      message: messageWithCitations,
      timestamp: data.timestamp,
      avatarUrl,
    };
  });

export const chatResponseSchema = z.object({
  conversationId: z.string(),
  messages: z.array(responseMessageSchema),
});

export type TChatParams = z.infer<typeof chatParamsSchema>;
export type TChatMessage = z.infer<typeof responseMessageSchema>;
export type TChatResponse = z.infer<typeof chatResponseSchema>;
