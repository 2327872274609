import { MarlinTheme } from '@marlin/shared/theme';
import { Icon, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { createChatGradient } from '../utils/colors';

interface IChatButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen?: boolean;
}

const useStyles = makeStyles<{ isOpen?: boolean }>()((theme: MarlinTheme, { isOpen }) => ({
  button: {
    background: isOpen ? theme.palette.alternative.main : createChatGradient(theme),
    position: 'fixed',
    bottom: theme.typography.pxToRem(32),
    right: theme.typography.pxToRem(32),
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
}));

export const ChatButton = ({ onClick, isOpen }: IChatButtonProps) => {
  const { classes } = useStyles({ isOpen });

  return (
    <IconButton className={classes.button} onClick={onClick}>
      <Icon baseClassName="material-symbols-outlined" className={classes.icon}>
        {isOpen ? 'close' : 'question_mark'}
      </Icon>
    </IconButton>
  );
};
