import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { IRegenerateVendorApiKey, TVendorApiKey, vendorApiKeySchema } from '../schemas/vendor-api-key.schema';
import { paths } from './api';

export const regenerateVendorApiKey = async ({
  vendorSubscriptionId,
}: IRegenerateVendorApiKey): Promise<TVendorApiKey> => {
  const res = await getHttpClient().put(
    paths.VENDOR_API_KEY_REGENERATE,
    {},
    {
      params: { vendorSubscriptionId },
    }
  );
  return safeParseData(res, vendorApiKeySchema);
};
