import { TopAlerts } from '@marlin/asset/shared/ui/top-alerts';
import { MarlinTheme } from '@marlin/shared/theme';
import { TTopOpenAlert } from '@marlin/shared/ui/top-open-alerts';
import { DEVICE_TYPE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useEquipmentAlertsContext } from '../../../../../shared/context/equipment-alerts.context';
import { useEquipmentDetailsConfigContext } from '../../../../../shared/context/equipment-details-config-context';
import { useEquipmentContext } from '../../../../../shared/context/equipment.context';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  alertsWrapper: {
    flexBasis: '40%',
  },
}));

export const DashboardAlerts = () => {
  const {
    config: {
      dashboard: { topAlerts },
    },
  } = useEquipmentDetailsConfigContext();
  const { equipment } = useEquipmentContext();
  const { alerts, isLoading } = useEquipmentAlertsContext();
  const { classes } = useStyles();

  const parsedAlerts = useMemo<
    {
      alert: TTopOpenAlert;
      alertErrorType: ERROR_TYPES;
      alertDeviceType: DEVICE_TYPE;
    }[]
  >(() => {
    const device = equipment?.devices?.[0];

    if (!device) return [];

    return alerts.map(({ criticality, startTime, title, uoM }) => ({
      alert: {
        criticality,
        startTime,
        title,
        deviceId: device.id,
        deviceName: device.name,
        deviceType: device.deviceType,
        uoM,
      },
      alertErrorType: ERROR_TYPES.DEVICE_ALERT,
      alertDeviceType: DEVICE_TYPE.EQUIPMENT,
    }));
  }, [alerts, equipment?.devices]);

  if (!topAlerts.show) return null;

  return (
    <TopAlerts
      topOpenAlerts={parsedAlerts}
      classes={classes.alertsWrapper}
      isLoading={isLoading}
      isEquipmentDashboardSection
    />
  );
};
