import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TApiKeyRequest, apiKeyRequestSchema, apiKeyResponseSchema } from '../schemas/api-key.schema';
import { paths } from './api';

export const createApiKey = async (data: TApiKeyRequest) => {
  const body = safeParseData(data, apiKeyRequestSchema);

  const response = await getHttpClient().post(paths.VENDOR_API_KEY, body);

  return safeParseData(response, apiKeyResponseSchema);
};
