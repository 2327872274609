import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useCreateApiKey, useGetApiKey } from '@marlin/account-data-access-webhook';
import camelCase from 'lodash/camelCase';
import deburr from 'lodash/deburr';
import escape from 'lodash/escape';
import { useCallback, useEffect, useState } from 'react';

const generateApiKeyName = ({
  organizationName,
  organizationId,
}: {
  organizationName: string;
  organizationId: string;
}) => {
  const escapedOrgName = camelCase(escape(deburr(organizationName)));
  return `${escapedOrgName}-${organizationId}`;
};

export const useVendorApiKey = () => {
  const { data: currentUser } = useCurrentUser();
  const { data: apiKey, isLoading } = useGetApiKey();
  const { mutateAsync: createApiKey } = useCreateApiKey();
  const [showLoader, setShowLoader] = useState(false);

  const generateNewApiKey = useCallback(() => {
    setShowLoader(true);
    if (currentUser) {
      createApiKey({
        name: generateApiKeyName(currentUser.currentOrganization),
        organizationIds: [currentUser.currentOrganization.organizationId],
      });
    }
  }, [createApiKey, currentUser]);

  useEffect(() => {
    if (!isLoading && apiKey?.apikey) {
      setShowLoader(false);
    }
  }, [apiKey?.apikey, isLoading]);

  return {
    apiKey,
    isLoading: showLoader,
    showButton: !apiKey && !isLoading,
    generateNewApiKey,
  };
};
