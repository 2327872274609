import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { TSourceSinkTagParams, useSetSourceSinkTag } from '@marlin/system-map/data-access/system-map';
import { TSourceSinkTag } from '@marlin/system-map/shared/data-access-schemas';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useContext } from 'react';

import { TSystemMapNode } from '../../../types';
import { content } from '../../content';
import { ChangeSystemTagBody } from '../modals/change-system-tag-modal/change-system-tag.body';
import { ChangeSystemTagFooter } from '../modals/change-system-tag-modal/change-system-tag.footer';
import { ChangeSystemTagTitle } from '../modals/change-system-tag-modal/change-system-tag.title';

interface ISetSystemTag {
  getSourceTagLabel: (systemTag?: TSourceSinkTag | null) => string;
  setSourceTag: (nodeId: string, closeContextMenu: () => void) => void;
}

export const useSetSourceTagContextMenu = (): ISetSystemTag => {
  const { getNodes, getNode } = useReactFlow<TSystemMapNode>();
  const { mutateAsync: mutateSystemTag } = useSetSourceSinkTag();
  const { enqueueSnackbar } = useSnackbar();
  const { modalDispatch } = useContext(ModalContext);

  const getSourceTagLabel = useCallback((sourceSinkTag?: TSourceSinkTag | null) => {
    return sourceSinkTag === 'POE_WATER_SOURCE'
      ? content.SYSTEM_SOURCE_TAG.REMOVE_TAG
      : content.SYSTEM_SOURCE_TAG.SET_TAG;
  }, []);

  const setNewSourceTag = useCallback(
    async ({
      assetId,
      assetType,
      sourceSinkTag,
      closeContextMenu,
    }: TSourceSinkTagParams & { closeContextMenu: () => void }) => {
      return mutateSystemTag({
        assetType,
        assetId,
        sourceSinkTag,
      })
        .then(() => {
          enqueueSnackbar(content.SYSTEM_SOURCE_TAG.SET_TAG_SUCCESS_MESSAGE, { variant: 'success' });
          closeContextMenu();
        })
        .catch(() => {
          enqueueSnackbar(content.SYSTEM_SOURCE_TAG.SET_TAG_ERROR_MESSAGE, { variant: 'error' });
        });
    },
    [enqueueSnackbar, mutateSystemTag]
  );

  const changeSourceTag = useCallback(
    async ({
      assetId,
      assetType,
      sourceSinkTag,
      previousAssetName,
      newAssetName,
      closeContextMenu,
    }: TSourceSinkTagParams & {
      previousAssetName: string;
      newAssetName: string;
      closeContextMenu: () => void;
    }) => {
      const handleCloseModal = () => modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });

      const handleConfirmChange = async () => {
        const response = await setNewSourceTag({ assetId, assetType, sourceSinkTag, closeContextMenu });
        handleCloseModal();
        return response;
      };

      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <ChangeSystemTagTitle />,
          body: <ChangeSystemTagBody previousAssetName={previousAssetName} newAssetName={newAssetName} />,
          footer: <ChangeSystemTagFooter onCancel={handleCloseModal} onConfirm={handleConfirmChange} />,
        },
      });
    },
    [modalDispatch, setNewSourceTag]
  );

  const removeSourceTag = useCallback(
    async ({ assetId, assetType, closeContextMenu }: TSourceSinkTagParams & { closeContextMenu: () => void }) => {
      return mutateSystemTag({
        assetType,
        assetId,
        sourceSinkTag: null,
      })
        .then(() => {
          enqueueSnackbar(content.SYSTEM_SOURCE_TAG.REMOVE_TAG_SUCCESS_MESSAGE, { variant: 'success' });
          closeContextMenu();
        })
        .catch(() => {
          enqueueSnackbar(content.SYSTEM_SOURCE_TAG.REMOVE_TAG_ERROR_MESSAGE, { variant: 'error' });
        });
    },
    [enqueueSnackbar, mutateSystemTag]
  );

  const setSourceTag = useCallback(
    (nodeId: string, closeContextMenu: () => void) => {
      const currentNode = getNode(nodeId);
      const nodes = getNodes();

      const currentSourceSinkTag = currentNode?.data.sourceSinkTags || [];
      const assetId = currentNode?.data.assetId;
      const assetType = currentNode?.data.assetType;

      const previousSourceAsset = nodes.find(
        (node) => (node.data.sourceSinkTags || []).includes('POE_WATER_SOURCE') && node.id !== nodeId
      );

      if (!assetId || !assetType) {
        return;
      }

      if (currentSourceSinkTag.includes('POE_WATER_SOURCE')) {
        return removeSourceTag({ assetId, assetType, closeContextMenu });
      }

      if (previousSourceAsset) {
        return changeSourceTag({
          assetId,
          assetType,
          sourceSinkTag: 'POE_WATER_SOURCE',
          previousAssetName: previousSourceAsset?.data.name || '',
          newAssetName: currentNode?.data.name || '',
          closeContextMenu,
        });
      }

      return setNewSourceTag({ assetId, assetType, sourceSinkTag: 'POE_WATER_SOURCE', closeContextMenu });
    },
    [changeSourceTag, getNode, getNodes, removeSourceTag, setNewSourceTag]
  );

  return {
    getSourceTagLabel,
    setSourceTag,
  };
};
