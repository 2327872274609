import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IOrganizationItemStylesProps {
  showTier: boolean;
}

export const useStyles = makeStyles<IOrganizationItemStylesProps>()((theme: MarlinTheme, { showTier }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(8),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    whiteSpace: 'normal',
    justifyContent: 'space-between',
    width: '100%',
  },
  orgName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: theme.typography.pxToRem(showTier ? 220 : 270),
    width: '80vw',
  },
  orgNameFull: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.text.primary,
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  greenDot: {
    color: theme.palette.success.light,
    fontSize: theme.typography.pxToRem(14),
  },
  orgNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    width: '90%',
  },
}));
