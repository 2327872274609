export const content = {
  SUCCESS_REMOVE_MESSAGE: 'Your gateway has been removed.',
  GATEWAY_HUB_TITLE: 'Gateways',
  GATEWAY_HUB_SUBTITLE: 'Use the data table below to manage your gateways.',
  GATEWAY_TABLE_TITLE: 'Gateway Status',
  GATEWAY_TABLE_NAME_COLUMN: 'Gateway Name',
  GATEWAY_TABLE_ID_COLUMN: 'ID',
  GATEWAY_TABLE_STATUS_COLUMN: 'Status',
  GATEWAY_TABLE_POWER_SOURCE_COLUMN: 'Power Source',
  GATEWAY_TABLE_SIGNAL_STRENGTH_COLUMN: 'Signal Strength',
  GATEWAY_TABLE_LAST_DATA_RECEIVED_COLUMN: 'Last Data Received',
  GATEWAY_TABLE_ACTIVE_SENSORS_COLUMN: 'Active Connections',
  GATEWAY_TABLE_NO_DATA: 'No results found.',
  GATEWAY_TABLE_ERROR: 'Something went wrong. Please try again later.',
  STATUS_HEADER_CELL_TOOLTIP:
    'Status is based on the last data received. A gateway is inactive after not receiving data for 8 hours, or as set by you.',
  CREATE: 'Create New',

  STATUS_CELL_ACTIVE: 'Online (Active)',
  STATUS_CELL_STANDBY: 'Online (Standby)',
  STATUS_CELL_OFFLINE: 'Offline',
  STATUS_CELL_ONLINE: 'Online',

  SIGNAL_CELL_HIGH: 'High',
  SIGNAL_CELL_MEDIUM: 'Med',
  SIGNAL_CELL_LOW: 'Low',

  DEREGISTER: 'Deregister',
  DEREGISTER_MODAL: {
    TITLE: 'Deregister Gateway?',
    BODY_TEXT_1ST_PART: 'Are you sure you want to deregister',
    BODY_TEXT_2ND_PART: 'This action cannot be undone.',
    FLOW_LINK_INFO: 'The following equipment and/or sensors will also be deleted and their Nexa history will be lost.',
    FLOW_LINKS_TABLE: 'Equipment & Sensors',
    CANCEL: 'Cancel',
    DEREGISTER: 'Deregister',
  },
  GATEWAY_SIGNAL: new Map([
    ['EXCELLENT', 'Excellent'],
    ['GOOD', 'Good'],
    ['FAIR', 'Fair'],
    ['POOR', 'Poor'],
    ['NOSIGNAL', 'No Signal'],
  ]),
  LAST_CONNECTION: 'Last Connection',
  GATEWAY_ID: 'Gateway ID',
  LINE: 'Line',
  BATTERY: 'Battery',
};
