import {
  TOrgDetails,
  TUpdateOrgAddress,
  useGetOrganizationDetails,
  useSupportUpdateOrganizationDetails,
  useUpdateOrganizationDetails,
} from '@marlin/account-data-access-organization';

interface IUpsertOrgProps {
  organizationId?: string;
  support?: boolean;
}

interface IUpsertOrg {
  data?: TOrgDetails;
  isFetched: boolean;
  updateOrganizationDetails: (params: TUpdateOrgAddress) => Promise<unknown>;
  isOrganizationDetailsUpdating: boolean;
}

export const useUpsertOrg = ({ organizationId, support }: IUpsertOrgProps): IUpsertOrg => {
  const { data, isFetched } = useGetOrganizationDetails(organizationId ? { organizationId } : undefined);
  const { mutateAsync: updateOrganizationDetails, isLoading: isOrganizationDetailsUpdating } =
    useUpdateOrganizationDetails(organizationId ? { organizationId } : undefined);
  const { mutateAsync: updateOrganizationDetailsSupport, isLoading: isOrganizationDetailsUpdatingSupport } =
    useSupportUpdateOrganizationDetails(organizationId ? { organizationId } : undefined);

  return {
    data,
    isFetched,
    updateOrganizationDetails: support ? updateOrganizationDetailsSupport : updateOrganizationDetails,
    isOrganizationDetailsUpdating: support ? isOrganizationDetailsUpdatingSupport : isOrganizationDetailsUpdating,
  };
};
