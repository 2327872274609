import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  TChatParams,
  TChatResponse,
  chatParamsSchema,
  chatResponseSchema,
  conversationIdSchema,
} from '../schemas/chat.schema';
import { paths } from './api';

export const chat = async (data: TChatParams): Promise<TChatResponse> => {
  const body = safeParseData(data, chatParamsSchema);

  const response = await getHttpClient().post(paths.CHAT, body);

  return safeParseData(response, chatResponseSchema);
};

export const getChatMessages = async (conversationId: string): Promise<TChatResponse> => {
  const parsedConversationId = conversationIdSchema.parse(conversationId);

  const response = await getHttpClient().get(paths.CHAT_MESSAGES, { params: { conversationId: parsedConversationId } });

  return safeParseData(response, chatResponseSchema);
};
