import { calculateAnimationDelay, dotCount, useStyles } from './chat-loader.styles';

export const ChatLoader = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      {Array.from({ length: dotCount }).map((_, index) => (
        <div
          key={index}
          className={classes.chatDot}
          style={{
            animationDelay: calculateAnimationDelay(index),
          }}
        />
      ))}
    </div>
  );
};
