import {
  TGatewayDetailsRow,
  mapGatewayDataSource,
  mapGatewayDatapointsDataSource,
} from '@marlin/asset/shared/utils/details-mapper';
import { TDateString, formatDate } from '@marlin/shared/utils-common-date';
import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';

import { content } from '../content';

export type TRow = { label: string; value?: number | string; column?: number };

const firmwareVersionCallback = (value: unknown) => {
  if (typeof value === 'string') {
    try {
      const configObject = JSON.parse(value);
      return configObject?.Firmware?.Version;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const detailsConfig: TGatewayDetailsRow[] = [
  { name: 'manufacturerId', label: content.GATEWAY_ID, valueSource: 'gateway', column: 1 },
  {
    name: 'ConfigData',
    label: content.FIRMWARE_VERSION,
    valueSource: 'datapoints',
    column: 1,
    valueCallback: firmwareVersionCallback,
  },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'gateway',
    column: 1,
    valueCallback: (value) => formatDate(value as unknown as TDateString, 'MM/DD/YY'),
  },
  { name: 'configurationId', label: content.CONFIGURATION_ID, valueSource: 'gateway', column: 1 },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'gateway', column: 2 },
];

interface IDetailsData {
  datapoints: TDatapointWithMetadata[];
  gateway: TGatewayInfoUpdateResponsePayload;
}

export const useGatewayDetailsSectionData = ({ datapoints, gateway }: IDetailsData): TRow[] => {
  if (!gateway) {
    return [];
  }

  const data = datapoints.length ? datapoints : gateway.lastReadingValues;

  return detailsConfig.map((row) => {
    const emptyRow = {
      label: row.label,
      value: content.EMPTY_DATAPOINT_VALUE,
      column: row.column,
    };

    switch (row.valueSource) {
      case 'gateway':
        return mapGatewayDataSource(row, gateway) ?? emptyRow;
      case 'datapoints':
        return mapGatewayDatapointsDataSource(row, data) ?? emptyRow;
      default:
        return emptyRow;
    }
  });
};
