import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  toggleWrapper: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.typography.pxToRem(24),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  toggle: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },

  label: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(16),
  },
}));
