import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(16),
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.typography.pxToRem(16),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  headerText: {
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.17),
    marginTop: theme.typography.pxToRem(4),
    marginBottom: theme.typography.pxToRem(16),
  },
  section: {
    alignItems: 'center',
  },
}));
