import { TOperator, TRule } from '@marlin/alert/data-access/automated-action';
import { useMetadataByModels } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { Select } from '@marlin/shared/ui-form-common';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, SelectChangeEvent } from '@mui/material';
import { useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { isEquipmentOrSensorStore } from '../../shared/utils/type-guards';
import { operator } from './operator.component';
import { value } from './value.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
  segment: {
    width: '33%',
  },
  select: {
    width: '100%',
  },
}));

interface IBasicRuleProps {
  model?: string;
  onSubmit: (rule: TRule, metadata?: TDeviceMetadataDatapointsResponse) => void;
  rule?: TRule;
  onCancel?: () => void;
}

const getOperator = (metadata?: TDeviceMetadataDatapointsResponse) => {
  switch (metadata?.type) {
    case 'number':
      return operator('numeric');
    case 'bool':
    case 'enum':
      return operator('non-numeric');
    default:
      return null;
  }
};

const getThreshold = (metadata?: TDeviceMetadataDatapointsResponse) => {
  switch (metadata?.type) {
    case 'number':
      return value('number');
    case 'bool':
      return value('bool');
    case 'enum':
      return value('enum');
    default:
      return null;
  }
};

export const RuleBuilder = ({ model, onSubmit, rule, onCancel }: IBasicRuleProps) => {
  const { classes, cx } = useStyles();
  const [datapoint, setDatapoint] = useState<string | null>(rule?.datapoint || null);
  const [operatorValue, setOperatorValue] = useState<TOperator>(rule?.operator ?? 'eq');
  const [value, setValue] = useState<string>(rule?.threshold ?? '');

  const { currentState } = useTriggersStore$();

  const { data: metadata } = useMetadataByModels(model ? [model] : []);
  const datapointList = useMemo(() => {
    return metadata
      ? metadata[0].metadata.datapoints.map((datapoint) => ({ id: datapoint.name, name: datapoint.name }))
      : [];
  }, [metadata]);
  const filteredDatapoint = useMemo(() => {
    return metadata ? metadata[0].metadata.datapoints.find((d) => d.name === datapoint) : undefined;
  }, [metadata, datapoint]);

  const Operator = useMemo(() => getOperator(filteredDatapoint), [filteredDatapoint]);
  const Threshold = useMemo(() => getThreshold(filteredDatapoint), [filteredDatapoint]);

  if (!isEquipmentOrSensorStore(currentState)) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={cx(classes.segment, 'nodrag')}>
        <Select
          label="Datapoint"
          value={datapoint}
          onChange={(event: SelectChangeEvent) => {
            setDatapoint(event.target.value as string);
          }}
          data={datapointList}
          prefix="device-model"
          fullWidth
          className={classes.select}
        />
      </div>
      <div className={cx(classes.segment, 'nodrag')}>
        {Operator && <Operator value={operatorValue} onChange={setOperatorValue} />}
      </div>
      <div className={cx(classes.segment, 'nodrag')}>
        {Threshold && <Threshold value={value} onChange={setValue} metadata={filteredDatapoint} />}
      </div>
      <IconButton color="error" onClick={onCancel}>
        <ClearIcon />
      </IconButton>
      <IconButton
        color="success"
        onClick={() =>
          onSubmit(
            {
              datapoint: datapoint!,
              operator: operatorValue,
              threshold: value,
              id: currentState?.ruleCounter.toString(),
            },
            filteredDatapoint
          )
        }
      >
        <CheckIcon />
      </IconButton>
    </div>
  );
};
