import { Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useMemo } from 'react';

import { useStyles } from './card-header.component.styles';

type TStatus = 'error' | 'warning' | 'success' | undefined;

export interface ICardHeaderProps extends PropsWithChildren {
  status?: TStatus;
  isMobile?: boolean;
  subtitle?: ReactNode;
  onSubtitleClick?: () => void;
  onTitleClick?: () => void;
  title: ReactNode;
  impact?: ReactNode;
  icon?: ReactNode;
  compact?: boolean;
  cardType?: string;
  empty?: boolean;
}

const CardHeader = ({
  status,
  isMobile,
  subtitle,
  title,
  onSubtitleClick,
  onTitleClick,
  impact,
  icon,
  compact,
  empty,
  cardType,
}: ICardHeaderProps) => {
  const { classes, cx } = useStyles();

  const headerClass = useMemo(() => {
    switch (status) {
      case 'error': {
        return classes.headerWithHighAlert;
      }
      case 'warning': {
        return classes.headerWithLowAlert;
      }
      case 'success': {
        return classes.headerWithSuccess;
      }
      default: {
        return classes.header;
      }
    }
  }, [classes.header, classes.headerWithHighAlert, classes.headerWithLowAlert, classes.headerWithSuccess, status]);

  const iconClass = useMemo(() => {
    switch (status) {
      case 'error': {
        return classes.iconError;
      }
      case 'warning': {
        return classes.iconWarning;
      }
      case 'success': {
        return classes.iconSuccess;
      }
      default: {
        return classes.icon;
      }
    }
  }, [classes.icon, classes.iconError, classes.iconWarning, classes.iconSuccess, status]);

  return (
    <div
      className={cx(classes.wrapper, isMobile ? classes.mobileWrapper : classes.desktopWrapper, headerClass, {
        [classes.compact]: compact,
        [classes.emptyMobile]: empty && isMobile,
      })}
    >
      <div className={classes.headerWrapper}>
        {subtitle && (
          <Typography
            className={classes.subtitle}
            variant="body2"
            title={subtitle.toString()}
            onClick={onSubtitleClick}
            data-testid={cardType ? `${cardType}-card-subtitle` : 'card-subtitle'}
          >
            {subtitle}
          </Typography>
        )}
        <div className={classes.titleWrapper}>
          <Typography
            className={classes.title}
            variant="h6"
            onClick={onTitleClick}
            data-testid={cardType ? `${cardType}-card-title` : 'card-title'}
          >
            {title}
          </Typography>
        </div>
        {impact && <Typography variant="body2">{impact}</Typography>}
      </div>
      {icon && (
        <div data-testid={cardType ? `${cardType}-statusIcon` : 'statusIcon'} className={iconClass}>
          {icon}
        </div>
      )}
    </div>
  );
};

export { CardHeader };
