import { useCurrentUser } from '@marlin/account-data-access-organization';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tab, Tabs } from '@mui/material';
import { FC, PropsWithChildren, SyntheticEvent, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { content } from './content';
import { isEditRecipientPage, isEditUserPage } from './helper';

interface ISettingsPageWrapperProps {
  showTabs?: boolean;
}

export const SettingsPageWrapper: FC<PropsWithChildren<ISettingsPageWrapperProps>> = ({
  children,
  showTabs = false,
}) => {
  const { pathname } = useLocation();
  const { goTo } = useRouter();
  const { data } = useCurrentUser();

  const allowedToSettings = usePermission(PERMISSIONS.SHOW_SETTINGS_PAGE);
  const allowedToOrgPage = usePermission(PERMISSIONS.SHOW_ORGANIZATION_SETTINGS_PAGE);

  const allowedToOrgsSection = usePermission(PERMISSIONS.SHOW_ORGANIZATION_ADDRESS_SETTINGS_PAGE);
  const isSupport = data?.currentOrganization.roles.find((role) => ['Support', 'BUCS', 'CSM'].includes(role));
  const allowedToWebhooks = usePermission(PERMISSIONS.VENDOR_WEBHOOKS);
  const allowedToExternalApi = usePermission(PERMISSIONS.VENDOR_API);
  const allowedToIntegrations = useMemo(
    () => allowedToSettings && (allowedToWebhooks || allowedToExternalApi),
    [allowedToSettings, allowedToWebhooks, allowedToExternalApi]
  );

  const handleTabChange = (event: SyntheticEvent, pathname: string) => {
    goTo(pathname);
  };

  const { title, subtitle } = useMemo(() => {
    switch (pathname) {
      case routes.settings.addRecipient.url():
        return {
          title: content.SETTINGS_ADD_RECIPIENT_TITLE,
          subtitle: content.SETTINGS_ADD_RECIPIENT_SUBTITLE,
        };
      case routes.settings.inviteNewUser.url(): {
        return {
          title: content.SETTINGS_INVITE_NEW_USER_TITLE,
          subtitle: content.SETTINGS_INVITE_NEW_USER_SUBTITLE,
        };
      }
      case routes.settings.people.url(): {
        return {
          title: content.SETTINGS_TITLE,
          subtitle: content.SETTINGS_SUBTITLE_MANAGE_USER_SETTINGS,
        };
      }
      case routes.settings.orgSettings.url(): {
        return {
          title: content.SETTINGS_TITLE,
          subtitle: content.SETTINGS_SUBTITLE_ORGANIZATION_INFORMATION,
        };
      }
      case routes.settings.hotsos.url(): {
        return { title: content.SETTINGS_TITLE, subtitle: content.SETTINGS_SUBTITLE_SUPPORT };
      }
      case routes.settings.appearance.url(): {
        return {
          title: content.SETTINGS_TITLE,
          subtitle: content.SETTINGS_SUBTITLE_MY_PREFERENCES_VIEWER,
        };
      }
      case routes.settings.profile.url(): {
        return { title: content.SETTINGS_TITLE, subtitle: content.SETTINGS_SUBTITLE_MY_ACCOUNT_VIEWER };
      }
      default: {
        if (isEditUserPage(pathname)) {
          return { title: content.SETTINGS_EDIT_USER_TITLE, subtitle: content.SETTINGS_EDIT_USER_SUBTITLE };
        }
        if (isEditRecipientPage(pathname)) {
          return {
            title: content.SETTINGS_EDIT_RECIPIENT_TITLE,
            subtitle: content.SETTINGS_EDIT_RECIPIENT_SUBTITLE,
          };
        }
        return {
          title: content.SETTINGS_TITLE,
          subtitle: allowedToSettings ? content.SETTINGS_SUBTITLE_SUPPORT : content.SETTINGS_SUBTITLE_VIEWER,
        };
      }
    }
  }, [pathname, allowedToSettings]);

  return (
    <PageContainer>
      <PageHeader icon={<SettingsIcon />} title={title} subtitle={subtitle} prefix="settings-hub-header" />
      {showTabs && (
        <Tabs value={pathname} onChange={handleTabChange}>
          {allowedToSettings && (
            <Tab
              value={routes.settings.people.url()}
              label={content.SETTINGS_TAB_PEOPLE}
              data-testid="page-people-tab"
            />
          )}
          {!isSupport && (
            <Tab
              disabled={!data?.id}
              value={routes.settings.profile.url()}
              label={content.SETTINGS_TAB_MY_ACCOUNT}
              data-testid="page-my-account-tab"
            />
          )}
          <Tab
            value={routes.settings.appearance.url()}
            label={content.SETTINGS_TAB_MY_PREFERENCES}
            data-testid="page-appearance-tab"
          />
          {allowedToOrgPage && allowedToOrgsSection && (
            <Tab
              value={routes.settings.orgSettings.url()}
              label={content.SETTINGS_TAB_ORGANIZATION}
              data-testid="page-org-tab"
            />
          )}
          {allowedToIntegrations && (
            <Tab
              value={routes.settings.integration.url()}
              label={content.SETTINGS_TAB_INTEGRATION}
              data-testid="page-integration-tab"
            />
          )}
        </Tabs>
      )}
      {children}
    </PageContainer>
  );
};
