import { SettingsErrorPage } from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useBoilersAndPumps } from '../../../../shared/hooks/tekmar-ssc/use-boilers-and-pumps.hook';
import { useCreateColumns } from '../../../hooks/boilers-and-pumps/use-create-columns.hook';
import { MobileBoilersAndPumps } from './mobile-boilers-and-pumps.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  dataGridWrapper: {
    height: theme.typography.pxToRem(600),
    width: '100%',
  },
  loadingSpinnerWrapper: {
    minHeight: theme.typography.pxToRem(350),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const BoilersAndPumps = () => {
  const columns = useCreateColumns({ columnProps: { sortable: false, disableColumnMenu: true } });
  const equipmentId = useIdFromPathname();
  const { boilersAndPumps, isLoading, isError, refetch } = useBoilersAndPumps(equipmentId || '');
  const [pageSize, setPageSize] = useState(10);
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isLoading) {
    return (
      <div className={classes.loadingSpinnerWrapper}>
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return <SettingsErrorPage onRefreshClick={refetch} />;
  }

  if (isMobile) {
    return <MobileBoilersAndPumps boilersAndPumps={boilersAndPumps} />;
  }

  return (
    <div className={classes.dataGridWrapper}>
      <DataGrid
        columns={columns}
        rows={boilersAndPumps}
        rowsPerPageOptions={[10, 25]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </div>
  );
};
