import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useIsSticking } from './use-is-sticking.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  stickyHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2, // Note: so that the header is above the page content
    padding: theme.typography.pxToRem(32),
    margin: theme.typography.pxToRem(-32),
    paddingTop: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(-16),
    paddingBottom: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.background.secondary,
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    maxHeight: theme.typography.pxToRem(72), // Note: align to Figma design
  },
  unstickyHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
  },
}));

/**
 * StickyHeaderWrapper component
 * @description if you need any header to be sticky, wrap it with this component
 */
export const StickyHeaderWrapper = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();
  const stickyHeaderRef = useRef<HTMLDivElement>(null);

  const { isSticking } = useIsSticking({ elementRef: stickyHeaderRef });

  if (!isSticking) {
    return (
      <div data-testid="sticky-header" className={classes.unstickyHeader} ref={stickyHeaderRef}>
        {children}
      </div>
    );
  }

  return (
    <div data-testid="sticky-header" className={classes.stickyHeader} ref={stickyHeaderRef}>
      {children}
    </div>
  );
};
