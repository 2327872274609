import { Copy, Visibility } from '@marlin/account/shared-api-key-utils';
import { INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import { content } from '../content';
import { useStyles } from './header-row.styles';

interface IApiKeyProps {
  keyHeader?: string;
  keyValue?: string;
  index: number;
}

export const HeaderRow = ({ keyHeader = '', keyValue = '', index }: IApiKeyProps) => {
  const { classes } = useStyles();
  const [valueInputType, setValueInputType] = useState(INPUT_TYPE.PASSWORD);

  const headerEndAdornment = (
    <div className={classes.adornment}>
      <Copy value={keyHeader} testId="api-key-header" />
    </div>
  );

  const valueEndAdornment = (
    <div className={classes.adornment}>
      <Visibility inputType={valueInputType} onInputTypeChange={setValueInputType} />
      <Copy value={keyValue} testId="api-key-value" />
    </div>
  );

  return (
    <>
      <Grid item md={0.5}>
        <Typography>{index}</Typography>
      </Grid>
      <Grid item md={5.75} xs={12}>
        <Input
          disabled
          data-testid="api-key-header"
          label={content.KEY(index)}
          value={keyHeader}
          externalEndAdornment={{ endAdornment: headerEndAdornment }}
        />
      </Grid>
      <Grid item md={5.75} xs={12}>
        <Input
          disabled
          data-testid="api-key-value"
          label={content.VALUE(index)}
          value={keyValue}
          type={valueInputType}
          externalEndAdornment={{
            endAdornment: valueEndAdornment,
          }}
        />
      </Grid>
    </>
  );
};
