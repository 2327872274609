import { environment } from '@marlin/environment';

export const content = {
  EQUIPMENT_LABEL: 'Equipment Details',
  EQUIPMENT_NAME: 'Equipment Name',
  REGISTRATION_CODE_LINK: 'Where do I find my registration code?',
  SERIAL_NUMBER_LINK: 'Where do I find my serial number?',
  CONTROLLER_FIXED_ID_LINK: 'Where do I find my Controller Fixed ID?',
  LOCATION_NAME: 'Location',
  SERIAL_NUMBER: 'Serial Number',
  MANUFACTURER: 'Manufacturer',
  REGISTER_LABEL: 'Register & Connect',
  REGISTER_SUBTITLE_LABEL: 'Connect to your equipment to monitor and receive alerts',
  REGISTER_CODE_MIN_MAX: 'You must enter 8 digits, no spaces or special characters. E.g.: 12345678',
  REGISTER_CODE_NUMBERS_ONLY: 'You must enter 9 digits, no spaces or special characters. E.g.: 123456789',
  MODEL: 'Model',
  DESCRIPTION: 'Description',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
  REGISTER_CODE: 'Registration Code',
  CONTROL_PANEL_ID: 'Control Panel ID',
  CREATE: 'Create',
  ADDITIONAL_INFORMATION: 'Additional Information',
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  COMMISSION_DATE: 'Commission Date',
  DATE_VALIDATION_FUTURE: 'The date cannot be in the future, please try again',
  EQUIPMENT_TYPE: 'Equipment Type',
  DIGITAL_MIXING_VALVE: 'Digital Mixing Valve',
  REGISTER_CODE_TOOLTIP: 'For detailed instructions on how to find the code to register your equipment visit ',
  REGISTER_CODE_EXPIRED: 'The code is expired, please try again',
  REGISTER_CODE_ALREADY_USED: 'The code is already used, please try again',
  REGISTER_CODE_INCORRECT: 'The code is incorrect, please try again',
  EQUIPMENT_NAME_NOT_UNIQUE: 'The name already exists in the organization, please try again',
  EQUIPMENT_ALREADY_REGISTERED: 'The equipment is already registered',
  EQUIPMENT_ALREADY_REGISTERED_AERCO:
    'This equipment has already been registered. Please check your Serial Number and Controller Fixed ID and try again.',
  CHOOSE_EQUIPMENT_LABEL: 'Choose Equipment',
  TOOLTIP_MESSAGE: `Choose Equipment: "Other" if your equipment is not compatible with ${environment.app.name}.`,
  INTELLISTATION_TYPE: 'Powers IntelliStation 2',
  AERCO_BENCHMARK_TYPE: 'AERCO Benchmark Boiler',
  AERCO_INNOVATION_TYPE: 'AERCO Innovation Water Heater',
  OTHER_TYPE: 'Other',
  OTHER_DESCRIPTION: 'Select "Other" if your equipment is not compatible with Nexa.',
  HOT_WATER_HEATER: 'Water Heater',
  BOILER: 'Boiler',
  CONTROLLER_FIXED_ID: 'Controller Fixed ID',
  SERIAL_NUMBER_OR_FIXED_ID_INVALID:
    'Either the Serial Number or Controller Fixed ID is incorrect. Please check your Serial Number and Controller Fixed ID and try again.',
  SERIAL_NUMBER_INVALID: `Enter 9 to 20 characters separated by dashes: 1 or 2 letters, 2 digits, and up to 14 or 15 digits (4 minimum), e.g., G-12-1234`,
  FIXED_ID_INVALID: 'Enter a numerical value between 4 and 10 digits. E.g.: 1234',
  COMMON_MAX_LENGTH: 'Enter a maximum of 50 characters.',
  LOCATIONS_FETCHING_ERROR: "Something went wrong. Locations haven't been fetched.",
  PARTITION_CONTROLLER: 'Partition Controller',
  BRAND: 'Brand',
};
