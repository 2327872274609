import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  section: {
    alignItems: 'center',
    width: '100%',
    gap: theme.typography.pxToRem(8),
  },

  text: {
    width: '100%',
  },
}));
