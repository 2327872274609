import {
  TGatewayConfigurationResponse,
  type TOperation,
  useCreateGateway,
  useDeactivateGateway,
  useLoadGatewayConfiguration,
  useUpdateGateway,
} from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { z } from 'zod';

import { getConfigurationPayload } from '../components/form/get-configuration-payload';
import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
} from '../components/form/upsert-connection-form.schema';
import { content } from '../content';
import { IConnectionInfo, TAddressErrors } from '../context/test-flow.context';
import { isUpdating } from '../utils/is-updating.utils';

interface ISaveAndTestConfiguration {
  deviceId: string;
  setConnections: (connections: IConnectionInfo[]) => void;
  onClose: () => void;
  refetch: () => void;
  data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate;
  setOperations: (operations: TOperation[]) => void;
  redirectToTestPage: () => void;
  setAddressErrors: React.Dispatch<React.SetStateAction<TAddressErrors | null>>;
}

const getOperationsFromConfiguration = (configuration: TGatewayConfigurationResponse): TOperation[] => {
  return configuration.connections.flatMap((connection) =>
    connection.addresses.flatMap(
      (address) =>
        address.operations?.flatMap((operation) => ({
          gatewayId: configuration.deviceId,
          connectionId: connection.id,
          addressId: address.id,
          operationCode: operation.code,
        })) || []
    )
  );
};

const getConnectionsFromConfiguration = (configuration: TGatewayConfigurationResponse) => {
  return configuration.connections.map((connection) => ({
    name: connection.name,
    id: connection.id,
    addresses: connection.addresses.map((address) => ({
      id: address.id,
      address: address.address,
    })),
  }));
};

export const useSaveAndTestConfiguration = ({
  deviceId,
  setConnections,
  onClose,
  data,
  refetch,
  setOperations,
  redirectToTestPage,
  setAddressErrors,
}: ISaveAndTestConfiguration) => {
  const deactivateGateway = useDeactivateGateway({ deviceId });
  const loadConfiguration = useLoadGatewayConfiguration();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useCallback(
    (error: AxiosError<{ errorMessage: string; status: string }>) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      if (error?.response?.data?.status) {
        try {
          setAddressErrors(JSON.parse(error?.response?.data.errorMessage));
        } catch (e) {
          enqueueSnackbar(error?.response?.data.errorMessage, { variant: 'error' });
          setAddressErrors(null);
        }
      } else {
        enqueueSnackbar(content.ERROR_MESSAGE, { variant: 'error' });
      }

      onClose();
    },
    [enqueueSnackbar, onClose, setAddressErrors]
  );

  const handleDeactivateAndLoadConfig = useCallback(
    ({ configurationId }: { configurationId: string }) => {
      const maxRetries = 5;
      let retryCount = 0;

      const retryLoadConfiguration = () => {
        loadConfiguration.mutate(
          { deviceId, configurationId },
          {
            onError: () => {
              enqueueSnackbar(content.SOMETHING_WENT_WRONG, {
                variant: 'error',
                preventDuplicate: true,
              });
            },
            onSuccess: (data) => {
              if (data.id !== configurationId && retryCount < maxRetries) {
                retryCount++;
                setTimeout(retryLoadConfiguration, 1000); // Retry after 1 second
              } else if (data.id === configurationId) {
                const operations = getOperationsFromConfiguration(data);
                const connections = getConnectionsFromConfiguration(data);
                setConnections(connections);
                setOperations(operations);
                redirectToTestPage();
                onClose();
              } else {
                enqueueSnackbar(content.SOMETHING_WENT_WRONG, {
                  variant: 'error',
                  preventDuplicate: true,
                });
              }
            },
          }
        );
      };

      deactivateGateway.mutate(
        { deviceId },
        {
          onError: () => {
            enqueueSnackbar(content.SOMETHING_WENT_WRONG, {
              variant: 'error',
              preventDuplicate: true,
            });
          },
          onSuccess: () => {
            retryLoadConfiguration();
          },
        }
      );
    },
    [
      deactivateGateway,
      deviceId,
      enqueueSnackbar,
      loadConfiguration,
      setConnections,
      setOperations,
      redirectToTestPage,
      onClose,
    ]
  );

  const onSubmitSuccess = useCallback(
    ({ configurationId }: { configurationId: string }) => {
      enqueueSnackbar(content.SAVE_MESSAGE, { variant: 'success' });
      refetch();
      handleDeactivateAndLoadConfig({ configurationId });
    },
    [enqueueSnackbar, handleDeactivateAndLoadConfig, refetch]
  );

  const { isLoading: isCreatingGateway, mutate: createGatewayWithSave } = useCreateGateway({
    onError,
    onSuccess: onSubmitSuccess,
  });

  const { isLoading: isUpdatingGateway, mutate: updateGatewayWithSave } = useUpdateGateway({
    onError,
    onSuccess: onSubmitSuccess,
  });

  const handleSaveAndTest = () => {
    const payload = getConfigurationPayload(data, deviceId);
    if (data && isUpdating(payload)) {
      updateGatewayWithSave({ data: { ...payload } });
    } else {
      createGatewayWithSave({ data: payload });
    }
  };

  const isLoading =
    deactivateGateway.isLoading || loadConfiguration.isLoading || isUpdatingGateway || isCreatingGateway;

  return { handleSaveAndTest, handleDeactivateAndLoadConfig, isLoading };
};
