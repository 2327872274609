import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

export const content: { [key in EQUIPMENT_TAB_VALUES]: string } = {
  [EQUIPMENT_TAB_VALUES.ALERTS]: `Alerts`,
  [EQUIPMENT_TAB_VALUES.SETTINGS]: `Settings`,
  [EQUIPMENT_TAB_VALUES.DASHBOARD]: `Dashboard`,
  [EQUIPMENT_TAB_VALUES.EVENT_LOG]: `Event Log`,
  [EQUIPMENT_TAB_VALUES.EQUIPMENT_FLOW]: `Digital Twin`,
  [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: `Historical Data`,
  [EQUIPMENT_TAB_VALUES.BOILERS_AND_PUMPS]: 'Boilers & Pumps',
  [EQUIPMENT_TAB_VALUES.BOILERS]: 'Boilers',
};
