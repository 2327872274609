import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { IMessage } from '../types';
import { ChatLoader } from './chat-loader.component';
import { ChatMessage } from './chat-message.component';
import { MyMessage } from './my-message.component';

interface IMessageList {
  messages: IMessage[];
  isAnswering?: boolean;
  scrollToBottom?: () => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },

  loader: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export const MessageList = ({ messages, isAnswering, scrollToBottom }: IMessageList) => {
  const { classes } = useStyles();

  return (
    <div className={classes.list}>
      {messages.map((message, index) =>
        message.from === 'me' ? (
          <MyMessage {...message} key={message.timestamp} />
        ) : (
          <ChatMessage
            {...message}
            key={message.timestamp}
            scrollToBottom={scrollToBottom}
            shouldAnimate={index === messages.length - 1}
          />
        )
      )}
      {isAnswering && (
        <div className={classes.loader}>
          <ChatLoader />
        </div>
      )}
    </div>
  );
};
