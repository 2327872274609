import { FormField } from '@marlin/shared/ui-form-common';
import { SectionContainer } from '@marlin/shared/ui-page';
import { Path } from 'react-hook-form';

import { useStyles } from './asset-form-sidebar.styles';
import { content } from './content';
import { SystemTagSelect } from './system-tag-select.component';
import { ISidebarFormModel } from './types';
import { WaterSourceSwitch } from './water-source-switch.component';

interface IAssetFormSidebarProps {
  showSourceSinkTag?: boolean;
}

export function AssetFormSidebar<TSidebarModel extends ISidebarFormModel = ISidebarFormModel>({
  showSourceSinkTag = false,
}: IAssetFormSidebarProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.sidebar}>
      <SectionContainer title={content.TITLE} headerClassName={classes.header}>
        <div className={classes.section}>
          {showSourceSinkTag && (
            <FormField<TSidebarModel> fieldName={'sourceSinkTag' as Path<TSidebarModel>}>
              {(props) => <WaterSourceSwitch {...props} />}
            </FormField>
          )}
          <FormField<TSidebarModel> fieldName={'systemTag' as Path<TSidebarModel>}>
            {(props) => <SystemTagSelect {...props} />}
          </FormField>
        </div>
      </SectionContainer>
    </div>
  );
}
