import { useCurrentUser } from '@marlin/account-data-access-organization';
import { ChangeOrgNameControl, OrganizationAddress } from '@marlin/account/shared/ui/edit-organization';
import { SystemConstraintsPage } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';

import { OrganizationId } from './components/organization-id.component';
import { useStyles } from './settings-organization.styles';

export const SettingsOrganization = () => {
  const { classes } = useStyles();

  const allowedToOrgsSection = usePermission(PERMISSIONS.SHOW_ORGANIZATION_ADDRESS_SETTINGS_PAGE);
  const allowedToOrgIdSection = usePermission([PERMISSIONS.VENDOR_API, PERMISSIONS.VENDOR_WEBHOOKS]);
  const { data: orgData, isError } = useCurrentUser();

  if (isError) {
    return <SystemConstraintsPage onlyContent />;
  }

  return (
    <div className={classes.pageWrapper}>
      {allowedToOrgsSection && (
        <>
          <div className={classes.sectionWrapper} data-testid="organization-settings-organization-name">
            <ChangeOrgNameControl />
          </div>
          <div className={classes.sectionWrapper} data-testid="organization-settings-organization-address">
            <OrganizationAddress />
          </div>
        </>
      )}
      {orgData && allowedToOrgIdSection && (
        <div className={classes.sectionWrapper} data-testid="organization-settings-organization-id">
          <OrganizationId organizationId={orgData.currentOrganization.organizationId} />
        </div>
      )}
    </div>
  );
};
