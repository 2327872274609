export const content = {
  TITLE: 'Tags',
  SOURCE_SINK_LABEL: 'This is Water Source',
  SYSTEM_TAG_LABEL: 'Tag',
  TAGS: {
    DOMESTIC_COLD_WATER: 'Domestic Cold Water',
    DOMESTIC_HOT_WATER: 'Domestic Hot Water',
    HEATING_HOT_WATER: 'Heating Hot Water',
  },
};
