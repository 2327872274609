import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: theme.typography.pxToRem(24),
  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(8),
  },
  editModeButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(16),
  },
  orgNameText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(0.15),
    lineHeight: '160%',
    marginBottom: theme.typography.pxToRem(16),
  },
  readOnly: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
}));
