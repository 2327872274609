export const content = {
  ORGANIZATION_DETAILS: 'Organization Details',
  ORGANIZATION_NAME: 'Organization Name',
  TIER: 'Tier',
  CHANGE_ORG_NAME: 'Edit organization details',
  MIN_MAX: 'Must be between 5 and 200 characters',
  MIN_3: 'Organization name must be at least 3 characters long',
  UPDATE_SUCCESS: 'Organization name has been changed',
  UPDATE_ERROR: 'Organization name update failed',
  MAX_LENGTH: (max: number) => `Maximum length of organization name is ${max}`,
  CANCEL: 'CANCEL',
  SAVE: 'EDIT',
  EDIT: 'EDIT',
  UPDATE: 'UPDATE',
  CONFIRM_CHANGE_ORG_TEXT: (currentName: string, newName: string) =>
    `Change your organization's name from ${currentName} to ${newName}`,
  CONFIRM_CHANGE_TIER_TEXT: (currentName: string, newName: string) =>
    `Change your tier's from ${currentName} to ${newName}`,
};
