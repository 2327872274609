import { TGetFilteredWebhooksParams } from '../schemas/get-filtered-webhooks.schema';
import { TGetWebhookParams } from '../schemas/get-webhook.schema';

export enum QUERY_KEY {
  WEBHOOKS = 'webhooks',
  WEBHOOK = 'webhook',
  VENDOR_API_KEY = 'vendorApiKey',
}

export const queryKey = {
  VENDOR_API_KEY: () => [QUERY_KEY.VENDOR_API_KEY],
  FILTERED_WEBHOOKS: (filterParams?: Partial<TGetFilteredWebhooksParams>) =>
    filterParams ? [QUERY_KEY.WEBHOOKS, filterParams] : [QUERY_KEY.WEBHOOKS],
  WEBHOOK: (params?: TGetWebhookParams) => [QUERY_KEY.WEBHOOK, params],
};
