import { useGetGateway, useUpdateRegisteredGateway } from '@marlin/asset/data-access/gateway';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import {
  GATEWAY_REGISTRATION_ERROR,
  TUpsertGatewayForm,
  getRegistrationErrorCode,
} from '@marlin/shared/utils/gateway-utils';
import { useCallback, useMemo, useState } from 'react';

import { content } from '../content';

type TProps = {
  gatewayId: string;
  onSuccess: () => void;
};

export const useUpdateGateway = ({ gatewayId, onSuccess }: TProps) => {
  const gatewayQuery = useGetGateway({ gatewayId });
  const gateway = useMemo(() => gatewayQuery?.data, [gatewayQuery?.data]);
  const [registrationErrorCode, setRegistrationErrorCode] = useState<GATEWAY_REGISTRATION_ERROR | null>(null);

  const isLoading = useMemo(() => gatewayQuery.isFetching, [gatewayQuery.isFetching]);

  const isError = useMemo(() => gatewayQuery.isError, [gatewayQuery.isError]);

  const updateRegisteredGatewayMutation = useUpdateRegisteredGateway();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formValues: TUpsertGatewayForm) => {
      try {
        await updateRegisteredGatewayMutation.mutateAsync({
          data: {
            name: formValues.name,
            locationId: formValues.locationId,
            description: formValues.description,
            commissionDate: formValues.commissionDate,
          },
          gatewayId,
        });

        enqueueSnackbar(content.UPDATE_SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });

        onSuccess();
      } catch (error) {
        const registrationErrorCode = getRegistrationErrorCode(error);

        if (registrationErrorCode) {
          setRegistrationErrorCode(registrationErrorCode);
        } else {
          enqueueSnackbar(content.CREATE_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      }
    },
    [enqueueSnackbar, gatewayId, onSuccess, updateRegisteredGatewayMutation]
  );

  return {
    gateway,
    isLoading,
    onSubmit,
    gatewayId,
    registrationErrorCode,
    isError,
  };
};
