import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chatWarning: {
    fontSize: theme.typography.pxToRem(10),
    textAlign: 'left',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const ChartWarning = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();

  return (
    <Typography variant="body1" className={classes.chatWarning}>
      {children}
    </Typography>
  );
};
