import { SLIDE, TEASER_MODAL_ACTION_TYPE, TeaserButton, useTeaserModal } from '@marlin/account/feature/teaser';
import { CollapsableLogo } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { useAuth } from '@marlin/shared/utils-auth';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Drawer } from '@mui/material';
import { FC, PropsWithChildren, useEffect } from 'react';

import { Logout } from './components/logout.component';
import { NavLinks } from './components/nav-links.component';
import { content } from './content';
import { useStyles } from './side-nav.component.styles';
import { TSideNavElement } from './types';

interface ISideNavProps {
  isCollapsed: boolean;
  topNavLinks: TSideNavElement[];
  bottomNavLinks: TSideNavElement[];
  app?: 'marlin' | 'portfolio' | 'support';
}

export const SideNav: FC<PropsWithChildren<ISideNavProps>> = ({
  children,
  isCollapsed,
  topNavLinks,
  bottomNavLinks,
  app,
}) => {
  const { modalDispatch } = useTeaserModal();
  const allowSystemMap = usePermission(PERMISSIONS.SYSTEM_MAP);
  const {
    handleExpandDesktopNavbar,
    handleCollapseDesktopNavbar,
    desktopNavbarLocked,
    desktopNavbarHidden,
    handleShowDesktopNavbar,
  } = useDesktopSideNavbarCollapse();
  const { classes } = useStyles({ isCollapsed, desktopNavbarLocked, desktopNavbarHidden });

  useEffect(() => {
    handleCollapseDesktopNavbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpgradeNexa = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL,
      payload: {
        slide: SLIDE.SYSTEM_MAP,
      },
    });
  };

  useEffect(() => {
    const handleBackClick = () => {
      if (desktopNavbarHidden) {
        handleShowDesktopNavbar();
      }
    };
    window.addEventListener('popstate', handleBackClick);

    return () => {
      window.removeEventListener('popstate', handleBackClick);
    };
  }, [desktopNavbarHidden, handleShowDesktopNavbar]);

  const { logOut } = useAuth();

  return (
    <Drawer
      onMouseLeave={handleCollapseDesktopNavbar}
      onMouseEnter={handleExpandDesktopNavbar}
      className={classes.wrapper}
      variant="permanent"
      anchor="left"
      data-testid="side-nav-menu"
    >
      {children}
      <div className={classes.optionsWrapper}>
        <div>
          <Link to={routes.home.root}>
            <CollapsableLogo isCollapsed={isCollapsed} locked={desktopNavbarLocked} />
          </Link>
          <NavLinks isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} navLinks={topNavLinks} />
        </div>

        <div className={classes.bottomWrapper}>
          {!allowSystemMap && app === 'marlin' && (
            <div className={classes.upgradeNexaBtn}>
              <TeaserButton
                onClick={handleUpgradeNexa}
                variant={isCollapsed && !desktopNavbarLocked ? 'collapsed' : 'main'}
                text={isCollapsed && !desktopNavbarLocked ? '' : content.UPGRADE_NEXA}
              />
            </div>
          )}
          <NavLinks isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} navLinks={bottomNavLinks} />
          <Logout isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} logOut={logOut} />
        </div>
      </div>
    </Drawer>
  );
};
