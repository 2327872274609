import { TLogicalOperator, TOperands, TOperation, TRule } from '@marlin/alert/data-access/automated-action';
import { useObservableState } from 'observable-hooks';
import { useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

type TTrigger = TOperation | TRule;

const isOperation = (state: TTrigger): state is TOperation => 'operands' in state;

const addElementToParent = (rule: TTrigger, parentId: string, state?: TTrigger): TTrigger => {
  if (!state) {
    return rule;
  }

  if (state.id === parentId) {
    return {
      ...state,
      operands: [...state.operands, rule],
    };
  }

  if (isOperation(state)) {
    return {
      ...state,
      operands: state.operands.map((operand: TOperands) => addElementToParent(rule, parentId, operand)),
    };
  }

  return state;
};

const getParent = (parentId: string, state: TTrigger): TTrigger => {
  if (state.id === parentId) {
    return state;
  }

  if (isOperation(state)) {
    return state.operands.map((operand: TOperands) => getParent(parentId, operand)).find(Boolean);
  }

  return state;
};

const rulesStore$ = new BehaviorSubject<TTrigger>(undefined);

export const useRulesStore$ = () => {
  const [currentState] = useObservableState<TTrigger>(() => rulesStore$);

  const generateId = useCallback(
    (parentId?: string) => {
      if (!parentId) {
        return `rule-${currentState?.operands?.length ?? 0}`;
      }
      const parent = getParent(parentId, currentState);
      return `${parentId}-${parent.operands?.length ?? 0}`;
    },
    [currentState]
  );

  const addRule = useCallback(
    (rule: TRule, parentId?: string | null) => {
      if (!parentId) {
        rulesStore$.next(rule);
        return;
      }

      const newState = addElementToParent(rule, parentId, currentState);

      rulesStore$.next(newState);
    },
    [currentState]
  );

  const addOperator = useCallback(
    (operator: TLogicalOperator, id: string, parentId?: string) => {
      const newOperation: TOperation = {
        id,
        logicalOperator: operator,
        operands: [],
      };
      if (!parentId) {
        rulesStore$.next(newOperation);
        return;
      }
      const newState = addElementToParent(newOperation, parentId, currentState);
      rulesStore$.next(newState);
    },
    [currentState]
  );

  return { rulesState: currentState, generateId, addRule, addOperator };
};
