import { MarlinTheme } from '@marlin/shared/theme';
import { Icon, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useOverrideMode } from '../../../../shared/hooks/tekmar-ssc/use-override-mode.hook';
import { content } from '../../../content';

interface IStyles {
  overrideValue?: string;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { overrideValue }) => ({
  wrapper: {
    background: getBackgroundColor(overrideValue, theme),
    height: theme.typography.pxToRem(48),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: theme.typography.pxToRem(16),
      height: 'fit-content',
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: theme.typography.pxToRem(0),
    },
  },
  error: {
    color: getColor(overrideValue, theme),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlWrapper: {
    display: 'flex',
  },
  icon: {
    height: theme.typography.pxToRem(22),
    marginRight: theme.typography.pxToRem(8),
    color: getColor(overrideValue, theme),
    [theme.breakpoints.between('md', 'lg')]: {
      height: theme.typography.pxToRem(16),
    },
  },
  typography: {
    marginLeft: theme.typography.pxToRem(16),
    lineHeight: 'normal',
  },
}));

const useValueControlStyles = makeStyles()((theme: MarlinTheme) => ({
  control: {
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.typography.pxToRem(8),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(12),
      paddingLeft: theme.typography.pxToRem(6),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: 0,
    },
  },
  label: {
    padding: theme.typography.pxToRem(2),
  },
  value: {
    padding: theme.typography.pxToRem(2),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface IValueControl {
  label: string;
  value: string;
}

const getBackgroundColor = (overrideModeValue: string | undefined, theme: MarlinTheme) => {
  switch (overrideModeValue?.toLowerCase()) {
    case 'heaton':
      return alpha(theme.palette.warning.main, 0.12);
    case 'establishingsteamtime':
      return alpha(theme.palette.primary.main, 0.08);
    case 'heatoff':
      return theme.palette.background.alternative;
    default:
      return alpha(theme.palette.warning.main, 0.12);
  }
};

const getColor = (overrideModeValue: string | undefined, theme: MarlinTheme) => {
  switch (overrideModeValue?.toLowerCase()) {
    case 'heaton':
      return theme.palette.warning.dark;
    case 'establishingsteamtime':
      return theme.palette.primary.main;
    case 'heatoff':
      return alpha(theme.palette.action.active, 0.54);
    default:
      return alpha(theme.palette.action.active, 0.54);
  }
};

const override = new Map([
  ['heaton', { label: content.HEAT_ON, icon: 'mode_heat' }],
  ['establishingsteamtime', { label: content.ESTABLISHING_STEAM_TIME, icon: 'mode_heat' }],
  ['heatoff', { label: content.HEAT_OFF, icon: 'mode_heat_off' }],
]);

export const ValueControl = ({ label, value }: IValueControl) => {
  const { classes } = useValueControlStyles();
  return (
    <div className={classes.control}>
      <span className={classes.label}>{label}:</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

export const OverrideModeBanner = () => {
  const { remainingTime, shouldDisplayBanner, overrideValue } = useOverrideMode();
  const { label, icon } = override.get(overrideValue?.toLowerCase() ?? '') || { label: '', icon: '' };
  const { classes } = useStyles({ overrideValue });

  if (!shouldDisplayBanner) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.controls}>
        <div className={classes.errorWrapper} data-testid="override-mode-banner">
          <Icon
            baseClassName="material-symbols-outlined"
            className={classes.icon}
            data-testid="override-mode-banner-icon"
          >
            {icon}
          </Icon>
          <span className={classes.error}>{label}</span>
        </div>
        <div className={classes.controlWrapper} data-testid="override-mode-banner-values">
          <ValueControl label={content.REMAINING_TIME} value={remainingTime} />
        </div>
      </div>
    </div>
  );
};
