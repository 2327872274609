import { Autocomplete, IFormFieldChildrenProps } from '@marlin/shared/ui-form-common';
import { Chip } from '@marlin/shared/ui/chips';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { content } from './content';
import { IOption, ISidebarFormModel, emptyOption, options } from './types';

export const SystemTagSelect = ({ onChange, value }: IFormFieldChildrenProps) => {
  const { trigger } = useFormContext<ISidebarFormModel>();

  const handleSelectChange = useCallback(
    (selectedOptions: IOption[] | null) => {
      if (selectedOptions === null || selectedOptions.length === 0) {
        onChange && onChange('');
        trigger('systemTag');
      } else {
        onChange && onChange(selectedOptions[selectedOptions.length - 1].id);
        trigger('systemTag');
      }
    },
    [onChange, trigger]
  );

  return (
    <Autocomplete
      label={content.SYSTEM_TAG_LABEL}
      options={Object.values(options)}
      value={[options[value] ?? emptyOption]}
      onChange={handleSelectChange}
      useObjects
      size="small"
      disableClearable={false}
      renderTags={(value: IOption[]) =>
        value[0].id === '' ? null : (
          <Chip label={value[0].name} onDelete={() => onChange && handleSelectChange(null)} size="small" />
        )
      }
    />
  );
};
