import { MarlinTheme } from '@marlin/shared/theme';
import {
  Autocomplete as AutocompleteMui,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { FC, ReactNode, SyntheticEvent, useCallback } from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { Input } from '../input';
import { useFiltering } from './use-filtering.hook';

export interface IOption {
  id: string;
  name: string;
  Icon?: FC<SvgIconProps>;
}

interface IBaseAutocompleteProps<T extends IOption>
  extends Omit<Partial<AutocompleteProps<T, boolean, boolean, boolean>>, 'onChange'> {
  label: string;
  testid?: string;
  handleInputChange?:
    | ((event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => void)
    | undefined;

  useObjects?: boolean;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: T) => ReactNode;
  options: T[];
  onChange?: ControllerRenderProps['onChange'];
  onOpen?: () => void;
  required?: boolean;
  error?: FieldError;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    color: theme.palette.action.active,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(16)}`,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    '&> .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      backgroundColor: theme.palette.background.primary,
      height: '100%',
      width: '100%',
    },
  },
}));

export function Autocomplete<T extends IOption>({
  className,
  options,
  value,
  testid,
  onChange,
  onOpen,
  label,
  useObjects,
  classes: propClasses,
  onScroll,
  handleInputChange,
  loading,
  inputValue,
  freeSolo,
  fullWidth,
  renderOption,
  multiple = true,
  autoSelect = true,
  disabled = false,
  size,
  renderGroup,
  groupBy,
  required,
  onBlur,
  error,
  getOptionKey,
  disableClearable = true,
  renderTags,
}: IBaseAutocompleteProps<T>) {
  const { classes } = useStyles();
  const { values, filterOptions } = useFiltering<T>(options, value || null, useObjects);

  const handleChange = useCallback(
    // TODO: fix in the future
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: SyntheticEvent, value: any, reason: AutocompleteChangeReason) => {
      if (multiple && !useObjects) {
        const optionIds = value.map((option: IOption) => {
          return option.id;
        });
        onChange && onChange(optionIds);
        return;
      }

      onChange && onChange(value);
    },
    [multiple, onChange, useObjects]
  );

  return (
    <AutocompleteMui<T, boolean, boolean, boolean>
      multiple={multiple}
      className={className}
      value={multiple ? values : value}
      inputValue={inputValue}
      data-testid={testid}
      options={options}
      filterOptions={filterOptions}
      disableClearable={disableClearable}
      getOptionLabel={(option: T | string) => (typeof option === 'string' ? option : option.name)}
      renderOption={(props, option: T, { index }) => {
        if (renderOption) {
          return renderOption(props, option);
        }
        if (index === options.length - 1 && loading) {
          return (
            <li key="loader" className={props.className}>
              {content.LOADING_LABEL}
            </li>
          );
        }
        if (option.Icon) {
          return (
            <li {...props} key={option.id} data-testid={`filter-option-${option.id}`} className={classes.iconContainer}>
              <div className={classes.iconWrapper}>
                <option.Icon className={classes.icon} />
              </div>
              <div>
                <Typography textAlign="left"> {option.name} </Typography>
              </div>
            </li>
          );
        }
        return (
          <li
            {...props}
            key={option.id}
            data-testid={`filter-option-${option.id}`}
            style={{ minHeight: 36 }} // note: class does not work here
          >
            {option.name}
          </li>
        );
      }}
      classes={propClasses}
      componentsProps={{
        paper: {
          onScroll,
        },
      }}
      filterSelectedOptions
      onChange={handleChange}
      onOpen={onOpen}
      onInputChange={handleInputChange}
      renderTags={renderTags ?? (() => null)}
      onBlur={onBlur}
      renderInput={(params) => (
        <Input
          className={fullWidth ? undefined : classes.input}
          label={label}
          {...params}
          testId="filter"
          required={required}
          error={error}
        />
      )}
      isOptionEqualToValue={(option: T, value: T) => option.id === value.id}
      loading={loading}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      forcePopupIcon
      autoSelect={autoSelect}
      blurOnSelect
      disabled={disabled}
      size={size}
      renderGroup={renderGroup}
      groupBy={groupBy}
      getOptionKey={getOptionKey}
    />
  );
}
