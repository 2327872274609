import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import { IMessage } from '../types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  myMessage: {
    borderRadius: theme.typography.pxToRem(32),
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)} `,
    width: 'fit-content',
    maxWidth: '80%',
    alignSelf: 'flex-end',
    fontSize: theme.typography.pxToRem(14),
  },
}));

export const MyMessage = ({ message }: IMessage) => {
  const { classes } = useStyles();

  return <div className={classes.myMessage}>{message}</div>;
};
