import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sidebar: {
    width: '100%',
    minWidth: theme.typography.pxToRem(300),
    maxWidth: theme.typography.pxToRem(712),
    height: 'fit-content',
  },
  section: {
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24),
  },
  header: {
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
  },
}));
