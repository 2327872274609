import { firstNameZodSchema, jobTitleZodSchema, lastNameZodSchema, phoneZodSchema } from '@marlin/shared/ui-form';
import { z } from 'zod';

export const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  title: '',
  email: '',
};

export const BillingContactSchema = z.object({
  firstName: firstNameZodSchema,
  lastName: lastNameZodSchema,
  phone: phoneZodSchema,
  title: jobTitleZodSchema.nullish(),
  email: z.string().email(),
});

export type TBillingContact = z.infer<typeof BillingContactSchema>;
