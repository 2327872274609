import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  isMechanicalRoomLabel: {
    marginBottom: theme.typography.pxToRem(32),
  },

  zipcode: {
    marginBottom: theme.typography.pxToRem(0),
    width: theme.typography.pxToRem(319),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.typography.pxToRem(32),
    },
  },

  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.typography.pxToRem(8),
    width: '100%',
    maxWidth: theme.typography.pxToRem(712),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      columnGap: 0,
      marginBottom: theme.typography.pxToRem(8),
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },

  button: {
    width: '100%',
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(24),
    maxWidth: theme.typography.pxToRem(712),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.typography.pxToRem(16),
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
    },
  },

  formSection: {
    marginBottom: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },

  description: {
    marginTop: theme.typography.pxToRem(16),
  },

  input: {
    marginBottom: theme.typography.pxToRem(32),
  },

  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },

  deviceType: {
    width: theme.typography.pxToRem(324),
    marginBottom: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  select: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(32),
  },

  selectMargins: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(32),
  },

  selectItem: {
    height: theme.typography.pxToRem(36),
  },

  formControl: {
    marginBottom: theme.typography.pxToRem(32),
  },

  pinDevice: {
    marginBottom: theme.typography.pxToRem(32),
  },

  attachmentUpdateValidation: {
    marginTop: theme.typography.pxToRem(8),
  },

  required: {
    color: theme.palette.error.main,
  },

  plantWarning: {
    marginBottom: theme.typography.pxToRem(16),
    display: 'flex',
  },

  iconWrapper: {
    marginLeft: theme.typography.pxToRem(8),
    color: theme.palette.primary.main,
    paddingTop: theme.typography.pxToRem(2),
    '& span': {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
  },
}));
