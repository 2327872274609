import { TDatapointSchema } from '@marlin/asset/data-access/equipment';
import { sbcImage } from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { makeStyles } from 'tss-react/mui';

import boiler from './img/boiler.png';
import pump from './img/pump.png';
import sensor from './img/sensor.png';
import tank from './img/tank.png';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(8),
  },
  image: {
    marginRight: theme.typography.pxToRem(8),
    padding: theme.typography.pxToRem(8),
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface IDatapointComponent {
  datapoints: TDatapointSchema[];
  name: string;
}

const Img = ({ type }: { type: string }) => {
  if (type.includes('Pump')) {
    return <img alt="pump" src={pump} width={50} height={80} />;
  }
  if (type.includes('Boiler')) {
    return <img alt="boiler" src={boiler} width={50} height={80} />;
  }
  if (type.includes('Sensor')) {
    return <img alt="sensor" src={sensor} width={50} height={80} />;
  }
  if (type.includes('Tank')) {
    return <img alt="tank" src={tank} width={50} height={80} />;
  }

  return <img alt="sbs" src={sbcImage} width={50} height={50} />;
};

export const DatapointComponent = ({ name, datapoints }: IDatapointComponent) => {
  const { classes } = useStyles();

  return (
    <Paper testId="card" className={classes.paper}>
      <div className={classes.label}>{name}</div>
      <div className={classes.wrapper}>
        <div className={classes.image}>
          <Img type={name} />
        </div>
        <div>
          {datapoints.map((item) => (
            <div>
              {item.name}:{' '}
              <span className={classes.value}>
                {item.value}
                {item.uom}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Paper>
  );
};
