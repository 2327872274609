import { TWebhookApiHeader } from '@marlin/account-data-access-webhook';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

import { content } from '../content';
import { HeaderRow } from './header-row.component';
import { useStyles } from './headers.styles';

interface IHeadersProps {
  onHideClick: () => void;
  headers: TWebhookApiHeader[];
}

export const Headers = ({ onHideClick, headers }: IHeadersProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Divider />
      <div className={classes.topSection}>
        <Typography className={classes.headerText}>{content.HEADER_KEY_VALUES}</Typography>
        <Button onClick={onHideClick} endIcon={<CloseIcon className={classes.icon} />}>
          {content.HIDE}
        </Button>
      </div>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        padding={2}
        mt={-4}
        flexDirection="row"
        alignContent="center"
        alignItems="center"
      >
        {headers.map(({ name, value }, index) => (
          <HeaderRow keyHeader={name} keyValue={value} index={index + 1} />
        ))}
      </Grid>
    </div>
  );
};
