import { zodResolver } from '@hookform/resolvers/zod';
import { TCalibrationParamsSchema } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { CalibrationFormButtons } from './calibration-forms/form-buttons.component';
import { ITab, TTabKey, tabsConfig } from './calibration-forms/tabs.config';
import { CalibrationManualFormSchema, CalibrationMeasurementFormSchema } from './types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tabsContainer: {
    marginLeft: `-${theme.typography.pxToRem(24)}`,
    marginRight: `-${theme.typography.pxToRem(24)}`,
  },
  tabsContent: {
    padding: theme.typography.pxToRem(24),
    paddingBottom: 0,
    paddingTop: theme.typography.pxToRem(16),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface ICalibrationModalBodyProps {
  defaultValues?: TCalibrationParamsSchema;
  manufacturerId: string;
  timezoneName: string;
  onConfirm: (calibration: TCalibrationParamsSchema) => void;
  onCancel: () => void;
  onSaveForLater: (calibration: TCalibrationParamsSchema, subtypeId: string, manufacturerId: string) => void;
}

const emptyDefaultValues: TCalibrationParamsSchema = {
  startMeasurementValue: null,
  startMeasurementTimestamp: null,
  endMeasurementValue: null,
  endMeasurementTimestamp: null,
  unitsPerPulse: null,
  measurementUnit: 'ft3',
};

export const CalibrationModalBody = ({
  defaultValues = emptyDefaultValues,
  manufacturerId,
  onConfirm,
  onCancel,
  timezoneName,
  onSaveForLater,
}: ICalibrationModalBodyProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { classes } = useStyles();
  const [tab, setTab] = useState<TTabKey>('METER_READINGS');

  const form = useForm<TCalibrationParamsSchema>({
    mode: 'onTouched',
    resolver: zodResolver(tab === 'METER_READINGS' ? CalibrationMeasurementFormSchema : CalibrationManualFormSchema),
    defaultValues: defaultValues,
  });

  const handleTabChange = useCallback(
    (event: SyntheticEvent, value: TTabKey) => {
      setTab(value);
    },
    [setTab]
  );

  const TabsContent = useMemo(() => {
    return tabsConfig[tab].component(form, timezoneName);
  }, [tab, form, timezoneName]);

  useEffect(() => {
    if (!defaultValues?.startMeasurementValue && !defaultValues?.endMeasurementValue && defaultValues?.unitsPerPulse) {
      setTab('MANUAL');
    }
  }, [defaultValues?.endMeasurementValue, defaultValues?.startMeasurementValue, defaultValues?.unitsPerPulse]);

  return (
    <div data-testid="calibration-modal-body" className={classes.tabsContainer}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        data-testid="calibration-tabs"
        variant={isMobile ? 'scrollable' : 'fullWidth'}
        className={classes.tabs}
      >
        {Object.values(tabsConfig).map((tab: ITab) => (
          <Tab key={tab.key} label={tab.label} value={tab.key} data-testid={`calibration-tab-${tab.label}`} />
        ))}
      </Tabs>
      <div className={classes.tabsContent}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onConfirm)}>
            {TabsContent}
            <CalibrationFormButtons
              onConfirm={form.handleSubmit(onConfirm)}
              onSaveForLater={() => onSaveForLater(form.getValues(), '', manufacturerId)}
              onCancel={onCancel}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
