import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  GetOrganizationSettingsSchema,
  OrganizationSettingsSchema,
  TGetOrganizationSettings,
  TOrganizationSettings,
  TUpdateOrganizationSettings,
  UpdateOrganizationSettingsParamsSchema,
} from '../schemas/settings';
import { paths } from './api';

export const getOrganizationSettings = async (): Promise<TGetOrganizationSettings> => {
  const res = await getHttpClient().get<unknown, TGetOrganizationSettings>(paths.ORGANIZATION_SETTINGS);
  return safeParseData(res, GetOrganizationSettingsSchema);
};

export const getOrganizationSettingsById = async ({ orgId }: { orgId: string }): Promise<TGetOrganizationSettings> => {
  const res = await getHttpClient().get<unknown, TGetOrganizationSettings>(paths.ORGANIZATION_SETTINGS_BY_ID, {
    params: { orgId },
  });
  return safeParseData(res, GetOrganizationSettingsSchema);
};

export const updateOrganizationSettings = async (data: TUpdateOrganizationSettings): Promise<TOrganizationSettings> => {
  const body = safeParseData(data, UpdateOrganizationSettingsParamsSchema);
  const res = await getHttpClient().put(paths.ORGANIZATION_SETTINGS, body);
  return safeParseData(res, OrganizationSettingsSchema);
};
