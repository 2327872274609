import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import isString from 'lodash/isString';
import { isValidElement } from 'react';
import { makeStyles } from 'tss-react/mui';

interface ISubtitleProps {
  subtitle?: string | JSX.Element;
  prefix: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  subtitle: {
    wordBreak: 'break-all',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
    },
  },
}));

export const Subtitle = ({ subtitle, prefix }: ISubtitleProps) => {
  const { classes } = useStyles();

  if (isString(subtitle)) {
    return (
      <Typography data-testid={`${prefix}-subtitle`} variant="subtitle1" textAlign="left" className={classes.subtitle}>
        {subtitle.endsWith('.') ? subtitle : `${subtitle}.`}
      </Typography>
    );
  } else if (isValidElement(subtitle)) {
    return subtitle;
  }

  return null;
};
