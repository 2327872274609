import { Button } from '@mui/material';

import { content } from './content';
import { useStyles } from './modal.styles';

interface IModalFooterProps {
  onGenerateNewKey: () => void;
  onCancel: () => void;
}

export const ModalFooter = ({ onGenerateNewKey, onCancel }: IModalFooterProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Button className={classes.button} variant="text" type="submit" data-testid="submit" onClick={onCancel}>
        {content.CANCEL}
      </Button>
      <Button className={classes.button} variant="contained" onClick={onGenerateNewKey} data-testid="cancel">
        {content.GENERATE}
      </Button>
    </div>
  );
};
