import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

import { organizationStorageService } from './organization-storage.service';

export interface IHttpErrorResponse {
  response: {
    status: number;
  };
}

export interface IHttpRequest extends InternalAxiosRequestConfig<unknown> {
  headers: AxiosRequestHeaders;
}

export interface IHttpInterceptor {
  intercept(config: IHttpRequest): Promise<IHttpRequest>;
  handleError(error: IHttpErrorResponse, logoutTimer: boolean): Promise<IHttpErrorResponse>;
}

interface IUser {
  organizationId: string;
}

interface IUserService {
  getUser(): IUser | null;
}

export class OrganizationInterceptor implements IHttpInterceptor {
  public constructor(private userService: IUserService) {}

  public intercept(request: IHttpRequest): Promise<IHttpRequest> {
    const user = this.userService.getUser();
    return Promise.resolve(this.handleRequest(request, user));
  }

  public handleError = (error: IHttpErrorResponse): Promise<IHttpErrorResponse> => {
    return Promise.reject(error);
  };

  private handleRequest = (request: IHttpRequest, user: IUser | null): IHttpRequest => {
    return { ...request, headers: this.getHeaders(request, user) };
  };

  private getHeaders = (request: IHttpRequest, user: IUser | null): AxiosRequestHeaders => {
    if (!user) {
      return request.headers;
    }

    const organizationId = user.organizationId;

    this.saveHeaderState(organizationId);

    request.headers.set('organizationId', request.headers['organizationId'] ?? organizationId);

    return request.headers;
  };

  private saveHeaderState = (organizationId: string): void => {
    organizationStorageService.set(organizationId);
  };
}
