import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateOrganizationDetailsSupport } from '../infrastructure/update-org-details';
import { TOrgDetails, TOrgDetailsParams } from '../schemas/org';
import { queryKey } from './query-key.enum';

export const useSupportUpdateOrganizationDetails = (params?: TOrgDetailsParams) => {
  return useMutation({
    mutationFn: updateOrganizationDetailsSupport,

    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: queryKey.ORGANIZATION_DETAILS(params) });
      const previousOrgData: TOrgDetails | undefined = queryClient.getQueryData<TOrgDetails>(
        queryKey.ORGANIZATION_DETAILS(params)
      );

      const optimisticOrganizationData: TOrgDetails | undefined = previousOrgData
        ? {
            ...previousOrgData,
            customerOrganization: !!data.customerOrganization,
          }
        : undefined;

      queryClient.setQueryData(queryKey.ORGANIZATION_DETAILS(params), optimisticOrganizationData);
      return { previousOrgData };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey.ORGANIZATION_DETAILS(params), context?.previousOrgData);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.ORGANIZATION_DETAILS(params) });
      queryClient.invalidateQueries({ queryKey: queryKey.CURRENT_USER() });
    },
  });
};
