import {
  OrganizationInterceptor,
  organizationStorageService,
  userFacade,
} from '@marlin/account-data-access-organization';
import { multiChartStorageService } from '@marlin/asset/feature/multi-charts';
import { environment } from '@marlin/environment';
import { portfolioStorageService } from '@marlin/portfolio-data-access-portfolio';
import { StorageService, createAuthInterceptor, getAuthService, init as initAuth } from '@marlin/shared/utils-auth';
import { init as initRealtimeCommunication } from '@marlin/shared/utils-realtime-communication';
import { initHistory } from '@marlin/shared/utils-router';
import { setupLogger } from '@marlin/shared/utils/logger';
import { initHttpClient } from '@marlin/shared/utils/react-query';
import { supportStorageService } from '@marlin/support-data-access-support';

setupLogger();

// TODO: move it to common config lib for marlin, portfolio and support
const persistentLocationState = {
  portfolio: '/portfolio',
  support: '/support',
};

initHistory(environment.baseUrl);
initAuth(
  environment.module.authorization,
  new StorageService([
    portfolioStorageService,
    organizationStorageService,
    supportStorageService,
    multiChartStorageService,
  ]),
  persistentLocationState
).then(() => {
  initHttpClient(environment.module.http, [createAuthInterceptor(), new OrganizationInterceptor(userFacade)]);
  initRealtimeCommunication(environment.module.realtimeCommunication, getAuthService());
});
