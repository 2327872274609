import { MarlinTheme } from '@marlin/shared/theme';
import { Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { makeStyles } from 'tss-react/mui';

import { IMessage } from '../types';

interface IChatMessage extends IMessage {
  scrollToBottom?: () => void;
  shouldAnimate?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chatMessage: {
    maxWidth: '100%',
    display: 'flex',
    gap: theme.typography.pxToRem(12),
    fontSize: theme.typography.pxToRem(14),
  },
  chatFrom: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(8),
  },
}));

const animateString = (str: string, setAnimatedMessage: (msg: string) => void, scrollBottom) => {
  let index = 0;
  const interval = setInterval(() => {
    setAnimatedMessage(str.slice(0, index + 1));
    index++;
    scrollBottom();
    if (index === str.length) {
      clearInterval(interval);
    }
  }, 14);
  return interval;
};

export const ChatMessage = ({ message, from, avatarUrl, scrollToBottom, shouldAnimate }: IChatMessage) => {
  const { classes } = useStyles();
  const [animatedMessage, setAnimatedMessage] = useState('');

  useEffect(() => {
    let interval;

    if (shouldAnimate) {
      interval = animateString(message, setAnimatedMessage, scrollToBottom);
    } else {
      setAnimatedMessage(message);
      scrollToBottom?.();
    }
    return () => clearInterval(interval);
  }, [message, scrollToBottom, shouldAnimate]);

  return (
    <div className={classes.chatMessage}>
      <div>
        <Avatar alt={from} sx={{ width: 24, height: 24 }} src={avatarUrl} />
      </div>
      <div>
        <div className={classes.chatFrom}>{from}</div>
        <div>
          <ReactMarkdown children={animatedMessage} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
    </div>
  );
};
