import { content } from './content';
import { aercoDatapointLabelsMap } from './datapoint-mappers-aerco.config';
import { intellistationDatapointLabelsMap } from './datapoint-mappers-intellistation.config';

function convertKeysToLowerCase(map: Map<string, string>): Map<string, string> {
  const newMap = new Map();
  for (const [key, value] of map) {
    newMap.set(key.toLowerCase(), value);
  }
  return newMap;
}

export const datapointsLabelsMap = new Map([
  ...convertKeysToLowerCase(intellistationDatapointLabelsMap),
  ...convertKeysToLowerCase(aercoDatapointLabelsMap),
]);

export const datapointsOptionsLabelsMap = new Map([
  ['on', content.DATAPOINT_OPTIONS_LABELS.ON],
  ['off', content.DATAPOINT_OPTIONS_LABELS.OFF],
  ['indoor', content.DATAPOINT_OPTIONS_LABELS.INDOOR],
  ['flue', content.DATAPOINT_OPTIONS_LABELS.FLUE],
  ['open', content.DATAPOINT_OPTIONS_LABELS.OPEN],
  ['closed', content.DATAPOINT_OPTIONS_LABELS.CLOSED],
  ['opening', content.DATAPOINT_OPTIONS_LABELS.OPENING],
  ['closing', content.DATAPOINT_OPTIONS_LABELS.CLOSING],
  ['auto', content.DATAPOINT_OPTIONS_LABELS.AUTO],
  ['error', content.DATAPOINT_ERROR_LABEL],
  ['notUsed', content.DATAPOINT_OPTIONS_LABELS.NOT_USED],
]);

export const datapointsStatusLabelsMap = new Map([
  ['noDemand', content.EMPTY_DATAPOINT_VALUE],
  ['absent', content.EMPTY_DATAPOINT_VALUE],
  ['error', content.DATAPOINT_ERROR_LABEL],
  ['overrideOff', content.STATUS.OVERRIDE_OFF],
  ['overrideHand', content.STATUS.OVERRIDE_HAND],
  ['overrideMaxHeat', content.STATUS.OVERRIDE_MAX_HEAT],
  ['noHeatAbortCutoff', content.STATUS.NO_HEAT_ABORT_CUTOFF],
  ['noHeatNormal', content.STATUS.NO_HEAT_NORMAL],
  ['wwsd', content.STATUS.WWSD],
  ['coolDown', content.STATUS.COOL_DOWN],
  ['heatOff', content.STATUS.HEAT_OFF],
  ['heatOn', content.STATUS.HEAT_ON],
  ['establishingSteamTime', content.STATUS.ESTABLISHING_STEAM_TIME],
  ['establishingSteamTemp', content.STATUS.ESTABLISHING_STEAM_TEMP],
  ['invisible', content.STATUS.INVISIBLE],
  ['on', content.STATUS.ON],
  ['off', content.STATUS.OFF],
]);
