import { z } from 'zod';

const DatapointSchema = z.object({
  name: z.string(),
  originalName: z.string().nullable().optional(),
  uom: z.string().nullable(),
  value: z.string(),
});

export type TDatapointSchema = z.infer<typeof DatapointSchema>;

export const EquipmentFlow = z
  .object({
    components: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        index: z.string().nullable(),
        datapoints: z.array(DatapointSchema),
      })
    ),
    relationships: z.array(
      z.object({
        name: z.string(),
        source: z.object({
          component: z.string(),
          id: z.string(),
        }),
        target: z.object({
          component: z.string(),
          id: z.string(),
        }),
      })
    ),
  })
  .transform(({ components, relationships }) => {
    const graph = new Map<string, Set<string>>();
    const incomingEdges = new Map<string, number>();

    components.forEach((component) => {
      graph.set(component.id, new Set());
      incomingEdges.set(component.id, 0);
    });

    relationships.forEach((relationship) => {
      graph.get(relationship.source.id)?.add(relationship.target.id);
      incomingEdges.set(relationship.target.id, (incomingEdges.get(relationship.target.id) || 0) + 1);
    });

    const levels = new Map<string, number>();
    const queue: string[] = [];

    incomingEdges.forEach((count, id) => {
      if (count === 0) {
        queue.push(id);
        levels.set(id, 0);
      }
    });

    while (queue.length > 0) {
      const current = queue.shift()!;
      const currentLevel = levels.get(current) || 0;

      graph.get(current)?.forEach((neighbor) => {
        const remainingEdges = (incomingEdges.get(neighbor) || 1) - 1;
        incomingEdges.set(neighbor, remainingEdges);

        if (remainingEdges === 0) {
          queue.push(neighbor);
          levels.set(neighbor, currentLevel + 1);
        }
      });
    }

    const nodePositions = new Map<number, number>();
    const nodeChildren = new Map<string, string[]>();

    relationships.forEach(({ source, target }) => {
      if (!nodeChildren.has(source.id)) {
        nodeChildren.set(source.id, []);
      }
      nodeChildren.get(source.id)?.push(target.id);
    });

    const nodes = components.map((component) => {
      const level = levels.get(component.id) || 0;
      const x = level * 400;
      const y = (nodePositions.get(level) || 0) * 150 - 350;
      nodePositions.set(level, (nodePositions.get(level) || 0) + 1);

      return {
        id: component.id,
        position: { x, y },
        type: 'componentNode',
        name: `${component.name} ${component.index ?? ''}`,
        datapoints: component.datapoints,
      };
    });

    nodes.forEach((node) => {
      const children = nodeChildren.get(node.id);
      if (children) {
        const parentY = node.position.y;
        const totalChildren = children.length;
        const childPositions: number[] = [];
        const spacing = 200;

        for (let i = 0; i < totalChildren; i++) {
          const offset = (i - Math.floor(totalChildren / 2)) * spacing;
          childPositions.push(parentY + offset);
        }

        children.forEach((childId, index) => {
          const childNode = nodes.find((n) => n.id === childId);
          if (childNode) {
            childNode.position.y = childPositions[index];
          }
        });
      }
    });

    const edges = relationships.map((relationship, index) => ({
      id: `edge-${index}`,
      source: relationship.source.id,
      target: relationship.target.id,
      sourceHandle: 'right',
      targetHandle: 'left',
    }));

    return { nodes, edges };
  });

export type TEquipmentFlow = z.infer<typeof EquipmentFlow>;
