import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateOrganizationDetails } from '../infrastructure/update-org-details';
import { TOrgDetailsParams } from '../schemas/org';
import { queryKey } from './query-key.enum';

export const useUpdateOrganizationDetails = (params?: TOrgDetailsParams) => {
  return useMutation({
    mutationFn: updateOrganizationDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.ORGANIZATION_DETAILS(params) });
      queryClient.invalidateQueries({ queryKey: queryKey.CURRENT_USER() });
    },
  });
};
