import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  variant: 'marlin' | 'portfolio' | 'support';
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { variant }) => ({
  container: {
    width: '100%',
    paddingRight: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(16),
    backgroundColor: variant === 'support' ? theme.palette.alternative.dark : theme.palette.primary.dark,
    height: theme.typography.pxToRem(48),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.typography.pxToRem(12),
    cursor: 'pointer',
  },

  orgName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    minWidth: theme.typography.pxToRem(164),
    maxWidth: theme.typography.pxToRem(164),
  },
  hideSafariNativeTooltip: {
    '&::after': {
      content: '""',
      display: 'block',
    },
  },

  orgIcon: {
    color: theme.palette.primary.contrastText,
  },
}));
