import { routes } from '@marlin/shared/utils-routes';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import { type MouseEvent as ReactMouseEvent, useCallback, useMemo, useState } from 'react';
import { NavLink, matchPath, useLocation, useMatch } from 'react-router-dom';

import { ISideNavOption } from '../types';
import { NestedLinks } from './nested-links.components';
import { useStyles } from './side-nav-link.component.styles';

interface ISideNavLinkProps extends ISideNavOption {
  onClick?: () => void;
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
  isNested?: boolean;
}

const SideNavLink = ({
  to,
  Icon,
  title,
  onClick,
  Badge = undefined,
  nestedOptions,
  isCollapsed,
  endAdornment,
  desktopNavbarLocked,
  isNested = false,
}: ISideNavLinkProps) => {
  const { pathname } = useLocation();
  const matchNested = nestedOptions?.some((nestedOption) => !!matchPath(nestedOption.to, pathname));

  const matchHome = useMatch({ path: routes.home.root });
  const matchHomeDashboard = useMatch({ path: routes.home.dashboard });
  const matchHomeLocations = useMatch({ path: routes.home.locations });
  const [nestedOptionsOpenState, setNestedOptionOpen] = useState(matchNested);
  const { classes, cx } = useStyles();

  const nestedOptionsOpen = nestedOptionsOpenState || matchNested;
  const matchHomepage = useMemo(
    (): boolean =>
      to === routes.home.root
        ? !!matchHome?.pathname.length || !!matchHomeDashboard?.pathname.length || !!matchHomeLocations?.pathname.length
        : true,
    [to, matchHome, matchHomeDashboard, matchHomeLocations]
  );

  const isNavLinkActive = useCallback(
    (isActive: boolean) => {
      if (to === routes.home.root) {
        return matchHomepage;
      }

      if (getBasePath(to) === getBasePath(pathname)) {
        return true;
      }

      return isActive;
    },
    [matchHomepage, pathname, to]
  );

  const toggleNestedOptions = (event: ReactMouseEvent) => {
    event.preventDefault();
    setNestedOptionOpen((opt) => !opt);
  };

  return (
    <div
      className={cx(
        nestedOptionsOpen ? classes.nestedItemsContainer : isNested ? classes.nestedContainer : classes.container
      )}
    >
      <NavLink
        onClick={onClick}
        to={to}
        className={({ isActive }) => (isNavLinkActive(isActive) ? classes.activeNavLink : classes.navLink)}
        data-testid={`nav-link-${to.replace(/\//gi, '-')}`}
        end
      >
        <div
          className={cx(classes.navLinkContent, {
            [classes.navLinkContentColapsed]: isCollapsed && !desktopNavbarLocked,
          })}
        >
          {Badge ? (
            <Badge>
              <Icon color="action" />
            </Badge>
          ) : (
            <Icon color="action" />
          )}
        </div>
        {desktopNavbarLocked ? (
          <div className={classes.expandContainer}>
            <div>
              <span>{title}</span>
            </div>

            <>
              {!endAdornment && nestedOptions && (
                <IconButton onClick={toggleNestedOptions}>
                  {nestedOptionsOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </>
          </div>
        ) : (
          !isCollapsed && (
            <div className={classes.expandContainer}>
              <div>
                <span>{title}</span>
              </div>
              <>
                {!endAdornment && nestedOptions && (
                  <IconButton onClick={toggleNestedOptions}>
                    {nestedOptionsOpen ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </>
            </div>
          )
        )}
        {endAdornment}
      </NavLink>
      <div>
        {nestedOptions && nestedOptionsOpen && (
          <NestedLinks isCollapsed={isCollapsed} desktopNavbarLocked={desktopNavbarLocked} options={nestedOptions} />
        )}
      </div>
    </div>
  );
};

function getBasePath(route: string) {
  if (!route.startsWith('/')) {
    return null;
  }

  const segments = route.split('/');

  if (segments.length > 1) {
    return `/${segments[1]}`;
  }

  return '/';
}

export default SideNavLink;
