import { ICriticality, TRuleCategory } from '@marlin/alert/data-access/alert-action';
import { ALERT_STATUS_FILTER } from '@marlin/alert/data-access/alert-action';
import { TAsset } from '@marlin/alert/data-access/automated-action';
import {
  AlertList as AlertListUI,
  AlertTabs,
  AlertsFilters,
  MobileAlertList as MobileAlertListUI,
  MobileAlertTabs,
  RefreshButton,
  TAB_INDEX,
  content,
  deviceTypeOptions,
  equipmentTypeOptions,
  gatewayTypeOptions,
} from '@marlin/alert/ui/alert-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { IRequestPagination, PageContainer, PageHeader, Pagination, scrollPageToTop } from '@marlin/shared/ui-page';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useMobileAlerts } from './use-mobile-alerts.hook';
import { useStore } from './use-store.hook';
import { getTotalItems } from './utils/get-total-items';
import { mapTabsToStatus } from './utils/map-tabs-to-alert-status';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: theme.static.defaultWidth,
  },

  mobileContainer: {
    backgroundColor: theme.palette.background.primary,
  },
}));

const defaultFilterParams = (
  criticalityQueryParam?: string | null,
  deviceIdQueryParam?: string | null,
  equipmentIdQueryParam?: string | null,
  deviceNameQueryParam?: string | null,
  categoryQueryParam?: string | null,
  alertTypeQueryParam?: string | null
): IFilterParams => {
  if (
    !criticalityQueryParam &&
    !deviceIdQueryParam &&
    !equipmentIdQueryParam &&
    !deviceNameQueryParam &&
    !categoryQueryParam &&
    !alertTypeQueryParam
  ) {
    return {
      search: '',
      equipmentIds: [],
      deviceIds: [],
      locationIds: [],
      deviceTypes: [],
      criticality: {
        low: false,
        high: false,
      },
    };
  }

  let devicesId: TAsset[] = [];
  let equipmentIds: TAsset[] = [];
  const ruleCategories: TRuleCategory[] = [];
  const deviceTypes: TAsset[] = [];

  if (deviceIdQueryParam) {
    devicesId = [{ id: deviceIdQueryParam, name: deviceNameQueryParam || '' }];
  }

  if (equipmentIdQueryParam) {
    equipmentIds = [{ id: equipmentIdQueryParam, name: '' }];
  }

  const criticality = {
    low: false,
    high: false,
  };

  if (criticalityQueryParam === 'LOW') {
    criticality.low = true;
  }

  if (criticalityQueryParam === 'HIGH') {
    criticality.high = true;
  }

  if (categoryQueryParam) {
    ruleCategories.push(categoryQueryParam as TRuleCategory);
  }

  if (alertTypeQueryParam) {
    switch (alertTypeQueryParam) {
      case 'Sensor': {
        deviceTypes.push(...deviceTypeOptions);
        break;
      }
      case 'Equipment': {
        deviceTypes.push(...equipmentTypeOptions);
        break;
      }
      case 'Gateway': {
        deviceTypes.push(...gatewayTypeOptions);
        break;
      }
    }
  }

  return {
    equipmentIds,
    deviceIds: devicesId,
    locationIds: [],
    deviceTypes,
    criticality,
    ruleCategories,
  };
};

const defaultFilterMobileParams = {
  status: ALERT_STATUS_FILTER.RESOLVED,
  pageSize: 10,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
  criticality: {
    low: false,
    high: false,
  },
};

const defaultPaginationParams = { page: 1, pageSize: 10 };

export interface IFilterParams {
  locationIds: TAsset[];
  equipmentIds: TAsset[];
  deviceIds: TAsset[];
  deviceTypes: TAsset[];
  search?: string;
  criticality: ICriticality;
  ruleCategories?: TRuleCategory[];
}

export function AlertList() {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const queryParams = useQueryParameters();
  const criticalityQueryParam = queryParams.get('criticality');
  const deviceIdQueryParam = queryParams.get('alertDeviceId');
  const deviceNameQueryParam = queryParams.get('alertDeviceName');
  const equipmentIdQueryParam = queryParams.get('alertEquipmentId');
  const categoryQueryParam = queryParams.get('category');
  const alertTypeQueryParam = queryParams.get('alertType');

  const [currentTab, setCurrentTab] = useState<ALERT_STATUS_FILTER>(ALERT_STATUS_FILTER.CURRENT);
  const [displayIconColumn, setDisplayIconColumn] = useState(false);
  const [requestPagination, setRequestPagination] = useState<IRequestPagination>(defaultPaginationParams);
  const [params, setParams] = useState<IFilterParams>(
    defaultFilterParams(
      criticalityQueryParam,
      deviceIdQueryParam,
      equipmentIdQueryParam,
      deviceNameQueryParam,
      categoryQueryParam,
      alertTypeQueryParam
    )
  );

  const {
    hasNextPage,
    alerts: mobileAlerts,
    isLoading: isLoadingMobileAlerts,
    isError: isErrorMobileAlerts,
    fetchNextPage,
    refetchMobile,
  } = useMobileAlerts(isMobile, { ...defaultFilterMobileParams, status: currentTab });

  const filterParams = useMemo(
    () => ({
      ...requestPagination,
      ...params,
      search: params.search?.trim(),
    }),
    [params, requestPagination]
  );

  const { alerts, isLoading, isError, pagination, totalItems, updateStatus, refetch } = useStore(filterParams);

  const handleTabChange = useCallback(
    (tab: TAB_INDEX) => {
      if (isMobile) {
        scrollPageToTop();
      }
      setDisplayIconColumn(tab === TAB_INDEX.ALL);
      setCurrentTab(mapTabsToStatus(tab));
      updateStatus(mapTabsToStatus(tab));
      refetch();
      setRequestPagination(defaultPaginationParams);
      setParams(
        defaultFilterParams(criticalityQueryParam, deviceIdQueryParam, equipmentIdQueryParam, deviceNameQueryParam)
      );
    },
    [
      isMobile,
      updateStatus,
      refetch,
      criticalityQueryParam,
      deviceIdQueryParam,
      equipmentIdQueryParam,
      deviceNameQueryParam,
    ]
  );

  const handleRefetch = useCallback(() => {
    if (isMobile) {
      return refetchMobile();
    }
    if (requestPagination.page === 1) {
      return refetch();
    }
    return Promise.resolve(() => setRequestPagination(defaultPaginationParams));
  }, [isMobile, refetchMobile, requestPagination.page, refetch]);

  const handleParamsChange = useCallback(
    (newParams: IFilterParams) => {
      setRequestPagination({
        pageSize: requestPagination.pageSize,
        page: defaultPaginationParams.page,
      });
      setParams(newParams);
    },
    [setParams, requestPagination.pageSize, setRequestPagination]
  );

  const handlePaginationChange = useCallback(
    (requestPagination: IRequestPagination) => {
      setRequestPagination(requestPagination);
    },
    [setRequestPagination]
  );

  if (isMobile) {
    return (
      <PageContainer prefix="alerts-page" className={classes.mobileContainer}>
        <PageHeader
          icon={<NotificationsRoundedIcon />}
          title={content.ALERTS_TITLE}
          prefix="alerts-header"
          actions={<RefreshButton onRefreshButtonClick={handleRefetch} totalItems={totalItems} />}
          bottomActions={<MobileAlertTabs totalItems={totalItems} onTabChange={handleTabChange} />}
        />
        <MobileAlertListUI
          alerts={mobileAlerts}
          displayIconColumn={displayIconColumn}
          isLoading={isLoadingMobileAlerts}
          isError={isErrorMobileAlerts}
        />
        <PaginationMobile
          totalItems={getTotalItems(currentTab, totalItems)}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          loadedItems={mobileAlerts.length}
        />
      </PageContainer>
    );
  }
  return (
    <PageContainer prefix="alerts-page" className={classes.container}>
      <PageHeader icon={<NotificationsRoundedIcon />} title={content.ALERTS_TITLE} prefix="alerts-header" />
      <AlertTabs totalItems={totalItems} onTabChange={handleTabChange} onRefreshButtonClick={handleRefetch}>
        {(tabIndex) => (
          <AlertsFilters
            key={tabIndex}
            initialParams={defaultFilterParams(
              criticalityQueryParam,
              deviceIdQueryParam,
              equipmentIdQueryParam,
              deviceNameQueryParam,
              categoryQueryParam,
              alertTypeQueryParam
            )}
            onChangeParams={handleParamsChange}
          />
        )}
      </AlertTabs>
      <AlertListUI alerts={alerts} displayIconColumn={displayIconColumn} isLoading={isLoading} isError={isError} />
      <Pagination pagination={pagination} onPaginationChange={handlePaginationChange} />
    </PageContainer>
  );
}
