import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { useSearchParams } from '@marlin/shared/utils-router';
import { useOnViewportChange } from '@xyflow/react';
import { ReactNode, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  nodeTagTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(4),
  },
  nodeTag: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    borderRadius: '50%',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.text.alternative}`,
    backgroundColor: theme.palette.charting.plasmaLavender,
  },
}));

export const SystemTagTooltip = ({
  children,
  text,
  when = true,
}: {
  children?: JSX.Element;
  text: NonNullable<ReactNode>;
  when?: boolean;
}) => {
  const { classes } = useStyles();
  const [tooltipEnabled, setTooltipEnabled] = useState(true);
  const [open, setOpen] = useState(false);
  // NOTE: Temporary fix for the issue with the tooltip not closing when the sensor drawer is open
  const [params] = useSearchParams();

  useOnViewportChange({
    onStart: (viewport) => {
      setTooltipEnabled(false);
    },
    onEnd: (viewport) => {
      setTooltipEnabled(true);
    },
  });

  useEffect(() => {
    const isSensorDrawerOpen = params?.size > 0;

    if (!isSensorDrawerOpen) {
      setTooltipEnabled(true);
    }
  }, [params.size]);

  const shouldRender = when && tooltipEnabled;

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const tooltipContent = (
    <div className={classes.nodeTagTooltip}>
      <div className={classes.nodeTag} />
      {text}
    </div>
  );

  return (
    <Tooltip
      placement="top"
      text={tooltipContent}
      open={shouldRender && open}
      onOpen={onOpen}
      onClose={onClose}
      followCursor={true}
    >
      <div onClick={onClose} style={{ pointerEvents: 'auto' }}>
        {children}
      </div>
    </Tooltip>
  );
};
