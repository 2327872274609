import { MarlinTheme } from '@marlin/shared/theme';
import { Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../shared/content';

interface IConnectionType {
  connectionType: string;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.typography.pxToRem(8),
  },
  iconWrapper: {
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const ConnectionType = ({ connectionType }: IConnectionType) => {
  const { classes } = useStyles();

  switch (connectionType) {
    case 'Ethernet':
    case '0':
      return <ControlType icon="cable" label={content.ETHERNET} />;
    case 'WiFi':
    case '1':
      return <ControlType icon="wifi" label={content.WIFI} />;
    case 'Cellular':
    case '2':
      return <ControlType icon="cell_tower" label={content.CELLULAR} />;
    default:
      return <span className={classes.text}>--</span>;
  }
};

const ControlType = ({ icon, label }: { icon: string; label: string }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.iconWrapper}>
      <Icon baseClassName="material-symbols-outlined" className={classes.icon} data-testid="check-circle">
        {icon}
      </Icon>
      <span className={classes.text}>{label}</span>
    </div>
  );
};
