import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(24),
    gap: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.alternative,
    border: `${theme.typography.pxToRem(1)} solid`,
    borderColor: theme.palette.divider,
  },

  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },

  subtitle: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
}));
