import { ISection } from '@marlin/asset/shared/equipment-config';

import { icons } from '../../../../shared/assets';
import { content } from '../../../content';

export const sectionConfig: ISection[] = [
  {
    title: content.SYSTEM_INPUTS,
    dataTestId: 'system-inputs-section',
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'heatingCallsAndTarget',
        title: content.HEATING_CALLS,
        icon: icons.temperature,
        items: [
          {
            name: 'roomTarget',
            label: content.DATAPOINT_SSC_LABELS.ROOM_TARGET,
          },
          {
            name: 'earlyStartStatus',
            label: content.DATAPOINT_SSC_LABELS.EARLY_START,
            dependencies: {
              visibility: {
                datapointName: 'systemAuxiliary',
                value: new Map([['indoor', true]]),
              },
            },
          },
          {
            name: 'boostStatus',
            label: content.DATAPOINT_SSC_LABELS.BOOST,
            dependencies: {
              visibility: {
                datapointName: 'systemAuxiliary',
                value: new Map([
                  ['off', true],
                  ['flue', true],
                ]),
              },
            },
          },
          {
            name: 'smartSetbackStatus',
            label: content.DATAPOINT_SSC_LABELS.SYSTEM_SMART_SETBACK,
          },
          {
            name: 'dhwStatus',
            label: content.DATAPOINT_SSC_LABELS.DHW_ENABLE,
          },
        ],
      },
      {
        sectionName: 'sensors',
        title: content.SENSORS,
        icon: icons.indicator,
        items: [
          {
            name: 'outdoorTemp',
            label: content.DATAPOINT_SSC_LABELS.OUTDOOR_TEMP,
          },
          {
            name: 'systemAuxiliary',
            label: content.DATAPOINT_SSC_LABELS.SYSTEM_AUXILIARY,
          },
          {
            name: 'condensateTemp',
            label: content.DATAPOINT_SSC_LABELS.CONDENSATE_TEMP,
          },
          {
            name: 'pressure',
            label: content.DATAPOINT_SSC_LABELS.PRESSURE,
          },
        ],
      },
    ],
  },
  {
    title: content.SYSTEM_OUTPUTS_AND_CONDITION,
    dataTestId: 'system-output-and-condition-section',
    style: {
      fullWidth: true,
    },
    sectionDatapoints: [
      {
        sectionName: 'outputs',
        title: content.OUTPUTS,
        icon: icons.indicator,
        items: [
          {
            name: 'combustionAirStatus',
            label: content.DATAPOINT_SSC_LABELS.COMBUSTION_AIR,
          },
          {
            name: 'wwsdStatus',
            label: content.DATAPOINT_SSC_LABELS.WARM_WEATHER_SHUTDOWN,
          },
          {
            name: 'overrideMode',
            label: content.DATAPOINT_SSC_LABELS.OVERRIDE_MODE,
            dependencies: {
              statusMapping: new Map([
                ['off', content.EQUIPMENT_STATUS_LABELS.AUTOMATIC],
                ['on', content.EQUIPMENT_STATUS_LABELS.OVERRIDE],
              ]),
            },
          },
        ],
      },
    ],
  },
];
