import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  inputSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
  inputRow: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  input: {
    marginBottom: theme.typography.pxToRem(8),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    minHeight: theme.typography.pxToRem(432),
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '150%',
    color: theme.palette.text.primary,
  },
  subTitleText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '166%',
    color: theme.palette.text.secondary,
  },
  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
  warningRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  warningIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
  timeInputs: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(8),
  },
}));
