import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { createAutomation } from '../../infrastructure/automation-builder';
import { queryKey } from '../query-key.enum';

export const useCreateAutomationComplex = () => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.AUTOMATIONS_COMPLEX() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.AUTOMATIONS_COMPLEX() });
    },
    mutationFn: createAutomation,
  });
};
