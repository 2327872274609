import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useCallback } from 'react';

import { useUpdateGateway as useUpdateGatewayShared } from '../../shared/hooks/use-update-gateway.hook';

export const useUpdateGateway = () => {
  const gatewayId = useIdFromPathname();
  const { goTo } = useRouter();
  const nextRoute = routes.gateway.list.path;

  const onSubmitSuccessCallback = useCallback(() => {
    goTo(nextRoute, { state: { shouldIgnoreGuard: true } });
  }, [goTo, nextRoute]);

  return useUpdateGatewayShared({ gatewayId, onSuccess: onSubmitSuccessCallback });
};
