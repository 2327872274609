import { emailFieldSchema, requiredTextFieldSchema } from '@marlin/shared/ui-form';
import { z } from 'zod';

import { content } from './content';

export const frequencySchema = z.union([
  z.literal('1hr'),
  z.literal('4hr'),
  z.literal('8hr'),
  z.literal('1day'),
  z.literal('1week'),
  z.literal('Realtime'),
]);

export const webhookTypeSchema = z.union([
  z.literal('total-organization-volume'),
  z.literal('all-individual-flow-meters'),
  z.literal('all-individual-partitions'),
]);

export const webhookApiKey = z.object({
  name: requiredTextFieldSchema,
  value: requiredTextFieldSchema,
});

export const selectedFlowMeterSchema = z.object({
  deviceId: z.string(),
  name: z.string(),
  datapointName: z.string(),
});

export const webhookFormSchema = z.object({
  name: requiredTextFieldSchema,
  managerEmail: emailFieldSchema,
  url: requiredTextFieldSchema.url(content.URL_VALIDATION_ERROR),
  frequency: frequencySchema,
  apiKeys: z.array(webhookApiKey),
  devices: z.array(selectedFlowMeterSchema),
  type: webhookTypeSchema,
});

export type TFrequency = z.infer<typeof frequencySchema>;
export type TWebhookForm = z.infer<typeof webhookFormSchema>;
export type TSelectedFlowMeter = z.infer<typeof selectedFlowMeterSchema>;
export type TWebhookType = z.infer<typeof webhookTypeSchema>;
