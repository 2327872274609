import { TAutomationBuilder, TLogicalOperator } from '@marlin/alert/data-access/automated-action';
import { Button, ButtonGroup } from '@mui/material';
import { NodeToolbar, Position, getOutgoers, useReactFlow, useViewport } from '@xyflow/react';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { uiContent } from '../content';
import { useRulesStore$ } from '../hooks/use-observable-rules-store';
import { useTriggersStore$ } from '../hooks/use-observable-trigger-store';
import { TModelStore } from '../types';
import { Rule } from './rules/rule.component';
import { SectionHeader } from './section-header.component';

export const TriggersNode = () => {
  const { zoom } = useViewport();
  const { rulesState, generateId, addOperator } = useRulesStore$();
  const { currentState } = useTriggersStore$();
  const { addNodes, addEdges, getNodes, getEdges } = useReactFlow();

  const {
    field: { onChange: onSubmitRules },
  } = useController<TAutomationBuilder>({ name: 'trigger.operation' });

  const isRuleDisabled = !(currentState as TModelStore)?.models?.length;

  const onAddRule = () => {
    const id = generateId();
    const outgoers = getOutgoers({ id: 'triggerTitle' }, getNodes(), getEdges());

    const newNode = {
      id,
      position: { x: 700, y: 500 + 100 * outgoers.length },
      type: 'componentNode',
      data: { children: <Rule id={id} /> },
    };

    const newEdge = {
      id: `edge-34-${id}`,
      source: 'triggerTitle',
      target: id,
      sourceHandle: 'right',
      targetHandle: 'left',
    };

    addNodes([newNode]);
    addEdges([newEdge]);
  };

  const onAddOperator = (logicalOperator: TLogicalOperator) => {
    const id = generateId();
    const outgoers = getOutgoers({ id: 'triggerTitle' }, getNodes(), getEdges());
    addOperator(logicalOperator, id);

    const newNode = {
      id: id,
      position: { x: 700, y: 500 + 100 * outgoers.length },
      type: 'operatorNode',
      data: { logicalOperator },
    };

    const newEdge = {
      id: `edge-34-${id}`,
      source: 'triggerTitle',
      target: id,
      sourceHandle: 'right',
      targetHandle: 'left',
    };

    addNodes([newNode]);
    addEdges([newEdge]);
  };

  // todo: moveSomewhereElse
  useEffect(() => {
    onSubmitRules(rulesState);
  }, [onSubmitRules, rulesState]);

  return (
    <div>
      <SectionHeader title={uiContent.TRIGGER.TITLE} tooltip={uiContent.TRIGGER.TOOLTIP} prefix={'trigger'} />
      <NodeToolbar isVisible position={Position.Right} align={'center'}>
        <ButtonGroup orientation="vertical" style={{ transform: `scale(${zoom})` }}>
          <Button key="andoperator" onClick={() => onAddOperator('and')}>
            AND Operator
          </Button>
          <Button key="oroperator" onClick={() => onAddOperator('or')}>
            OR Operator
          </Button>
          <Button key="rule" onClick={onAddRule} disabled={isRuleDisabled}>
            Rule
          </Button>
        </ButtonGroup>
      </NodeToolbar>
    </div>
  );
};
