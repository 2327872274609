import { useCallback, useMemo, useState } from 'react';

interface IUseChatId {
  conversationId: string | null;
  setConversationId: (id: string) => void;
  clearConversationId: () => void;
}

export const useChatId = (): IUseChatId => {
  const [conversationId, setConversationId] = useState<string | null>(localStorage.getItem('conversationId') || null);

  const saveConversationId = useCallback((id: string) => {
    localStorage.setItem('conversationId', id);
    setConversationId(() => id);
  }, []);

  const clearConversationId = useCallback(() => {
    localStorage.removeItem('conversationId');
    setConversationId(null);
  }, []);

  return useMemo(
    () => ({ conversationId, setConversationId: saveConversationId, clearConversationId }),
    [conversationId, saveConversationId, clearConversationId]
  );
};
