import { IConfig } from '@marlin/asset/shared/equipment-config';

import { content } from '../../../content';

export const boilersAndPumpsConfig: IConfig['boilersAndPumps'] = {
  rows: [
    {
      name: `${content.DATAPOINT_SSC_LABELS.BOILER} {X}`,
      id: 'boiler{X}',
      hoursDatapoint: 'Boiler{X}IgnitionRuntime',
      cyclesDatapoint: 'Boiler{X}IgnitionCycles',
      boilerFiringRateDatapoint: 'Boiler{X}FiringRate',
      boilerFiringTempDatapoint: 'Boiler{X}FiringTemp',
      boilerEnabledStatus: 'Boiler{X}EnableStatus',
      type: 'boiler',
    },
  ],
  columns: ['boiler', 'hours', 'cycles', 'boilerStatus'],
};
