import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

export function useIsSticking({ elementRef }: { elementRef: RefObject<HTMLElement> }) {
  const previousTop = useRef<number>(-1);
  const [isSticking, setIsSticking] = useState<boolean>(false);

  const trackSticky = useCallback(() => {
    if (!elementRef.current) return;

    const currentTop = elementRef.current.getBoundingClientRect().top;
    const scrolledTop = document.getElementById('appContent')?.scrollTop || 0;

    if (previousTop.current === currentTop || !!scrolledTop) {
      setIsSticking(true);
    } else {
      setIsSticking(false);
    }

    previousTop.current = currentTop;
  }, [elementRef]);

  useEffect(() => {
    document.getElementById('appContent')?.addEventListener('scroll', trackSticky);
    return () => {
      document.getElementById('appContent')?.removeEventListener('scroll', trackSticky);
    };
  }, [trackSticky]);

  return { isSticking };
}
