import { Search } from '@marlin/shared/ui-form-common';
import { usePagination } from '@marlin/shared/utils/react-query';
import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';

import { ListHeader } from './components/list-header.component';
import { ListTitle } from './components/list-title.component';
import { dataGridPageCounterOffset, rowsPerPageOptions } from './constants';
import { content } from './content';
import { useDataGridSx, useStyles } from './marlin-table.component.styles';
import { IMarlinTableProps } from './types';

export const MarlinTable = <Type extends object>({
  useDataHook,
  createColumns,
  texts,
  actions,
  buttons,
  options,
}: IMarlinTableProps<Type>) => {
  const { classes } = useStyles({ height: options?.height });
  const sx = useDataGridSx();

  const { term, debouncedTerm, search } = useSearchFilter();
  const { page, pageSize, changePage, changePageSize } = usePagination({
    initialPageSize: options?.initialPageSize ?? 5,
  });

  const { data, isLoading, isError, onSortChange, pagination } = useDataHook({ debouncedTerm, page, pageSize });

  const dataGridPage = useMemo(() => pagination.page - dataGridPageCounterOffset, [pagination.page]);

  const dataGridChangePageSize = useCallback(
    (page: number) => changePage(page + dataGridPageCounterOffset),
    [changePage]
  );

  const createFilters = useCallback(
    (term: string, search: (value: string) => void) => {
      return (
        <ListHeader>
          {texts?.tableTitle ? (
            <div className={classes.header}>
              <ListTitle tooltipContent={texts?.tableTitleTooltip}>{texts?.tableTitle}</ListTitle>
              {buttons || null}
            </div>
          ) : (
            ''
          )}
          <div className={classes.listActions}>
            <div className={classes.actionItem}>
              <Search value={term} onChange={search} label={texts?.searchTitle || ''} prefix="user" />
            </div>
            <div>{actions}</div>
          </div>
        </ListHeader>
      );
    },
    [
      classes.actionItem,
      buttons,
      classes.header,
      classes.listActions,
      texts?.searchTitle,
      texts?.tableTitle,
      texts?.tableTitleTooltip,
    ]
  );

  return (
    <Paper data-testid="card" variant="outlined">
      {createFilters && createFilters(term, search)}
      <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
        <DataGrid
          sx={sx}
          loading={isLoading}
          onPageChange={dataGridChangePageSize}
          onPageSizeChange={changePageSize}
          localeText={{
            noRowsLabel: isError ? content.USER_TABLE_ERROR : content.USER_TABLE_NO_DATA,
          }}
          page={dataGridPage}
          pageSize={pagination.pageSize}
          rowCount={pagination.totalItems}
          rows={data}
          getRowId={(row) => row.id}
          columns={createColumns({
            columnProps: { headerClassName: classes.column },
            rows: data,
          })}
          rowsPerPageOptions={rowsPerPageOptions}
          paginationMode="server"
          disableSelectionOnClick
          disableColumnMenu
          onSortModelChange={onSortChange}
          {...options}
        />
      </div>
    </Paper>
  );
};
