import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

export const changeMode = async ({
  mode,
  deviceId,
}: {
  mode: 'Standby' | 'Active';
  deviceId: string;
}): Promise<void> => {
  await getHttpClient().put<unknown, void>(
    paths.GATEWAY_MODE,
    {
      mode,
    },
    { params: { deviceId } }
  );
};

export const useActivateGateway = ({
  deviceId,
  onError,
  onSuccess,
}: {
  deviceId: string;
  onSuccess: () => void;
  onError: (error: AxiosError<{ errorMessage: string; status: string }>) => void;
}) => {
  return useMutation<unknown, AxiosError<{ errorMessage: string; status: string }>, { deviceId: string }>({
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries({ queryKey: queryKey.GATEWAY_MODE({ deviceId }) });
    },
    onError,
    mutationFn: () => changeMode({ mode: 'Active', deviceId }),
  });
};

export const useDeactivateGateway = ({ deviceId }: { deviceId: string }) => {
  return useMutation<unknown, unknown, { deviceId: string }>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.GATEWAY_MODE({ deviceId }) });
    },
    mutationFn: () => changeMode({ mode: 'Standby', deviceId }),
  });
};
