import { sanitizeString, sanitizedStringSchema } from '@marlin/shared/utils/zod';
import isNil from 'lodash/isNil';
import { z } from 'zod';

const handleNullish = (value: string | null | undefined) => {
  if (isNil(value)) {
    return '';
  }

  return value;
};

const orgFeatureSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  isBeta: z.boolean(),
  isActive: z.boolean(),
});

const orgAddressSchemaUpdate = z.object({
  country: z.string(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

const orgAddressSchema = z.object({
  country: z.string().nullish().transform(handleNullish),
  address1: z.string().nullish().transform(handleNullish),
  address2: z.string().nullish().transform(handleNullish),
  city: z.string().nullish().transform(handleNullish),
  state: z.string().nullish().transform(handleNullish),
  postalCode: z.string().nullish().transform(handleNullish),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

export const orgDetailsParamsSchema = z
  .object({
    organizationId: z.string(),
  })
  .optional();

const billingContactSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  title: z.string(),
  email: z.string().email(),
});

const assignedCsmSchema = z.object({
  id: z.string(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
});

const jsonBillingContactSchema = z.preprocess((val) => {
  if (typeof val === 'string') {
    try {
      return JSON.parse(val);
    } catch {
      return null;
    }
  }
  return val;
}, billingContactSchema);

export const orgDetailsSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: orgAddressSchema.nullish(),
  timeZone: z.string().nullish(),
  features: z.array(orgFeatureSchema),
  tierId: z.string(),
  tierName: z.string(),
  customerBillingContact: jsonBillingContactSchema.nullish(),
  notes: z.string().nullish(),
  customerOrganization: z.boolean().nullish(),
  assignedCsm: z.array(assignedCsmSchema).nullish(),
});

export type TOrgAddress = z.infer<typeof orgAddressSchema>;
export type TOrgDetails = z.infer<typeof orgDetailsSchema>;
export type TOrgDetailsParams = z.infer<typeof orgDetailsParamsSchema>;

const billingContactInputSchema = z.object({
  firstName: sanitizedStringSchema,
  lastName: sanitizedStringSchema,
  phone: sanitizedStringSchema,
  title: sanitizedStringSchema.nullish(),
  email: z.string().email().transform(sanitizeString),
});

const billingContactOutputSchema = billingContactInputSchema.transform((value): string => {
  return JSON.stringify(value);
});

export const updateOrgAddressSchema = z.object({
  name: z.string().nullish(),
  address: orgAddressSchemaUpdate.nullish(),
  timeZone: z.string().nullish(),
  organizationId: z.string().nullish(),
  tierId: z.string().nullish(),
  customerBillingContact: billingContactOutputSchema.nullish(),
  notes: z.string().nullish(),
  customerOrganization: z.boolean().nullish(),
  assignedCsm: z.array(assignedCsmSchema.transform((value) => value.id)).nullish(),
});

export type TUpdateOrgAddress = z.input<typeof updateOrgAddressSchema>;
export type TOrganizationFeature = z.output<typeof orgFeatureSchema>;
