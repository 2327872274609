import { useColorModeContext } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { CommonChip } from '@marlin/shared/ui/chips';
import NightlightIcon from '@mui/icons-material/Nightlight';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Typography } from '@mui/material';
import { useCallback } from 'react';

import { content } from '../content';
import { useStyles } from '../settings-appearance.styles';

export const ThemeSwitcher = () => {
  const { classes, cx } = useStyles();

  const { mode, setMode } = useColorModeContext();

  const onBrightClick = useCallback(() => {
    setMode('light');
  }, [setMode]);

  const onDarkClick = useCallback(() => {
    setMode('dark');
  }, [setMode]);

  return (
    <Paper className={classes.container} data-testid="theme-card">
      <Typography className={classes.title}>{content.THEME}</Typography>
      <div className={classes.themeSwitcherContainer}>
        <div
          className={cx(classes.themeOption, { [classes.themeOptionActive]: mode === 'light' })}
          onClick={onBrightClick}
        >
          <div className={classes.themeOptionTitle}>
            <WbSunnyIcon />
            {content.THEME_BRIGHT}
          </div>
          {mode === 'light' && (
            <div>
              <CommonChip active={true} variant={'filled'} label={content.ACTIVE} />
            </div>
          )}
        </div>
        <div
          className={cx(classes.themeOption, { [classes.themeOptionActive]: mode === 'dark' })}
          onClick={onDarkClick}
        >
          <div className={classes.themeOptionTitle}>
            <NightlightIcon />
            {content.THEME_DARK}
          </div>
          {mode === 'dark' && (
            <div>
              <CommonChip active={true} variant={'filled'} label={content.ACTIVE} />
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
};
