import { TOperator } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Select } from '@marlin/shared/ui-form-common';
import { SelectChangeEvent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: '100%',
  },
}));

const numberOperators = [
  { id: 'eq', name: 'Equal' },
  { id: 'neq', name: 'Not Equal' },
  { id: 'gt', name: 'Greater Than' },
  { id: 'gte', name: 'Greater Than or Equal' },
  { id: 'lt', name: 'Less Than' },
  { id: 'lte', name: 'Less Than or Equal' },
];

const nonNumberOperators = [
  { id: 'eq', name: 'Equal' },
  { id: 'neq', name: 'Not Equal' },
];

export interface IOperatorProps {
  value: TOperator;
  onChange: (value: TOperator) => void;
}

export const operator =
  (variant: 'numeric' | 'non-numeric') =>
  ({ onChange, value }: IOperatorProps) => {
    const { classes } = useStyles();
    return (
      <Select
        label="Operator"
        value={value}
        onChange={(event: SelectChangeEvent) => {
          onChange(event.target.value as TOperator);
        }}
        data={variant === 'numeric' ? numberOperators : nonNumberOperators}
        prefix="operator"
        fullWidth
        className={classes.select}
      />
    );
  };
