import { MarlinTheme } from '@marlin/shared/theme';
import { TDeviceType, deviceTypeOptions } from '@marlin/shared/ui-form';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Handle, NodeProps, Position, useReactFlow } from '@xyflow/react';
import { useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { getModelsBySensorType } from '../../shared/utils/sensor-type-utils';
import { TRIGGER_STEP } from '../../types';
import { useCommonStyles } from '../common.styles';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  icon: {
    color: theme.palette.action.active,
  },
  selectWrapper: {
    width: '100%',
  },
  handle: {
    width: 1,
    height: 1,
    opacity: 0,
  },
}));

export const SensorTypeNode = ({ id }: NodeProps) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes, cx } = useStyles();
  const [sensorType, setSensorType] = useState<TDeviceType | undefined>(undefined);
  const { addNodes, addEdges, deleteElements } = useReactFlow();

  const { addModels, removeModels, currentState } = useTriggersStore$();

  const sensorTypes = useMemo(() => Object.values(deviceTypeOptions), []);

  const handleSensorTypeChange = useCallback((event: SelectChangeEvent) => {
    setSensorType(event.target.value as TDeviceType);
  }, []);

  const onCancelClick = useCallback(async () => {
    removeModels();
    await deleteElements({
      nodes: [{ id }],
    });
  }, [deleteElements, id, removeModels]);

  const onChooseSensorsClick = useCallback(() => {
    if (sensorType) {
      addModels(getModelsBySensorType(sensorType));
      const newNode = {
        id: 'sensorManufacturerId',
        position: { x: 1100, y: 250 },
        type: 'sensorManufacturerIdNode',
        data: {},
      };

      const newEdge = {
        id: 'edge-35',
        source: 'sensorType',
        target: 'sensorManufacturerId',
        sourceHandle: 'right',
        targetHandle: 'left',
      };

      addNodes([newNode]);
      addEdges([newEdge]);
    }
  }, [addEdges, addNodes, addModels, sensorType]);

  return (
    <div className={cx(commonClasses.node, classes.container)} data-testid={`sensor-type-node-${id}`}>
      <div className={cx(commonClasses.nodeContent, classes.content)}>
        <span data-testid={`sensor-type-node-${id}-label`}>Sensor type</span>
        <div className={cx(classes.selectWrapper, 'nodrag')}>
          <FormControl fullWidth>
            <InputLabel>Sensor type</InputLabel>
            <Select
              value={sensorType}
              label="Sensor type"
              onChange={handleSensorTypeChange}
              variant="outlined"
              disabled={currentState?.currentStep !== TRIGGER_STEP.ADD_SENSOR_TYPE}
            >
              {sensorTypes.map(({ id, Icon, name, value }) => (
                <MenuItem key={id} value={value}>
                  <div className={classes.deviceTypeOption}>
                    <Icon className={classes.icon} />
                    {name}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={commonClasses.actionButtons}>
          <Button onClick={onCancelClick} disabled={currentState?.currentStep !== TRIGGER_STEP.ADD_SENSOR_TYPE}>
            Cancel
          </Button>
          <Button
            onClick={onChooseSensorsClick}
            variant="contained"
            disabled={!sensorType || currentState?.currentStep !== TRIGGER_STEP.ADD_SENSOR_TYPE}
          >
            Choose sensors
          </Button>
        </div>
      </div>
      <Handle className={classes.handle} type="target" id="top" position={Position.Top} />
      <Handle className={classes.handle} type="target" id="left" position={Position.Left} />
      <Handle className={classes.handle} type="source" id="right" position={Position.Right} />
      <Handle className={classes.handle} type="source" id="bottom" position={Position.Bottom} />
    </div>
  );
};
