export const content = {
  WEBHOOKS_TITLE: 'Webhooks',
  CREATE_NEW_BUTTON: 'create new',
  WEBHOOK_TYPE: {
    AGGREGATED_ORGANIZATION_VOLUME: 'Total Organization Volume',
    AGGREGATED_DEVICE_VOLUME: 'All Individual Flow Meters',
    ALL_INDIVIDUAL_PARTITIONS: 'All Individual Partitions',
  },
  FREQUENCY: {
    REALTIME: 'Real Time',
    HOURLY: '1 hour',
    DAILY: '1 day',
    WEEKLY: '1 week',
    MONTHLY: '1 month',
    FOUR_HOURS: '4 hours',
    EIGHT_HOURS: '8 hours',
  },
  DELETE_WEBHOOK_TITLE: 'Delete Webhook?',
  DELETE_WEBHOOK_BODY: 'Are you sure you want to delete this webhook?',
  DELETE_WEBHOOK_SUCCESS: 'Your webhook has been deleted',
  MODAL_BUTTON_SUBMIT_TEXT: 'Delete',
  MODAL_BUTTON_CANCEL_TEXT: 'Cancel',
  DELETE_WEBHOOK_ERROR: "Something went wrong. Your webhook hasn't been deleted",
  WEBHOOK_TOGGLE: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    SUSPENDED_TOOLTIP:
      'The webhook service has been suspended after 10 failed attempts. Enable the webhook to continue receiving messages.',
    ENABLED_SUCCESS_MESSAGE: 'Your webhook has been enabled',
    DISABLED_SUCCESS_MESSAGE: 'Your webhook has been disabled',
    UNSUSPENDED_SUCCESS_MESSAGE: 'Your webhook has been unsuspended',
    ENABLED_ERROR_MESSAGE: 'Something went wrong. Your webhook has not been enabled',
    DISABLED_ERROR_MESSAGE: 'Something went wrong. Your webhook has not been disabled',
    UNSUSPENDED_ERROR_MESSAGE: 'Something went wrong. Your webhook has not been unsuspended',
  },
  SHOW_KEYS: 'Show Keys',
  HIDE_KEYS: 'Hide Keys',
  HEADER_KEY_VALUES: 'Header Key-Value Pairs',
  HIDE: 'Hide',
  KEY: (index: number) => `Key ${index}`,
  VALUE: (index: number) => `Value ${index}`,
};
