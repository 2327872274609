import { IBoilersAndPumpsRow, TBoilersAndPumpsColumnName } from '@marlin/asset/shared/equipment-config';
import { useCallback, useMemo } from 'react';

import { useEquipmentDetailsConfigContext } from '../../../shared/context/equipment-details-config-context';
import { content } from '../../content';
import { useStyles } from './use-styles.hook';

export interface IBoilersAndPumpsColumnDef {
  field: string;
  headerName: string;
  flex?: number;
  renderCell?: (cellValues: { row: IBoilersAndPumpsRow }) => JSX.Element;
  renderHeader?: () => JSX.Element;
  sortable?: boolean;
  disableColumnMenu?: boolean;
}

const columnDefinitionsMap = new Map<
  TBoilersAndPumpsColumnName,
  (
    columnProps: Partial<IBoilersAndPumpsColumnDef>,
    getClassName: (
      columnName: TBoilersAndPumpsColumnName,
      cellValues: {
        row: IBoilersAndPumpsRow;
      }
    ) => string
  ) => IBoilersAndPumpsColumnDef
>([
  [
    'name',
    (columnProps) => ({
      field: 'name',
      headerName: content.BOILERS_AND_PUMPS.COLUMNS.NAME,
      ...columnProps,
      flex: 1,
    }),
  ],
  [
    'boiler',
    (columnProps) => ({
      field: 'name',
      headerName: content.BOILERS_AND_PUMPS.COLUMNS.BOILER,
      ...columnProps,
      flex: 1,
    }),
  ],
  [
    'hours',
    (columnProps) => ({
      field: 'hours',
      headerName: content.BOILERS_AND_PUMPS.COLUMNS.HOURS,
      ...columnProps,
      flex: 1,
    }),
  ],
  [
    'cycles',
    (columnProps) => ({
      field: 'cycles',
      headerName: content.BOILERS_AND_PUMPS.COLUMNS.CYCLES,
      ...columnProps,
      flex: 1,
    }),
  ],
  [
    'boilerStatus',
    (columnProps, getClassName) => ({
      field: 'boilerStatus',
      headerName: content.BOILERS_AND_PUMPS.COLUMNS.BOILER_STATUS,
      ...columnProps,
      flex: 1,
      renderCell: (cellValues) => {
        return <div className={getClassName('boilerStatus', cellValues)}>{cellValues.row.boilerStatus}</div>;
      },
    }),
  ],
  [
    'pumpStatus',
    (columnProps, getClassName) => ({
      field: 'pumpStatus',
      headerName: content.BOILERS_AND_PUMPS.COLUMNS.PUMP_STATUS,
      renderCell: (cellValues) => (
        <div className={getClassName('pumpStatus', cellValues)}>{cellValues.row.pumpStatus}</div>
      ),
      ...columnProps,
      flex: 1,
    }),
  ],
]);

export const useCreateColumns = ({
  columnProps,
}: {
  columnProps: Partial<IBoilersAndPumpsColumnDef>;
}): IBoilersAndPumpsColumnDef[] => {
  const { classes, cx } = useStyles();

  const getClassName = useCallback(
    (
      columnName: TBoilersAndPumpsColumnName,
      cellValues: {
        row: IBoilersAndPumpsRow;
      }
    ) => {
      switch (columnName) {
        case 'boilerStatus':
          return `${cx({
            [classes.highlighted]: cellValues.row.boilerStatusControlType === 'highlight',
            [classes.error]: cellValues.row.boilerStatusControlType === 'error',
            [classes.custom]: !cellValues.row.boilerStatusControlType,
          })}`;
        case 'pumpStatus':
          return `${cx({
            [classes.highlighted]: cellValues.row.pumpStatusControlType === 'highlight',
            [classes.error]: cellValues.row.pumpStatusControlType === 'error',
            [classes.custom]: !cellValues.row.pumpStatusControlType,
          })}`;
        default:
          return '';
      }
    },
    [classes.custom, classes.error, classes.highlighted, cx]
  );

  const {
    config: { boilersAndPumps },
  } = useEquipmentDetailsConfigContext();

  const columns = useMemo(() => boilersAndPumps?.columns, [boilersAndPumps]);

  return useMemo(() => {
    if (!columns) return [];

    return columns.map((columnName) => {
      const columnDefinition = columnDefinitionsMap.get(columnName);

      if (!columnDefinition) return null;

      return columnDefinition(columnProps, getClassName);
    });
  }, [columnProps, columns, getClassName]).filter(Boolean) as IBoilersAndPumpsColumnDef[];
};
