import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface IGridLayout {
  form: JSX.Element | null;
  tags?: JSX.Element | null;
  buttons?: JSX.Element | null;
}

export const GridLayout = ({ form, tags, buttons }: IGridLayout) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Grid container spacing={2} maxWidth={1280}>
      {form && (
        <Grid item xs={isMobile ? 12 : tags ? 7 : 12}>
          {form}
        </Grid>
      )}
      {tags && (
        <Grid item xs={isMobile ? 12 : 5}>
          {tags}
        </Grid>
      )}
      {buttons && (
        <Grid item xs={isMobile ? 12 : tags ? 7 : 12}>
          {buttons}
        </Grid>
      )}
    </Grid>
  );
};
