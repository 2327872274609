import { MarlinTheme } from '@marlin/shared/theme';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { Handle, NodeProps, Position, useReactFlow, useViewport } from '@xyflow/react';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { AddNextStepButton } from '../../shared/components/add-next-step.component';
import { sensorModelToSensorTypeMap } from '../../shared/utils/sensor-type-utils';
import { TRIGGER_STEP, TRIGGER_VARIANT } from '../../types';
import { useCommonStyles } from '../common.styles';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  device: {
    backgroundColor: theme.palette.systemMap.device,
    width: theme.typography.pxToRem(96),
    height: theme.typography.pxToRem(96),
    borderRadius: '50%',
    padding: 0,
  },
  deviceBackground: {
    backgroundColor: theme.palette.background.primary,
    width: theme.typography.pxToRem(96),
    height: theme.typography.pxToRem(96),
    borderRadius: '50%',
    padding: 0,
  },
  deviceNodeContent: {
    lineHeight: 1.6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  summary: {
    backgroundColor: theme.palette.systemMap.device,
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
  },
  handle: {
    width: 1,
    height: 1,
    opacity: 0,
  },
}));

export const SensorNode = ({ id }: NodeProps) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { zoom } = useViewport();

  const { currentState, restoreDefaultState, openAddSensorType } = useTriggersStore$();

  useEffect(() => {
    restoreDefaultState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addNodes, addEdges } = useReactFlow();
  const onAddClick = () => {
    openAddSensorType();

    const newNode = {
      id: 'sensorType',
      position: { x: 625, y: 325 },
      type: 'sensorTypeNode',
      data: {},
    };

    const newEdge = {
      id: 'edge-36',
      source: 'sensorNode',
      target: 'sensorType',
      sourceHandle: 'right',
      targetHandle: 'left',
    };

    addNodes([newNode]);
    addEdges([newEdge]);
  };

  if (currentState?.currentStep === TRIGGER_STEP.ADD_RULE || currentState?.currentStep === TRIGGER_STEP.FINISHED) {
    if (
      currentState?.variant === TRIGGER_VARIANT.SENSOR &&
      currentState.models?.length &&
      currentState.manufacturerIdList.length
    ) {
      return (
        <div className={classes.summary}>
          <div className={cx(commonClasses.node)} data-testid={`device-node-${id}`}>
            <div className={classes.deviceNodeContent}>
              <SensorsRoundedIcon />
              <span data-testid={`device-summary-node-${id}-label`}>
                Chosen sensor type: {sensorModelToSensorTypeMap.get(currentState?.models[0])}
              </span>
              <span>Chosen sensors:</span>
              {currentState.manufacturerIdList.map((manufacturerId) => (
                <span key={manufacturerId.id}>{manufacturerId.name}</span>
              ))}
            </div>
            <Handle className={classes.handle} type="target" id="top" position={Position.Top} />
            <Handle className={classes.handle} type="target" id="left" position={Position.Left} />
            <Handle className={classes.handle} type="source" id="right" position={Position.Right} />
            <Handle className={classes.handle} type="source" id="bottom" position={Position.Bottom} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className={classes.deviceBackground}>
      <div className={cx(commonClasses.node, classes.device)} data-testid={`device-node-${id}`}>
        <div className={classes.deviceNodeContent}>
          <SensorsRoundedIcon />
          <span data-testid={`device-node-${id}-label`}>Sensor</span>
        </div>
        <Handle className={classes.handle} type="target" id="top" position={Position.Top} />
        <Handle className={classes.handle} type="target" id="left" position={Position.Left} />
        <Handle className={classes.handle} type="source" id="right" position={Position.Right} />
        <Handle className={classes.handle} type="source" id="bottom" position={Position.Bottom} />
      </div>

      {currentState?.currentStep === 'IDLE' && <AddNextStepButton onClick={onAddClick} zoom={zoom} />}
    </div>
  );
};
