import { useQuery } from '@marlin/shared/utils/react-query';

import { getOrganizationDetails } from '../infrastructure/get-org-details';
import { TOrgDetailsParams } from '../schemas/org';
import { queryKey } from './query-key.enum';

export const useGetOrganizationDetails = (params?: TOrgDetailsParams) => {
  return useQuery({
    queryKey: queryKey.ORGANIZATION_DETAILS(params),
    queryFn: () => getOrganizationDetails(params),
  });
};
