import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Button } from '@mui/material';
import {
  MarkerType,
  Panel,
  ReactFlow,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import ComponentNode from './component-node.component';
import { initialEdges, initialNodes } from './default-nodes';
import { EquipmentManufacturerIdNode } from './nodes/equipment/equipment-manufacturer-id.component';
import { EquipmentModelNode } from './nodes/equipment/equipment-model.component';
import { EquipmentNode } from './nodes/equipment/equipment.component';
import { LogicalOperatorNode } from './nodes/rules/logical-operator.component';
import { SensorTypeNode } from './nodes/sensors/sensor-type.component';
import { SensorNode } from './nodes/sensors/sensor.component';
import { SensorsManufacturerIdNode } from './nodes/sensors/sensors-manufacturer-id.component';

const nodeTypes = {
  componentNode: ComponentNode,
  sensorNode: SensorNode,
  sensorTypeNode: SensorTypeNode,
  sensorManufacturerIdNode: SensorsManufacturerIdNode,
  equipmentNode: EquipmentNode,
  equipmentModelNode: EquipmentModelNode,
  equipmentManufacturerIdNode: EquipmentManufacturerIdNode,
  operatorNode: LogicalOperatorNode,
};

export const Canvas = ({ handleSubmit }: { handleSubmit: () => void }) => {
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { toObject } = useReactFlow();
  const { getValues } = useFormContext<TAutomationBuilder>();
  const { enqueueSnackbar } = useSnackbar();

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [setEdges, edges, nodes]
  );

  return (
    <div style={{ height: '100%', width: '100%', minHeight: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodesDelete={onNodesDelete}
        nodeTypes={nodeTypes}
        fitView
        preventScrolling
        proOptions={{
          hideAttribution: true,
        }}
        defaultEdgeOptions={{
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 32,
            height: 32,
          },
        }}
      >
        <Panel position="top-right">
          <Button onClick={handleSubmit}>Submit</Button>
          <Button
            onClick={() => {
              const data = toObject();
              // eslint-disable-next-line no-console
              console.log(data);
              navigator?.clipboard.writeText(JSON.stringify(getValues())).then(() => {
                enqueueSnackbar('Form data JSON has been copied', {
                  variant: 'success',
                  preventDuplicate: true,
                });
              });
            }}
          >
            Export
          </Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};
