import { DEVICE_TYPE, THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { useMemo } from 'react';

import { HomeCardEquipment } from '../card-equipment';
import { HomeCardNoAssets } from '../card-no-assets';
import { HomeCardNoSensors } from '../card-no-devices';
import { HomeCardUnassignedDevices } from '../card-unassigned-devices';
import { useStyles } from './card-assets.component.styles';

type THomeCardAssetsProps = {
  homeLocation: THomeLocationSchema;
};

const HomeCardAssets = ({ homeLocation }: THomeCardAssetsProps) => {
  const { classes } = useStyles();
  const equipments = useMemo(() => homeLocation.equipment, [homeLocation.equipment]);

  if (!equipments?.length && !homeLocation.unattachedDevices?.length) {
    return <HomeCardNoAssets location={homeLocation} />;
  }

  return (
    <div className={classes.wrapper}>
      {equipments?.map((equipment) => (
        <HomeCardEquipment key={equipment.equipmentId} homeLocation={homeLocation} equipment={equipment} />
      ))}
      {homeLocation.unattachedDevices?.length ? (
        <HomeCardUnassignedDevices
          homeLocation={homeLocation}
          devices={homeLocation.unattachedDevices.filter((device) => device.deviceType !== DEVICE_TYPE.UNKNOWN)}
        />
      ) : (
        <HomeCardNoSensors location={homeLocation} />
      )}
    </div>
  );
};

export { HomeCardAssets };
