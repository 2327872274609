import { MarlinTheme } from '@marlin/shared/theme';
import { IFormFieldChildrenProps } from '@marlin/shared/ui-form-common';
import { TSourceSinkTag } from '@marlin/system-map/shared/data-access-schemas';
import { FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ISidebarFormModel } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  control: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const WaterSourceSwitch = ({ onChange, value }: IFormFieldChildrenProps) => {
  const { classes } = useStyles();
  const { trigger } = useFormContext<ISidebarFormModel>();

  const handleSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange && onChange('POE_WATER_SOURCE');
        trigger('sourceSinkTag');
      } else {
        onChange && onChange('');
        trigger('sourceSinkTag');
      }
    },
    [onChange, trigger]
  );

  return (
    <FormControlLabel
      className={classes.control}
      label={content.SOURCE_SINK_LABEL}
      control={
        <Switch
          disabled // Note: User may not set the POE_water_source tag within the Asset Details modal, see 38419
          checked={(value as TSourceSinkTag) === 'POE_WATER_SOURCE'}
          onChange={handleSwitchChange}
          data-testid="card-toggle"
        />
      }
      labelPlacement="start"
    />
  );
};
