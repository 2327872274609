import { z } from 'zod';

export enum FEATURE {
  TestFeature = 0,

  //  Tier 1 features are base line features, so they are not defined here

  // Tier 2 features

  SensorDevices = 1,
  SystemMap = 2,
  SustainabilityData = 3,
  CustomAutomations = 4,
  MultiDataCharts = 5,
  EnergyCalculations = 6,
  DataExport = 7,
  PerformanceScores = 8,
  AutogeneratedReports = 9,
  Webhooks = 16,
  Api = 17,

  // Tier 3 features

  EnergyCalculations2 = 10,
  MultiPointAutomations = 11,
  PredictiveInsights = 12,
  EquationBuilder = 13,
  MultiDataCharts2 = 14,
  CustomReportFormats = 15,
}

const featureActionsSchema = z.union([z.literal('enable'), z.literal('disable')]);

export const featureParamsSchema = z.object({
  features: z.array(z.nativeEnum(FEATURE)),
  action: featureActionsSchema,
  organizationId: z.string(),
});

export type TFeatureParams = z.infer<typeof featureParamsSchema>;
