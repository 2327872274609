import { environment } from '@marlin/environment';

export const content = {
  HOME_TITLE: 'Home',
  HOME_SUBTITLE: `Your ${environment.app.name} Overview.`,
  SENSORS_HEALTH: 'Sensors Health',
  HEALTH: 'Health',
  ALERTS_OPEN: 'High and Low Criticality Alerts Open',
  LOCATIONS: 'Locations',
  MECHANICAL_ROOM: 'Mechanical Rooms',
  GATEWAYS: 'Gateways',
  SENSORS: 'Sensors',
  LOW_CRITICALITIES_ALERTS_OPEN: 'Low Criticality Alerts Open',
  PERFORM_SYSTEM_MAINTENANCE: 'Perform Sensor Maintenance',
  NO_AUTOMATIONS: 'No Automations Have Been Created',
  CREAATE_AUTOMATION: 'CREATE AUTOMATION',
  NO_SENSORS: 'No Sensors Connected Yet',
  ADD_SENSOR: 'ADD SENSOR',
  DETAILS: 'Details',
  USE_DESKTOP_AUTOMATIONS: 'Use your desktop to create automations',
  USE_DESKTOP_SENSORS: 'Use your desktop to create sensors',
  TIME_IN_ALERT: 'Time in alert:',
  CREATE_AN_AUTOMATION: 'Create an automation to receive alerts',
  NO_OPEN_ALERTS: 'You Have No Open Alerts',
  ALERTS: 'Alerts',
  PERFORMANCE: 'Performance',
  OPEN_ALERTS: 'Open Alerts',
  CHART_TITLE_MODAL: 'Flow',
};
