import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  menu: {
    padding: 0,
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: theme.typography.pxToRem(160),
  },
  optionIcon: {
    marginRight: `-${theme.typography.pxToRem(8)}`,
  },
}));
