import { z } from 'zod';

export const DeviceModelsSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    models: z.array(
      z.object({
        id: z.string(),
        brandId: z.string(),
        name: z.string(),
        isWattsGatewayCompatible: z.boolean(),
      })
    ),
  })
);

export type TDeviceModels = z.infer<typeof DeviceModelsSchema>;
