import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(16),
    gap: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(4),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.background.alternative,
    border: `${theme.typography.pxToRem(1)} solid`,
    borderColor: theme.palette.divider,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.divider}`,
  },

  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },

  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },

  subheader: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },

  accordion: {
    border: `${theme.typography.pxToRem(1)} solid`,
    borderColor: theme.palette.divider,
    borderTop: 0,
    backgroundColor: theme.palette.background.primary,
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
}));
