import type {
  IDashboardConfig,
  IDashboardSectionStyle,
  TEquipmentDashboardSectionMetadata,
} from '@marlin/asset/shared/equipment-config';
import { MarlinTheme } from '@marlin/shared/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ChartWrapper } from './chart/chart-wrapper.component';
import { DashboardAlerts } from './dashboard-top-section/dashboard-alerts.component';
import { EquipmentProductDetails } from './dashboard-top-section/equipment-product-details.component';
import { DatapointSection } from './datapoint/datapoint-section.component';
import { EquipmentDetails } from './details/equipment-details-section.component';
import { EquipmentStatus } from './equipment-status/equipment-status.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      '&.Mui-expanded:hover': {
        cursor: 'default',
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      gap: 'unset',
      marginTop: 0,
    },
  },
}));

interface IDashboard {
  config: IDashboardConfig;
}

export const Dashboard = ({ config }: IDashboard) => {
  const { classes } = useStyles();
  const { sections, chart, productDetails } = config;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.column}>
          <EquipmentStatus />
          {productDetails ? <EquipmentProductDetails productDatapoints={productDetails?.datapoints} /> : null}
          {chart && (
            <ChartWrapper
              title={chart?.title}
              subTitle={chart?.subTitle}
              datapointNames={chart?.datapointNames}
              datapointGroupNames={chart?.datapointGroupNames}
              options={chart?.options}
            />
          )}
          <div>
            {sections.map(({ sectionDatapoints, title, dataTestId, style }) => (
              <DatapointSectionWrapper
                key={title}
                sectionDatapoints={sectionDatapoints}
                dataTestId={dataTestId}
                title={title}
                style={style}
              />
            ))}
          </div>
          <DashboardAlerts />
          <EquipmentDetails />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.column}>
        {productDetails ? <EquipmentProductDetails productDatapoints={productDetails?.datapoints} /> : null}
        <div>
          {sections.map(({ sectionDatapoints, title, dataTestId, style }) => (
            <DatapointSectionWrapper
              key={title}
              sectionDatapoints={sectionDatapoints}
              dataTestId={dataTestId}
              title={title}
              style={style}
            />
          ))}
        </div>
      </div>
      <div className={classes.column}>
        {chart && (
          <ChartWrapper
            title={chart?.title}
            subTitle={chart?.subTitle}
            datapointNames={chart?.datapointNames}
            datapointGroupNames={chart?.datapointGroupNames}
            options={chart?.options}
          />
        )}
        <DashboardAlerts />
        <EquipmentDetails />
      </div>
    </div>
  );
};

interface IDatapointSectionWrapperProps {
  sectionDatapoints?: TEquipmentDashboardSectionMetadata[];
  dataTestId: string;
  title: string;
  style?: IDashboardSectionStyle;
}

const DatapointSectionWrapper = ({ sectionDatapoints, dataTestId, title, style }: IDatapointSectionWrapperProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Accordion
      className={classes.accordion}
      data-testid={dataTestId}
      defaultExpanded
      expanded={!isMobile ? true : undefined}
      elevation={0}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary className={classes.accordionSummary} expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
        {title}
      </AccordionSummary>
      {sectionDatapoints && (
        <AccordionDetails className={classes.paper}>
          <DatapointSection sectionDatapoints={sectionDatapoints} style={style} />
        </AccordionDetails>
      )}
    </Accordion>
  );
};
