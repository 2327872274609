import TemperatureIcon from '@mui/icons-material/DeviceThermostat';
import FlowIcon from '@mui/icons-material/PanoramaPhotosphere';
import PressureIcon from '@mui/icons-material/Speed';
import { Icon as MuiIcon } from '@mui/material';

const Icon = ({ name }: { name: string }) => <MuiIcon baseClassName="material-symbols-outlined">{name}</MuiIcon>;

export const icons = {
  temperature: <TemperatureIcon />,
  flow: <FlowIcon />,
  indicator: <PressureIcon />,
  pump: <Icon name="water_pump" />,
  valve: <Icon name="valve" />,
};
