import { Handle, Node, NodeProps, Position } from '@xyflow/react';

export type TComponentNode = Node<
  {
    children: JSX.Element;
  },
  'component'
>;

export default function ComponentNode({ data }: NodeProps<TComponentNode>) {
  return (
    <div>
      <Handle type="target" id="top" style={{ width: 1, height: 1, opacity: 0 }} position={Position.Top} />
      <Handle type="target" id="left" style={{ width: 1, height: 1, opacity: 0 }} position={Position.Left} />
      <div>{data.children}</div>
      <Handle type="source" id="right" style={{ width: 1, height: 1, opacity: 0 }} position={Position.Right} />
      <Handle type="source" id="bottom" style={{ width: 1, height: 1, opacity: 0 }} position={Position.Bottom} />
    </div>
  );
}
