import {
  CHANNEL_TYPE,
  IEmailChannel,
  ISmsChannel,
  TCreateAutomation,
} from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ControllerFieldState, useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../context';
import { IRecipient } from '../types';
import { SelectRecipient } from './recipients/select-recipient.component';

interface IChannelProps {
  type: CHANNEL_TYPE.SMS | CHANNEL_TYPE.EMAIL;
  recipients: IRecipient[];
  onAddRecipientButtonClick: () => void;
  checkboxId?: string;
  selectId?: string;
}

interface IChannelController {
  field: {
    value: ISmsChannel | IEmailChannel;
    onChange: (event: ISmsChannel | IEmailChannel | null) => void;
  };
  fieldState: ControllerFieldState;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
  },
  tooltip: {
    position: 'absolute',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: theme.typography.pxToRem(55),
    marginRight: theme.typography.pxToRem(5),
  },
  checkbox: {
    flex: '15%',
  },
}));

const filterRecipientsByIds = (recipients: IRecipient[], ids: string[]): IRecipient[] =>
  recipients.filter((recipient) => ids.includes(recipient.id));

export function ExtendedChannel({ type, recipients, onAddRecipientButtonClick, checkboxId, selectId }: IChannelProps) {
  const { classes } = useStyles();
  const content = useContent();
  const { trigger } = useFormContext();
  const {
    field,
    fieldState: { error, isDirty },
  }: IChannelController = useController<TCreateAutomation, 'channels.EMAIL' | 'channels.SMS'>({
    name: `channels.${type}`,
  });

  const [channelEnabled, setChannelEnabled] = useState<boolean>(field.value?.recipients?.length > 0 || false);
  const [channelRecipients, setChannelRecipients] = useState<IRecipient[]>(
    filterRecipientsByIds(recipients, field.value?.recipients.map(({ recipientId }) => recipientId) ?? [])
  );

  useEffect(() => {
    setChannelRecipients(
      filterRecipientsByIds(recipients, field.value?.recipients.map(({ recipientId }) => recipientId) ?? [])
    );
  }, [field.value?.recipients, recipients]);

  const label = useMemo(() => {
    return type === CHANNEL_TYPE.SMS ? content.ACTION.LABEL.SMS : content.ACTION.LABEL.EMAIL;
  }, [content.ACTION.LABEL.EMAIL, content.ACTION.LABEL.SMS, type]);

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChannelEnabled(event.target.checked);
      let change: ISmsChannel | IEmailChannel | null = null;
      if (event.target.checked) {
        change = {
          type,
          recipients: channelRecipients.map((recipient) => ({
            recipientId: recipient.id,
            recipientType: recipient.recipientType,
          })),
        };
      }
      field.onChange(change);
      if (isDirty) {
        trigger(`channels.${type}`);
      }
    },
    [channelRecipients, field, isDirty, trigger, type]
  );

  const handleSelectChange = useCallback(
    (data: IRecipient[]) => {
      setChannelRecipients(data);
      field.onChange({
        type,
        recipients: data.map((recipient) => ({
          recipientId: recipient.id,
          recipientType: recipient.recipientType,
        })),
      });
      trigger(`channels.${type}`);
    },
    [field, trigger, type]
  );

  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={channelEnabled}
            data-testid={checkboxId ? checkboxId : 'automation-checkbox'}
            onChange={handleCheckboxChange}
          />
        }
        label={
          <div className={classes.label}>
            {label} {type === CHANNEL_TYPE.SMS && <Tooltip text={content.TEXT_TOOLTIP} placement="right" />}
          </div>
        }
      />
      <SelectRecipient
        onChange={handleSelectChange}
        disabled={!channelEnabled}
        recipients={recipients}
        shouldDisableRecipients={type === CHANNEL_TYPE.SMS}
        value={channelRecipients}
        error={error}
        id={selectId ? selectId : 'automation-select-recipients'}
        onAddRecipientButtonClick={onAddRecipientButtonClick}
      />
    </div>
  );
}
