import {
  aercoImageStep1,
  aercoImageStep2,
  intellistationImageStep1,
  intellistationImageStep2,
  intellistationImageStep3,
  intellistationImageStep4,
} from './assets';
import { content } from './content';

export const intellistationDescriptionSteps = [
  {
    imageSrc: intellistationImageStep1,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_1,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_1_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
  {
    imageSrc: intellistationImageStep2,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_2,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_2_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
  {
    imageSrc: intellistationImageStep3,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_3,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_3_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
  {
    imageSrc: intellistationImageStep4,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_4,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_4_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
];

export const aercoDescriptionSteps = [
  {
    imageSrc: aercoImageStep1,
    description: content.INSTRUCTIONS_MODAL.AERCO.STEP_1,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.AERCO.STEP_1_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.AERCO.TITLE_STEP_1,
  },
  {
    imageSrc: aercoImageStep2,
    description: content.INSTRUCTIONS_MODAL.AERCO.STEP_2,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.AERCO.STEP_2_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.AERCO.TITLE_STEP_2,
  },
];
