import { useCurrentUser } from '@marlin/account-data-access-organization';
import { adjustOrgName } from '@marlin/shared/utils-common-org-name';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { MenuItem, Typography } from '@mui/material';

import { OrganizationTier } from '../../components/organization-tier.component';
import { useStyles } from './organization-item.styles';

interface IOrganizationItemProps {
  isCurrentOrg: boolean;
  orgName?: string;
  onChange: () => void;
  style?: React.CSSProperties;
  showFullName?: boolean;
  tierName: string;
  isPortfolio: boolean;
  isSupport?: boolean;
}

const maxAllowedCharactersInOrgName = 28;
const maxAllowedCharactersInOrgNameWithout = 42;

export const OrganizationItem = ({
  isCurrentOrg,
  orgName,
  onChange,
  showFullName = false,
  tierName,
  isPortfolio,
  isSupport,
}: IOrganizationItemProps) => {
  const { classes, cx } = useStyles();
  const { data } = useCurrentUser();

  const showTier = data?.currentOrganization.roles.includes('Support') && !isPortfolio && !isSupport;

  return (
    <MenuItem onClick={onChange} className={classes.container} data-testid="organization-menu-item">
      <div className={classes.orgNameContainer}>
        {isCurrentOrg && <FiberManualRecordIcon className={classes.greenDot} />}
        {orgName &&
          (showFullName ? (
            <Typography className={cx(classes.orgName, classes.hideSafariNativeTooltip)}> {orgName} </Typography>
          ) : (
            <Typography noWrap className={cx(classes.orgName, classes.hideSafariNativeTooltip)}>
              {adjustOrgName(orgName, showTier ? maxAllowedCharactersInOrgName : maxAllowedCharactersInOrgNameWithout)}
            </Typography>
          ))}
      </div>
      {showTier && <OrganizationTier tier={tierName} isMobile={false} />}
    </MenuItem>
  );
};
