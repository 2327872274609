import { MarlinTheme } from '@marlin/shared/theme';
import { useEquipmentModelOptions } from '@marlin/shared/ui-device-type';
import { TModel } from '@marlin/shared/utils/zod';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Handle, NodeProps, Position, useReactFlow } from '@xyflow/react';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTriggersStore$ } from '../../hooks/use-observable-trigger-store';
import { TRIGGER_STEP } from '../../types';
import { useCommonStyles } from '../common.styles';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
  selectWrapper: {
    width: '100%',
  },
  handle: {
    width: 1,
    height: 1,
    opacity: 0,
  },
}));

export const EquipmentModelNode = ({ id }: NodeProps) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes, cx } = useStyles();
  const [model, setModel] = useState<TModel | undefined>(undefined);
  const { addNodes, addEdges, deleteElements } = useReactFlow();

  const models = useEquipmentModelOptions();

  const { addModels, removeModels, currentState } = useTriggersStore$();

  const handleModelChange = useCallback((event: SelectChangeEvent) => {
    setModel(event.target.value as TModel);
  }, []);

  const onCancelClick = useCallback(async () => {
    removeModels();
    await deleteElements({
      nodes: [{ id }],
    });
  }, [deleteElements, id, removeModels]);

  const onChooseEquipmentClick = useCallback(() => {
    if (model) {
      addModels([model]);
      const newNode = {
        id: 'equipmentManufacturerId',
        position: { x: 1150, y: 445 },
        type: 'equipmentManufacturerIdNode',
        data: {},
      };

      const newEdge = {
        id: 'edge-35',
        source: 'equipmentModel',
        target: 'equipmentManufacturerId',
        sourceHandle: 'right',
        targetHandle: 'left',
      };

      addNodes([newNode]);
      addEdges([newEdge]);
    }
  }, [addEdges, addModels, addNodes, model]);

  return (
    <div className={cx(commonClasses.node, classes.container)} data-testid={`equipment-node-${id}`}>
      <div className={cx(commonClasses.nodeContent, classes.content)}>
        <span data-testid={`equipment-model-node-${id}-label`}>Equipment model</span>
        <div className={cx(classes.selectWrapper, 'nodrag')}>
          <FormControl fullWidth>
            <InputLabel>Model</InputLabel>
            <Select
              value={model}
              label="Model"
              onChange={handleModelChange}
              variant="outlined"
              disabled={currentState?.currentStep !== TRIGGER_STEP.ADD_MODEL}
            >
              {models.map((model) => (
                <MenuItem key={model.id} value={model.id}>
                  {model.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={commonClasses.actionButtons}>
          <Button onClick={onCancelClick} disabled={currentState?.currentStep !== 'ADD_MODEL'}>
            Cancel
          </Button>
          <Button
            onClick={onChooseEquipmentClick}
            variant="contained"
            disabled={!model || currentState?.currentStep !== TRIGGER_STEP.ADD_MODEL}
          >
            Choose equipment
          </Button>
        </div>
      </div>
      <Handle className={classes.handle} type="target" id="top" position={Position.Top} />
      <Handle className={classes.handle} type="target" id="left" position={Position.Left} />
      <Handle className={classes.handle} type="source" id="right" position={Position.Right} />
      <Handle className={classes.handle} type="source" id="bottom" position={Position.Bottom} />
    </div>
  );
};
