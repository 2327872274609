import { TTriggerStoreEquipment, TTriggerStoreSensor } from '../../types';

export const isEquipmentStore = (
  currentState: TTriggerStoreEquipment | unknown
): currentState is TTriggerStoreEquipment => {
  return (currentState as TTriggerStoreEquipment)?.models?.length > 0;
};

export const isSensorStore = (currentState: TTriggerStoreSensor | unknown): currentState is TTriggerStoreSensor => {
  return (currentState as TTriggerStoreSensor)?.models?.length > 0;
};

export const isEquipmentOrSensorStore = (
  currentState: TTriggerStoreEquipment | TTriggerStoreSensor | unknown
): currentState is TTriggerStoreEquipment | TTriggerStoreSensor => {
  return isEquipmentStore(currentState) || isSensorStore(currentState);
};
