import { base64DecodeSchema } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import {
  webhookApiHeaderSchema,
  webhookResponseDeviceSchema,
  webhookSyncFrequencySchema,
  webhookTypeSchema,
} from './webhook.schema';

export const getWebhookParamsSchema = z.object({
  webhookId: z.string(),
});

export const getWebhookResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: base64DecodeSchema,
  organizationId: z.string(),
  managerEmail: z.string(),
  frequency: webhookSyncFrequencySchema,
  type: webhookTypeSchema,
  devices: z.array(webhookResponseDeviceSchema),
  suspended: z.boolean(),
  allDevices: z.boolean(),
  failureCount: z.number(),
  headers: z.array(webhookApiHeaderSchema),
});

export type TGetWebhookParams = z.infer<typeof getWebhookParamsSchema>;
export type TWebhookResponse = z.infer<typeof getWebhookResponseSchema>;
