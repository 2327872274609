export const content = {
  FOOTER_TEXT: '© 2024 Watts. All rights reserved.',
  PROPERTY_NAME: 'Property Name',
  ADDRESS_LABEL: 'Address',
  SETUP_ORG_TITLE: 'Set up your organization',
  ORGANIZATION: 'Organization',
  SELECT_SUBTITLE: 'If you already created an organization select it from the list bellow.',
  SETUP_ORG_SUBTITLE: 'Add the name and address of the property where your equipment is located.',
  REGISTER_EQUIPMENT_TITLE: 'Register equipment',
  REGISTER_EQUIPMENT_SUBTITLE: 'Connect your equipment to Nexa to receive real-time data and alerts.',
  INVITE_USER_TITLE: 'Invite users',
  INVITE_USER_SUBTITLE: 'Give your team access to your organization and sensors.',
  I_LL_DO_THIS_LATER: 'I’ll do this later',
  SEND_INVITE: (count: number) => `Send${count > 0 ? ` ${count} ` : ' '}invite${count > 1 ? 's' : ''}`,
  INVITE_USER_SUPPORT: 'Not ready to send invites? You can add users from your dashboard at any time.',
  ADD_ANOTHER_USER: 'Add another user',
  EQUIPMENT_INTELLISTATION: 'Powers IntelliStation 2',
  EQUIPMENT_AERCO_INNOVATION: 'AERCO Innovation',
  EQUIPMENT_AERCO_BENCHMARK: 'AERCO Benchmark',
  REGISTER_EQUIPMENT_SUPPORT: `Can't find your equipment or Nexa sensors? Contact `,
  REGISTER_EQUIPMENT_EMAIL: 'support@nexaplatform.com',
  SELECT_EQUIPMENT: 'Select additional equipment to register',
  EQUIPMENT_LIMIT_1: 'Thank you for registering your equipment.',
  EQUIPMENT_LIMIT_2: 'You may add more equipment once you enter Nexa on the Equipment page.',
  PROPERTY_NAME_REQUIRED: 'Property name is required',
  ADDRESS_REQUIRED: 'Address is required',
  SENSOR_TYPE: 'Sensor Type',
  CONNECTING: '...Connecting',
  REGISTERED_ERROR:
    'This equipment has already been registered. If you feel this is incorrect, please contact a Nexa customer support representative.',
  INCORRECT_ERROR: 'Incorrect registration data. Please check the information and re-enter.',
  MIN_MAX: 'Must be between 3 and 80 characters',
  MIN_MAX_EQUIPMENT: 'Must be between 3 and 200 characters',
  ORG_NAME_MUST_BE_VALIDATION: 'Organization name must be at least 3 characters long.',
  DUPLICATE_EMAIL: 'This email has already been entered.',
  MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,
  EQUPIMENT_NAME: 'Equipment Name',
  EQUIPMENT_TYPE: 'Equipment type:',
  PREV: 'Prev',
  NEXT: 'Next',
  BACK: 'Back',
  OK: 'OK',
  COMPLETE: 'Complete',
  EDIT: 'Edit',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  REGISTRATION_CODE: 'Registration Code',
  SERIAL_NUMBER: 'Serial Number',
  CONTROLLER_FIXED_ID: 'Controller Fixed ID',
  REGISTER_EQUIPMENT: 'Register Equipment',
  REGISTRATION_CODE_LINK: 'How do I find my registration code?',
  SERIAL_NUMBER_LINK: 'How do I find my serial number?',
  CONTROLLER_FIXED_ID_LINK: 'How do I find my Controller Fixed ID?',
  SELECT_ORGANIZATION: 'Select your organization',
  CREATE_ORGANIZATION: 'Create a new organization',
  ERROR: {
    LOCATION: {
      TITLE: 'Location creation failed',
      SUBTITLE1: 'Reach out to',
      SUBTITLE2: 'or return to previous registration page.',
      LINK: 'support@nexaplatform.com',
      BUTTON: 'Return to previous page',
    },
    REGISTRATION_DATA: {
      TITLE: 'Incorrect registration data',
      SUBTITLE: 'Please check the information and re-enter.',
      BUTTON: 'Return to previous page',
    },
    ALREADY_REGISTERED: {
      TITLE: 'This equipment has already been registered',
      SUBTITLE: 'If you feel this is incorrect, please contact a Nexa customer support representative.',
      BUTTON: 'Return to previous page',
    },
    REGISTRATION_FAILED: {
      TITLE: 'Registration failed',
      SUBTITLE1: 'Reach out to',
      SUBTITLE2: 'or return to previous registration page.',
      LINK: 'support@nexaplatform.com',
      BUTTON: 'Return to previous page',
    },
    INVITATION_FAILED: {
      TITLE: 'Invitation failed',
      SUBTITLE1: 'Reach out to',
      SUBTITLE2: 'or return to previous registration page.',
      LINK: 'support@nexaplatform.com',
      BUTTON: 'Return to previous page',
    },
    ORGANIZATION_CREATION: {
      TITLE: 'Organization creation failed',
      SUBTITLE1: 'Reach out to',
      SUBTITLE2: 'or return to previous registration page.',
      LINK: 'support@nexaplatform.com',
      BUTTON: 'Return to previous page',
    },
  },
  SETUP_COMPLETE: {
    TITLE: 'Setup is complete',
    SUBTITLE1: 'Dive into your dashboard or invite team members for a collaborative experience.',
    SUBTITLE2: 'You will be redirected to invite step shortly.',
    BONUS_TITLE: 'Bonus: Free warranty* extension.',
    BONUS_TEXT_INTELLISTATION:
      'With your equipment registration you increased your warranty period from 1.5\u00A0years to 5\u00A0years free of charge.',
    BONUS_TEXT_INNOVATIONEDGE:
      'With your equipment registration you increased your Edge Control Panel warranty period from 2 years to 5 years free of charge.',
    TERMS_AND_CONDITIONS_INTELLISTATION: '*For complete terms and conditions visit',
    TERMS_AND_CONDITIONS_INNOVATIONEDGE:
      '*You must register the product to Nexa within 2 years of shipment to qualify for the free warranty extension. Click the link to read complete',
    BUTTON: 'Next',
    LINK_INTELLISTATION: 'https://www.watts.com/resources/warranty-information#9',
    LINK_INNOVATIONEDGE: 'Terms and Conditions',
  },
  INVITATIONS_SENT: {
    TITLE: 'Invitations sent',
    SUBTITLE: 'You will be redirected to your new dashboard shortly.',
  },
  INVITATIONS_COMPLETED: {
    TITLE: 'Finished',
    SUBTITLE1: 'Your equipment is registered to your new organization.',
    SUBTITLE2: 'You are being redirected to',
    SUBTITLE3: 'shortly.',
    LINK: 'your new dashboard',
  },
  DELETE_MODAL: {
    TITLE: 'Are you sure you want to remove this equipment from the registration list?',
    BODY: "If yes, you won't be able to access data analysis or connected sensors.",
    CONFIRM: 'Yes',
    CANCEL: 'Cancel',
  },
  CANCEL_MODAL: {
    TITLE: 'Do you want to cancel setting up your organization?',
    CONFIRM: 'Return to login',
    CANCEL: 'Return to org setup',
  },
  LOGGER: {
    SELF_SERVICE: {
      SETUP_ORGANIZATION: 'Self Service - Setup Organization step',
      SETUP_ORGANIZATION_SUCCESS: 'Self Service - Setup Organization success',
      SELECT_ORGANIZATION_SUCCESS: 'Self Service - Select Organization success',
      REGISTER_EQUIPMENT: 'Self Service - Register Equipment step',
      REGISTER_EQUIPMENT_SUCCESS: 'Self Service - Register Equipment success',
      REGISTER_EQUIPMENT_ERROR: 'Self Service - Register Equipment error',
      INVITE_USERS: 'Self Service - Invite Users step',
      INVITE_USERS_SUCCESS: 'Self Service - Invite Users success',
      SETUP_COMPLETE: 'Self Service - Setup Complete step',
      INVITATIONS_SENT: 'Self Service - Invitations Sent step',
      INVITATIONS_COMPLETED: 'Self Service - Invitations Complete step',
      ADD_EQUIPMENT: 'Self Service - Add Equipment',
      EDIT_EQUIPMENT: 'Self Service - Edit Equipment',
      SAVE_EQUIPMENT_SUCCESS: 'Self Service - Save Equipment Success',
      SAVE_EQUIPMENT_ERROR: 'Self Service - Save Equipment Error',
      REMOVE_EQUIPMENT: 'Self Service - Remove Equipment',
      ERRORS: {
        REGISTRATION_FAILED_ERROR: 'Self Service - Registration Failed Error step',
        ALREADY_REGISTERED_ERROR: 'Self Service - Already Registered Error step',
        REGISTRATION_DATA_ERROR: 'Self Service - Registration Data Error step',
        LOCATION_ERROR: 'Self Service - Location Error step',
        ORGANIZATION_CREATION_ERROR: 'Self Service - Organization Creation Error step',
        INVITATION_FAILED_ERROR: 'Self Service - Invitation Failed Error step',
      },
      HOW_DO_I_FIND: (title: string) => `Self Service - ${title} open`,
    },
  },
  CHANGE_ORGANIZATION: 'Change organization',
};
