import { base64DecodeSchema, base64EncodeUrlSchema } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import {
  webhookApiHeaderSchema,
  webhookResponseDeviceSchema,
  webhookSyncFrequencySchema,
  webhookTypeSchema,
} from './webhook.schema';

const webhookDeviceSchema = z.object({
  deviceId: z.string(),
  datapointName: z.string(),
});

export const createWebhookParamsSchema = z.object({
  name: z.string(),
  url: base64EncodeUrlSchema,
  managerEmail: z.string(),
  frequency: webhookSyncFrequencySchema,
  type: webhookTypeSchema,
  devices: z.array(webhookDeviceSchema),
  headers: z.array(webhookApiHeaderSchema),
});

export const createWebhookResponseSchema = z.object({
  id: z.string(),
  url: base64DecodeSchema,
  organizationId: z.string(),
  managerEmail: z.string(),
  type: webhookTypeSchema,
  devices: z.array(webhookResponseDeviceSchema),
  suspended: z.boolean(),
  allDevices: z.boolean(),
  failureCount: z.number(),
  frequency: webhookSyncFrequencySchema,
  name: z.string(),
});

export type TCreateWebhookParams = z.infer<typeof createWebhookParamsSchema>;
